import BoxComponent from '../../../../components/BoxComponent';
import OptionsComponent from './OptionsComponent';
import { currencyFormatter, getCountryCode } from '../../../../utils/utils';
import {
	Box,
	Flex,
	Image,
	Table,
	Tbody,
	Td,
	Text,
	Thead,
	Tr,
	useColorMode,
	useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import SendProjectTokensComponent from '../../../../components/SendProjectTokensComponent';
import { useState } from 'react';
// import useTokenTransfer from '../../../../hooks/useTokenTransfer';

const ListTokenGallery = ({ projects, isTablet, isMobile }) => {
	const { colorMode } = useColorMode();
	const { t } = useTranslation();
	/* const { transferProjectTokens } = useTokenTransfer(); */
	const [selectedProject, setSelectedProject] = useState();
	const {
		isOpen: isOpenSendModal,
		onClose: onCloseSendModal,
		onOpen: openSendModal,
	} = useDisclosure();

	const onOpenSendModal = metadataId => {
		const _project = projects.find(
			project => project.project.metadataId === metadataId,
		);
		setSelectedProject(_project);
		openSendModal();
	};

	const colorHeadTable = colorMode === 'dark' ? 'brand.200' : 'brand.500';
	const colorBorder =
		colorMode === 'dark'
			? 'transparencyWhite.100'
			: 'transparencyBlack.200';

	return (
		<BoxComponent p='0' w='100%'>
			<Box
				borderWidth={{ base: '2px', md: '0' }}
				borderColor={colorBorder}
				borderRadius={'10px'}
			>
				<Table>
					<Thead>
						<Tr
							borderColor={colorBorder}
							borderBottomWidth={{ base: '2px', md: '3px' }}
						>
							<Td
								borderColor={colorBorder}
								borderRightWidth={{ base: '2px', md: '0px' }}
								color={colorHeadTable}
								fontSize={{ base: '12px', sm: '14px' }}
								fontWeight='700'
								textAlign='center'
								lineHeight='100%'
								py='10px'
								px={{ base: '4px', md: '10px' }}
							>
								Project name
							</Td>

							<Td
								borderColor={colorBorder}
								borderRightWidth={{ base: '2px', md: '0px' }}
								color={colorHeadTable}
								fontSize={{ base: '12px', sm: '14px' }}
								fontWeight='700'
								textAlign='center'
								lineHeight='100%'
								py='10px'
								px={{ base: '4px', md: '10px' }}
							>
								Country
							</Td>
							<Td
								borderColor={colorBorder}
								borderRightWidth={{ base: '2px', md: '0px' }}
								color={colorHeadTable}
								fontSize={{ base: '12px', sm: '14px' }}
								fontWeight='700'
								textAlign='center'
								lineHeight='100%'
								py='10px'
								px={{ base: '4px', md: '10px' }}
							>
								Total tokens
							</Td>
							<Td
								borderColor={colorBorder}
								borderRightWidth={{ base: '2px', md: '0px' }}
								color={colorHeadTable}
								fontSize={{ base: '12px', sm: '14px' }}
								fontWeight='700'
								textAlign='center'
								lineHeight='100%'
								py='10px'
								px={{ base: '4px', md: '10px' }}
							>
								Fiat value
							</Td>
							<Td
								color={colorHeadTable}
								fontSize={{ base: '12px', sm: '14px' }}
								fontWeight='700'
								textAlign='center'
								lineHeight='100%'
								py='10px'
								px={{ base: '4px', md: '10px' }}
							>
								Options
							</Td>
						</Tr>
					</Thead>
					{projects && projects?.length > 0 ? (
						<Tbody>
							{projects.map(({ project }, index) => (
								<Tr
									key={index}
									fontSize={isTablet ? '10' : '12px'}
									borderColor={
										index === projects.length - 1
											? 'transparent'
											: colorBorder
									}
									borderBottomWidth='2px'
									px={{ base: '4px', md: '10px' }}
								>
									<Td
										fontSize={isTablet ? '10' : '14px'}
										fontWeight={isTablet ? '600' : '500'}
										p='8px'
									>
										{project.title}
									</Td>
									<Td p='8px'>
										<Flex justify='center'>
											<Image
												src={`/assets/countries/${getCountryCode(project.country)}.webp`}
												boxSize='12px'
												rounded={'50px'}
											/>
										</Flex>
									</Td>
									<Td
										fontWeight='300'
										p='8px'
										textAlign='center'
									>
										{project.mintedSupply}
									</Td>
									<Td
										fontWeight='300'
										p='8px'
										textAlign='center'
									>
										{currencyFormatter(project.value)}
									</Td>
									<Td
										p='8px'
										justifyContent='center'
										display='flex'
										border='none'
									>
										<OptionsComponent
											onOpenSendModal={onOpenSendModal}
											project={project}
										/>
									</Td>
								</Tr>
							))}
						</Tbody>
					) : null}
				</Table>
				{!projects || projects?.length === 0 ? (
					<Box w='100%' pt='15px'>
						<Text textAlign='center' w='100%'>
							{t('dashboard.myProperties.noTokens')}
						</Text>
					</Box>
				) : null}
			</Box>
			{isOpenSendModal && (
				<SendProjectTokensComponent
					isOpenSendModal={isOpenSendModal}
					closeSendModal={onCloseSendModal}
					isMobile={isMobile}
					project={selectedProject.project}
				/>
			)}
		</BoxComponent>
	);
};

export default ListTokenGallery;
