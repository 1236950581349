import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import { IconArrowDown } from '../../assets/Icons';
import { formatDateLongMonth } from '../../utils/utils';

const DatePickerTimelineComponent = ({
	name,
	selected,
	disabled = false,
	onChange,
	minDate,
}) => {
	return (
		<DatePicker
			minDate={minDate}
			selected={selected}
			onChange={e => onChange({ date: e, name })}
			customInput={
				<InputGroup>
					<InputRightElement>
						<IconArrowDown />
					</InputRightElement>
					<Input
						name={name}
						placeholder='Select date'
						value={selected ? formatDateLongMonth(selected) : ''}
						readOnly
						cursor='pointer'
					/>
				</InputGroup>
			}
			wrapperClassName='datepicker-wrapper'
			calendarClassName='datepicker-calendar'
			inputClassName='datepicker-input'
			showPopperArrow={false}
			dateFormat='MM/dd/yyyy'
			disabled={disabled}
		/>
	);
};

export default DatePickerTimelineComponent;
