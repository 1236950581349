import { useRef, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	LineElement,
	CategoryScale,
	LinearScale,
	PointElement,
	Filler,
	Tooltip,
	Legend,
} from 'chart.js';
import {
	Box,
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Text,
	useColorModeValue,
	useTheme,
} from '@chakra-ui/react';

import { ChevronDownIcon } from '@chakra-ui/icons';
import { format, subDays, getYear } from 'date-fns';
import { useTranslation } from 'react-i18next';

ChartJS.register(
	LineElement,
	CategoryScale,
	LinearScale,
	PointElement,
	Filler,
	Tooltip,
	Legend,
);

const GraphComponent = ({ data, setGraphDays, graphDays }) => {
	const { t } = useTranslation();
	const chartRef = useRef(null);
	const [gradient, setGradient] = useState(null);
	const theme = useTheme();

	const chartColor = useColorModeValue(
		theme.colors.brand[500],
		theme.colors.brand[200],
	);

	const chartTransparency = useColorModeValue(
		theme.colors.transparencyWhite[100],
		theme.colors.transparencyBlack[100],
	);

	const generateData = (days, graphData) => {
		const today = new Date();
		const step = today.getDate() % 2 === 0 ? 7 : 8;

		today.setDate(today.getDate() + step);
		const data = [];
		for (let i = 0; i < days; i++) {
			const date = subDays(today, days - 1 - i);
			const item = graphData.filter(
				item =>
					date.toLocaleDateString() ===
					new Date(item.date).toLocaleDateString(),
			);

			data.push({
				date,
				formattedDate: format(date, 'MMM dd, yyyy'),
				year: getYear(date),
				value: item[0]?.value || 0,
			});
		}
		return data;
	};

	useEffect(() => {
		if (chartRef.current) {
			const chart = chartRef.current;
			const ctx = chart.ctx;
			const gradient = ctx.createLinearGradient(0, 0, 0, 400);
			gradient.addColorStop(0, chartColor);
			gradient.addColorStop(0.1, chartColor);
			gradient.addColorStop(0.8, chartTransparency);
			setGradient(gradient);
		}
	}, [chartColor, chartTransparency]);

	const allData = generateData(graphDays, data);

	// Selecciona 12 puntos de datos y etiquetas equidistantes
	const step = Math.max(Math.floor(graphDays / 12), 1);
	const labels = [];
	const values = [];
	for (let i = 0; i < 12; i++) {
		const index = i * step;
		if (index < allData.length) {
			labels.push(allData[index]);
			values.push(allData[index].value);
		} else {
			break;
		}
	}

	// Verificar si todos los años son iguales
	const allYearsSame = labels.every(label => label.year === labels[0].year);

	const chartLabels = labels.map(label =>
		allYearsSame
			? format(label.date, 'MMM dd')
			: format(label.date, 'MMM yyyy'),
	);

	const chartData = {
		labels: chartLabels, // Agregar etiquetas aquí
		datasets: [
			{
				label: 'Beneficios',
				data: values, // Usar solo los valores aquí
				fill: true,
				backgroundColor: gradient,
				borderColor: 'transparent',
				pointRadius: 0,
				tension: 0.5,
			},
		],
	};

	const options = {
		maintainAspectRatio: false,
		responsive: true,
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					stepSize: 100,
					font: {
						size: 10, // Tamaño de fuente para el eje Y
					},
				},
				grid: {
					color: 'rgba(255, 255, 255, 0.1)', // Color de las líneas de puntos

					borderDash: [5, 5], // Configuración de puntos
				},
			},
			x: {
				ticks: {
					font: {
						size: 10, // Tamaño de fuente para el eje X
					},
				},
				grid: {
					display: 'none',
					borderDash: [0, 0],
				},
			},
		},
		plugins: {
			legend: {
				display: false, // Ocultar la leyenda
			},
		},
	};

	return (
		<Stack h='100%' w='100%' p='15px 20px' gap='5px'>
			<Text
				fontSize='16'
				fontWeight='700'
				lineHeight='12px'
				letterSpacing={0.02}
			>
				{t('adminPage.volumeTransactions.title')}
			</Text>
			<Flex justifyContent='space-between' align='center'>
				<Flex gap='3px' flexDirection={{ base: 'column', sm: 'row' }}>
					{/* <Text
						variant='colored'
						fontSize='12'
						fontWeight='400'
						lineHeight='17px'
					>
						(+5){t('adminPage.volumeTransactions.more')}
					</Text>
					<Text
						variant='gray'
						fontSize='12'
						fontWeight='400'
						lineHeight='17px'
					>
						{t('adminPage.volumeTransactions.year')}
					</Text> */}
				</Flex>

				<Menu>
					<MenuButton
						as={Button}
						rightIcon={<ChevronDownIcon boxSize='20px' />}
						borderRadius='27px'
						fontSize='10px'
						px='10px'
						w='fit-content'
						h='22px'
					>
						{t('adminPage.volumeTransactions.days', {
							count: graphDays,
						})}
					</MenuButton>
					<MenuList fontSize={10}>
						<MenuItem onClick={() => setGraphDays(30)}>
							{t('adminPage.volumeTransactions.30days')}
						</MenuItem>
						<MenuItem onClick={() => setGraphDays(90)}>
							{t('adminPage.volumeTransactions.90days')}
						</MenuItem>
						<MenuItem onClick={() => setGraphDays(180)}>
							{t('adminPage.volumeTransactions.6month')}
						</MenuItem>
						<MenuItem onClick={() => setGraphDays(365)}>
							{t('adminPage.volumeTransactions.12month')}
						</MenuItem>
						<MenuItem onClick={() => setGraphDays(730)}>
							{t('adminPage.volumeTransactions.24month')}
						</MenuItem>
					</MenuList>
				</Menu>
			</Flex>
			<Box h='100%' w='100%'>
				<Line ref={chartRef} data={chartData} options={options} />
			</Box>
		</Stack>
	);
};

export default GraphComponent;
