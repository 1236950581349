import { useState } from 'react';
import { Text, Box, Flex, Button, useDisclosure } from '@chakra-ui/react';
import CardBoxComponent from '../../../../components/CardBoxComponent';
import Countdown from './CountdownComponent';
import ProjectIncomesInfo from './ProjectIncomesInfo';
import CalculatorROI from './CalculatorROI';
import AddCredit from './AddCredit';
import SuccessfulComponent from '../../../../components/SuccessfulComponent';
import { IconInvest } from '../../../../assets/Icons';
import { userExample } from '../../../../data/mockdata';
import LoginComponent from '../../../../components/LoginComponent';
import ModalComponent from '../../../../components/ModalComponent';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { valueTypeOptions } from '../../data';
import useInfoData from '../../../../hooks/useInfoData';
import { getUserBalanceByCurrency } from '../../helper';
import { interactWithTransak } from '../../../../lib/transak';
import { PrivateRoutes, PublicRoutes } from '../../../../routes/Routes';
import useSeller from '../../../../hooks/useSeller';
import { availableTokens, currencyFormatter } from '../../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { propertyStatus } from '../../../../data/optionsData';

const OfferDetails = ({ projectInfo, projectStatus }) => {
	const { mintedSupply, metadataId, quantityOfTokens, minTicketPrice } =
		projectInfo;
	const { t } = useTranslation();
	const user = useSelector(state => state.user);
	const isLogged = user?.userId.length ?? false;
	const { balances } = useInfoData({ walletAddress: user.walletAddress });

	const [remainingTokens, setRemainingTokens] = useState(
		availableTokens({ mintedSupply, quantityOfTokens }),
	);
	const navigate = useNavigate();
	const [isMinting, setIsMinting] = useState(false);
	const [valueType, setValueType] = useState(valueTypeOptions.Fiat);
	const [valueInput, setValueInput] = useState();
	const [successfulBuy, setSuccessfulBuy] = useState(false);
	const [errMsg, setErrMsg] = useState();
	const [selectedCurrency, setSelectedCurrency] = useState();
	const [topUp, setTopUp] = useState(false);
	const [numTokens, setNumTokens] = useState();
	const [realCost, setRealCost] = useState();
	const [transakResponse, setTransakResponse] = useState();
	const [txHash, setTxHash] = useState(null);
	const { mint } = useSeller();

	const {
		isOpen: isOpenLoginModal,
		onOpen: onOpenLoginModal,
		onClose: onCloseLoginModal,
	} = useDisclosure();
	const {
		isOpen: isOpenCreditCardModal,
		onOpen: onOpenCreditCardModal,
		onClose: onCloseCreditCardModal,
	} = useDisclosure();

	/**
	 * @name checkBuyErrors
	 * @description Check if there are any errors in the buy process
	 * @returns {Error} An error if there is any, undefined otherwise
	 */

	const checkBuyErrors = () => {
		let error = false;
		if (errMsg) {
			setErrMsg();
		}

		if (!selectedCurrency) {
			error = t('error.selectCurrency');
			setErrMsg(error);
			return true;
		}

		if (numTokens > remainingTokens) {
			setErrMsg(
				t('error.notEnoughTokensToBuy') +
					`. Max. ${remainingTokens} tokens`,
			);
			return true;
		}

		if (!numTokens || numTokens === 0) {
			setErrMsg(t('error.insertTokens'));
			return true;
		}
		if (realCost < minTicketPrice) {
			error = t('error.minPurchase') + currencyFormatter(minTicketPrice);
			setErrMsg(error);
			return true;
		}

		if (!valueInput) {
			error = t('error.insertValue');
			setErrMsg(error);
			return true;
		} else {
			const userCurrencyBalance = getUserBalanceByCurrency(
				selectedCurrency,
				balances,
			);
			if (userCurrencyBalance < realCost) {
				error = t('error.dontEnoughCredit');
				setErrMsg(error);
				setTopUp(true);
				return true;
			}
		}

		return error;
	};

	/**
	 * @name updateMintedSupply
	 * @description Mint the num of tokens and update the minted supply in the database
	 * @returns {Promise<{success: boolean}>} The success of the operation
	 */

	const updateMintedSupply = async () => {
		// Mint the supply of tokens in blockchain
		const mintTx = await mint(metadataId, selectedCurrency, numTokens);

		try {
			if (mintTx.success) {
				setRemainingTokens(remainingTokens - numTokens);
				setTxHash(mintTx.receipt.hash);
				return { success: true };
			} else {
				return { success: false };
			}
		} catch (error) {
			console.error('Error minting tokens:', error);
			return { success: false, message: error };
		}
	};

	const handleClick = site => {
		site === 'wallet'
			? navigate(`/${PublicRoutes.Wallet}`)
			: navigate(`/${PrivateRoutes.Dashboard}`);
	};

	const onBuyNow = async () => {
		if (!isLogged) {
			onOpenLoginModal();
		} else {
			const error = checkBuyErrors();
			if (!error) {
				try {
					setIsMinting(true);
					const updatedMintedSupply = await updateMintedSupply();
					if (updatedMintedSupply.success) {
						setSuccessfulBuy(true);
					} else {
						setErrMsg(t('error.buyError'));
					}
				} catch (error) {
					setErrMsg(error.message);
					console.error('Error minting tokens:', error);
				}
				setIsMinting(false);
			}
		}
	};

	const onTransactionResponse = txResponse => {
		setTransakResponse(txResponse);
		setErrMsg(undefined);
		setTopUp(false);
		setValueInput('');
		setValueType(valueTypeOptions.Fiat);
		setNumTokens(undefined);
	};

	const handlePurchaseConfirmation = () =>
		interactWithTransak(
			{
				purchaseQuantity: realCost,
				userInfo: {
					email: user.email,
					walletAddress: user.walletAddress,
					userId: user.userId,
				},
				selectedCurrency,
			},
			txResponse => onTransactionResponse(txResponse),
		);

	const onChangeSelectedCurrency = e => {
		if (topUp) setTopUp(false);
		if (errMsg) setErrMsg(undefined);
		setSelectedCurrency(e);
	};

	return (
		<>
			{!transakResponse && !successfulBuy && (
				<CardBoxComponent
					title={t('projectDetails.offerDetails.title')}
					pyCardBody='10px'
					minHeight='fit-content'
				>
					<Flex flexDirection='column' align='center' gap='10px'>
						{numTokens > 0 && (
							<Box>
								<Text
									variant='smallColored'
									textAlign='center'
									pb='10px'
								>
									{t(
										'projectDetails.offerDetails.finaliseFunding',
									)}
								</Text>
								<Countdown
									endDate={
										new Date(projectInfo?.financingEnd)
									}
								></Countdown>
							</Box>
						)}
						<ProjectIncomesInfo
							projectInfo={projectInfo}
							amount={valueInput}
						/>
						{projectStatus === propertyStatus.Cancelled ||
							(remainingTokens > 0 && (
								<CalculatorROI
									projectSelected={projectInfo}
									userExample={userExample}
									valueInput={valueInput}
									setValueInput={setValueInput}
									setValueType={setValueType}
									valueType={valueType}
									setSelectedCurrency={
										onChangeSelectedCurrency
									}
									selectedCurrency={selectedCurrency}
									setRealCost={setRealCost}
									realCost={realCost}
									setNumTokens={setNumTokens}
									numTokens={numTokens}
								/>
							))}
						{errMsg ? (
							<Text
								fontSize='11px'
								textColor='negative'
								textAlign='center'
							>
								{errMsg}
							</Text>
						) : null}

						{projectStatus === propertyStatus.Open &&
							remainingTokens > 0 && (
								<Button
									variant='main'
									fontSize='13'
									fontWeight='500'
									mb='5px'
									onClick={
										topUp ? onOpenCreditCardModal : onBuyNow
									}
									isLoading={isMinting}
								>
									<IconInvest boxSize='15px' mr='5px' />
									{topUp
										? t('projectDetails.offerDetails.topUp')
										: t(
												'projectDetails.offerDetails.buyNow',
											)}
								</Button>
							)}
					</Flex>
				</CardBoxComponent>
			)}
			<ModalComponent
				onClose={onCloseLoginModal}
				isOpen={isOpenLoginModal}
				modalCross={false}
			>
				<LoginComponent isModal={true} />
			</ModalComponent>

			{successfulBuy ? (
				<SuccessfulComponent
					p='20px'
					w='356px'
					h='930px'
					text={`${t('projectDetails.offerDetails.succesful.messagePart1')} ${numTokens} ${t('projectDetails.offerDetails.succesful.messagePart2')}`}
					txHash={txHash}
					buttonTitle={t(
						'projectDetails.offerDetails.succesful.button',
					)}
					handleClick={() => handleClick('dashboard')}
				/>
			) : null}
			{transakResponse ? (
				<SuccessfulComponent
					p='20px'
					w='356px'
					h='930px'
					text={`${t('projectDetails.offerDetails.succesful.succesfulBuyCripto')}`}
					txHash={txHash}
					buttonTitle={t(
						'projectDetails.offerDetails.succesful.goToWallet',
					)}
					handleClick={() => handleClick('wallet')}
					handleClose={() => setTransakResponse(undefined)}
				/>
			) : null}
			<AddCredit
				isOpen={isOpenCreditCardModal}
				closeModal={onCloseCreditCardModal}
				realCost={realCost}
				numTokens={numTokens}
				selectedCurrency={selectedCurrency}
				handlePurchaseConfirmation={handlePurchaseConfirmation}
			/>
		</>
	);
};
export default OfferDetails;
