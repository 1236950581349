export const oracleABI = [
	{
		inputs: [
			{ internalType: 'address', name: 'usdcDataFeed', type: 'address' },
			{ internalType: 'address', name: 'usdtDataFeed', type: 'address' },
			{ internalType: 'address', name: 'ethDataFeed', type: 'address' },
		],
		stateMutability: 'nonpayable',
		type: 'constructor',
	},
	{
		inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
		name: 'OwnableInvalidOwner',
		type: 'error',
	},
	{
		inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
		name: 'OwnableUnauthorizedAccount',
		type: 'error',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'string',
				name: 'name',
				type: 'string',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'dataFeedAddress',
				type: 'address',
			},
		],
		name: 'DataFeedAdded',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'string',
				name: 'name',
				type: 'string',
			},
		],
		name: 'DataFeedRemoved',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'string',
				name: 'name',
				type: 'string',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'newDataFeedAddress',
				type: 'address',
			},
		],
		name: 'DataFeedUpdated',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'previousOwner',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'newOwner',
				type: 'address',
			},
		],
		name: 'OwnershipTransferred',
		type: 'event',
	},
	{
		inputs: [
			{ internalType: 'string', name: 'name', type: 'string' },
			{
				internalType: 'address',
				name: 'dataFeedAddress',
				type: 'address',
			},
		],
		name: 'addDataFeed',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'string', name: 'tokenName', type: 'string' },
			{ internalType: 'uint8', name: 'tokenDecimals', type: 'uint8' },
			{ internalType: 'uint256', name: 'usdAmount', type: 'uint256' },
		],
		name: 'calculateUsdAmount',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'getDataFeedNames',
		outputs: [{ internalType: 'string[]', name: '', type: 'string[]' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'string', name: 'tokenName', type: 'string' }],
		name: 'getLatestPrice',
		outputs: [{ internalType: 'int256', name: '', type: 'int256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'string', name: 'name', type: 'string' }],
		name: 'isSupportedDataFeed',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'owner',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'string', name: 'name', type: 'string' }],
		name: 'removeDataFeed',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'renounceOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'newOwner', type: 'address' },
		],
		name: 'transferOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'string', name: 'name', type: 'string' },
			{
				internalType: 'address',
				name: 'newDataFeedAddress',
				type: 'address',
			},
		],
		name: 'updateDataFeed',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
];
