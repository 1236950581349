import {
	Box,
	Text,
	Flex,
	Progress,
	Stack,
	useColorMode,
	SimpleGrid,
	Input,
} from '@chakra-ui/react';
import {
	currencyFormatter,
	multiplicationCalc,
	divisionCalc,
	getFundedThreshold,
	availableTokens,
} from '../../../../utils/utils';
import MenuROIComponent from './MenuROIComponent';
import EstimatedEarnings from './EstimatedEarnings';
import CryptocurrencyBoxComponent from './CryptocurrencyBoxComponent';
import {
	IconEther,
	IconTRNSK,
	IconUSDC,
	IconUSDT,
} from '../../../../assets/Icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { valueTypeOptions } from '../../data';
import { currency2 } from '../../../../data/mockdata';
import useInfoData from '../../../../hooks/useInfoData';
import { getUserBalanceByCurrency } from '../../helper';
import { ENV } from '../../../../constants/constants';

const CalculatorROI = ({
	projectSelected,
	valueInput,
	setValueInput,
	setValueType,
	valueType,
	setSelectedCurrency,
	selectedCurrency,
	setRealCost,
	realCost,
	setNumTokens,
	numTokens,
}) => {
	const userState = useSelector(store => store.user);
	const numPaymentColumns = Object.keys(
		projectSelected.paymentMethods,
	).filter(key => projectSelected.paymentMethods[key]).length;
	const { balances } = useInfoData({
		walletAddress: userState.walletAddress,
	});

	const currentBalance = userState.userId.length
		? getUserBalanceByCurrency(selectedCurrency, balances)
		: undefined;

	const { t } = useTranslation();
	const { colorMode } = useColorMode();

	const marks = ['', '25%', '50%', '75%', ''];

	const { quantityOfTokens, pricePerToken, mintedSupply } = projectSelected;

	const funded = getFundedThreshold({ mintedSupply, quantityOfTokens });
	const remainingTokens = availableTokens({ mintedSupply, quantityOfTokens });

	const calculateNumTokens = () => {
		if (!valueInput || !valueInput.length) return '-';
		return valueType === valueTypeOptions.Fiat
			? Math.floor(divisionCalc(valueInput, pricePerToken))
			: valueInput;
	};

	const getFinalCost = () => {
		const _numTokens = calculateNumTokens();
		if (!valueInput || !valueInput.length) return '-';
		const realCost = multiplicationCalc(numTokens, pricePerToken);
		setNumTokens(_numTokens);
		setRealCost(realCost);
		return realCost;
	};

	const onChangeValue = event => {
		const { value } = event.target;
		setValueInput(value);
	};

	return (
		<Stack
			minWidth='100%'
			border='0.5px solid'
			borderColor={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyBlack.100'
			}
			borderRadius='12px'
			px='10px'
			py='15px'
			my='10px'
			gap='20px'
		>
			<Stack w='100%' align='center' gap='7px' px='15px'>
				<Text fontSize='10px' letterSpacing={-0.02}>
					{t(
						'projectDetails.offerDetails.calculatorROI.selectPayment',
					)}
				</Text>
				<SimpleGrid w='100%' columns={numPaymentColumns} spacing='10px'>
					{projectSelected.paymentMethods.usdt && (
						<CryptocurrencyBoxComponent
							icon={
								ENV === 'dev' ? (
									<IconTRNSK boxSize='14px' />
								) : (
									<IconUSDT boxSize='14px' />
								)
							}
							currency={ENV === 'dev' ? 'TRNSK' : 'USDT'}
							isSelected={selectedCurrency === 'USDT'}
							onClick={() => setSelectedCurrency('USDT')}
						/>
					)}
					{projectSelected.paymentMethods.usdc && (
						<CryptocurrencyBoxComponent
							icon={<IconUSDC boxSize='14px' />}
							currency='USDC'
							isSelected={selectedCurrency === 'USDC'}
							onClick={() => setSelectedCurrency('USDC')}
						/>
					)}
					{projectSelected.paymentMethods.eth && (
						<CryptocurrencyBoxComponent
							icon={<IconEther boxSize='14px' />}
							currency='ETH'
							isSelected={selectedCurrency === 'ETH'}
							onClick={() => setSelectedCurrency('ETH')}
						/>
					)}
				</SimpleGrid>
			</Stack>
			<Flex
				fontSize='10px'
				fontWeight='400'
				justifyContent='space-between'
			>
				{userState?.userId.length ? (
					<Flex gap='7px'>
						<Text variant='gray' fontSize='inherit'>
							{t(
								'projectDetails.offerDetails.calculatorROI.accountBalance',
							)}
							:
						</Text>
						<Text fontSize='inherit'>
							{currentBalance >= 0
								? currencyFormatter(currentBalance)
								: '-'}
						</Text>
					</Flex>
				) : null}

				<Flex gap='7px'>
					<Text variant='gray' fontSize='inherit'>
						{t(
							'projectDetails.offerDetails.calculatorROI.remainingTokens',
						)}
					</Text>
					<Text fontSize='inherit'>{remainingTokens}</Text>
				</Flex>
			</Flex>
			<Flex
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyBlack.50'
				}
				border='0.5px solid'
				borderColor='transparencyBlack.100'
				borderRadius='12'
				justifyContent='space-between'
				p='15px'
			>
				<Input
					type='number'
					fontSize='24'
					fontWeight='600'
					variant='noStyled'
					value={valueInput || ''}
					onChange={onChangeValue}
					bg='none'
					placeholder={t(
						'projectDetails.offerDetails.calculatorROI.numberInputField.value',
					)}
					_placeholder={{
						color:
							colorMode === 'dark'
								? 'transparencyWhite.500'
								: 'transparencyBlack.500',
					}}
				/>

				<MenuROIComponent
					currentValueType={valueType}
					setValueType={setValueType}
					setValueInput={setValueInput}
				/>
			</Flex>
			<Box position='relative' w='100%'>
				<Progress
					value={funded}
					h='8px'
					borderRadius='50px'
					colorScheme='brand'
				/>
				<Box position='relative' w='100%' mt='5px'>
					{marks.map((mark, index) => (
						<Text
							key={index}
							position='absolute'
							left={`${(index / (marks.length - 1)) * 100}%`}
							transform='translateX(-50%)'
							fontSize='12'
							fontWeight='400'
						>
							{mark}
						</Text>
					))}
				</Box>

				<Flex justifyContent='space-between' fontWeight='400'>
					<Text fontSize='12'>0%</Text>
					<Text fontSize='12'>100%</Text>
				</Flex>
			</Box>
			<Stack
				minWidth='100%'
				border='0.5px solid'
				borderRadius='12px'
				borderColor={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyBlack.100'
				}
				p='10px'
				my='10px'
				gap='20px'
			>
				<Flex justifyContent='space-between' px='15px' fontWeight='400'>
					<Text variant='gray' fontSize='12'>
						Num. Tokens
					</Text>

					<Flex justifyContent='space-between' gap='1'>
						<Text variant='smallColored' fontWeight='600'>
							{numTokens}
						</Text>

						<Text fontSize='12'>Tokens</Text>
					</Flex>
				</Flex>
				<Flex justifyContent='space-between' px='15px' fontWeight='400'>
					<Text variant='gray' fontSize='12'>
						{t('projectDetails.offerDetails.calculatorROI.cost')}
					</Text>
					<Flex justifyContent='space-between' gap='1'>
						<Text variant='smallColored' fontWeight='600'>
							{getFinalCost()}
						</Text>

						<Text fontSize='12'>{currency2[0]}</Text>
					</Flex>
				</Flex>
			</Stack>
			<EstimatedEarnings
				projectSelected={projectSelected}
				realCost={realCost}
			/>
		</Stack>
	);
};

export default CalculatorROI;
