export const sellerAbi = [
	{
		inputs: [
			{
				internalType: 'address',
				name: '_tokenContract',
				type: 'address',
			},
			{
				internalType: 'address',
				name: '_oracleContract',
				type: 'address',
			},
			{
				internalType: 'address',
				name: 'treasuryContract',
				type: 'address',
			},
		],
		stateMutability: 'nonpayable',
		type: 'constructor',
	},
	{ inputs: [], name: 'EnforcedPause', type: 'error' },
	{ inputs: [], name: 'ExpectedPause', type: 'error' },
	{
		inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
		name: 'OwnableInvalidOwner',
		type: 'error',
	},
	{
		inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
		name: 'OwnableUnauthorizedAccount',
		type: 'error',
	},
	{ inputs: [], name: 'ReentrancyGuardReentrantCall', type: 'error' },
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'recipient',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'projectId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'usdcAmount',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'usdtAmount',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'ethAmount',
				type: 'uint256',
			},
		],
		name: 'ClaimRefund',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'buyer',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'projectId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'price',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'string',
				name: 'paymentMethod',
				type: 'string',
			},
		],
		name: 'MintProjectToken',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'previousOwner',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'newOwner',
				type: 'address',
			},
		],
		name: 'OwnershipTransferred',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'account',
				type: 'address',
			},
		],
		name: 'Paused',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'account',
				type: 'address',
			},
		],
		name: 'Unpaused',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'recipient',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'projectId',
				type: 'uint256',
			},
		],
		name: 'WithdrawProjectFunds',
		type: 'event',
	},
	{
		inputs: [],
		name: 'ELVX_ORACLES_CONTRACT',
		outputs: [
			{
				internalType: 'contract IELVX_Oracles',
				name: '',
				type: 'address',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'ELVX_TOKEN_CONTRACT',
		outputs: [
			{
				internalType: 'contract IELVX_REToken',
				name: '',
				type: 'address',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'ELVX_TREASURY_CONTRACT',
		outputs: [
			{
				internalType: 'contract IELVX_Treasury',
				name: '',
				type: 'address',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'id', type: 'uint256' },
			{ internalType: 'uint256', name: 'amount', type: 'uint256' },
		],
		name: 'calculateETHPrice',
		outputs: [
			{ internalType: 'uint256', name: '', type: 'uint256' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'id', type: 'uint256' },
			{ internalType: 'uint256', name: 'amount', type: 'uint256' },
		],
		name: 'calculateUSDCPrice',
		outputs: [
			{ internalType: 'uint256', name: '', type: 'uint256' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'id', type: 'uint256' },
			{ internalType: 'uint256', name: 'amount', type: 'uint256' },
		],
		name: 'calculateUSDTPrice',
		outputs: [
			{ internalType: 'uint256', name: '', type: 'uint256' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'disableRefund',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'enableRefund',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'user', type: 'address' },
			{ internalType: 'uint256', name: 'tokenId', type: 'uint256' },
		],
		name: 'getUserTokenBalance',
		outputs: [
			{
				components: [
					{
						internalType: 'uint256',
						name: 'usdtAmount',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'usdcAmount',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'ethAmount',
						type: 'uint256',
					},
				],
				internalType: 'struct ELVX_RESeller.TokenInfo',
				name: '',
				type: 'tuple',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'id', type: 'uint256' },
			{ internalType: 'uint256', name: 'amount', type: 'uint256' },
		],
		name: 'mintWithETH',
		outputs: [],
		stateMutability: 'payable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'id', type: 'uint256' },
			{ internalType: 'uint256', name: 'amount', type: 'uint256' },
		],
		name: 'mintWithUSDC',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'id', type: 'uint256' },
			{ internalType: 'uint256', name: 'amount', type: 'uint256' },
		],
		name: 'mintWithUSDT',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'owner',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'pause',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'paused',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		name: 'projectTokenBalances',
		outputs: [
			{ internalType: 'uint256', name: 'usdtAmount', type: 'uint256' },
			{ internalType: 'uint256', name: 'usdcAmount', type: 'uint256' },
			{ internalType: 'uint256', name: 'ethAmount', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'refund',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'renounceOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'newOwner', type: 'address' },
		],
		name: 'transferOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'unpause',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '', type: 'address' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
		],
		name: 'userTokenBalances',
		outputs: [
			{ internalType: 'uint256', name: 'usdtAmount', type: 'uint256' },
			{ internalType: 'uint256', name: 'usdcAmount', type: 'uint256' },
			{ internalType: 'uint256', name: 'ethAmount', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'withdrawProjectFunds',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
];
