import { createIcon } from '@chakra-ui/react';

const IconReload = createIcon({
	displayName: 'IconReload',
	viewBox: '5 5 40 40',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<>
			<path
				d='M25 38c-7.2 0-13-5.8-13-13 0-3.2 1.2-6.2 3.3-8.6l1.5 1.3C15 19.7 14 22.3 14 25c0 6.1 4.9 11 11 11 1.6 0 3.1-.3 4.6-1l.8 1.8c-1.7.8-3.5 1.2-5.4 1.2z'
				stroke='currentColor'
				fill='currentColor'
			/>
			<path
				d='M34.7 33.7l-1.5-1.3c1.8-2 2.8-4.6 2.8-7.3 0-6.1-4.9-11-11-11-1.6 0-3.1.3-4.6 1l-.8-1.8c1.7-.8 3.5-1.2 5.4-1.2 7.2 0 13 5.8 13 13 0 3.1-1.2 6.2-3.3 8.6z'
				stroke='currentColor'
				fill='currentColor'
			/>
			<path
				d='M18 24h-2v-6h-6v-2h8z'
				stroke='currentColor'
				fill='currentColor'
			/>
			<path
				d='M40 34h-8v-8h2v6h6z'
				stroke='currentColor'
				fill='currentColor'
			/>
		</>
	),
});

export default IconReload;
