import { Grid, Stack, Text, useColorMode } from '@chakra-ui/react';

import InfoDashboardComponent from './InfoDashboardComponent';
import { differenceInDays } from 'date-fns';
import { currencyFormatter, formatOrDash } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getUserIncome } from '../../../services/userService';
import { useSelector } from 'react-redux';

/**
*
    @name IncomeComponent
    @description Component that allow the user to check the incomes he/she has, and how much money he/she received.
	@returns {JSX.Element} IncomeComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const IncomeComponent = () => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const [data, setData] = useState();
	const user = useSelector(store => store.user);

	useEffect(() => {
		const getIncomes = async () => {
			const { data: _data, success } = await getUserIncome(user.userId);
			if (success) setData(_data);
		};
		getIncomes();
	}, []);

	return (
		<Stack
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyWhite.950'
			}
			h='100%'
			w='100%'
			maxW='500px'
			border='0.5px solid '
			borderColor='transparencyBlack.200'
			borderRadius='12px'
			p='15px 20px'
		>
			<Text fontWeight='700' fontSize='16' lineHeight='150%'>
				{t('dashboard.income.title')}
			</Text>
			<Grid
				templateColumns={'repeat(2, minmax(0, 1fr))'}
				h='100%'
				placeItems='center'
				gap='10px'
			>
				<InfoDashboardComponent
					header={t('dashboard.income.nextMonth')}
					info={currencyFormatter(data?.nextMonth)}
					blur={true}
				/>
				<InfoDashboardComponent
					header={t('dashboard.income.upcomingPropertySale')}
					info={currencyFormatter(data?.upcoming)}
					blur={true}
				/>
				<InfoDashboardComponent
					header={t('dashboard.income.lastMonth')}
					info={formatOrDash(data?.totalIncome)}
				/>
				<InfoDashboardComponent
					header={t('dashboard.income.nextPayment')}
					info={
						differenceInDays(data?.nextPayment, new Date()) +
						' days'
					}
					blur={true}
				/>
			</Grid>
		</Stack>
	);
};

export default IncomeComponent;
