import { Stack, Text } from '@chakra-ui/react';

const DetailsComponent = ({ title, children }) => {
	return (
		<Stack direction='column' gap={15}>
			<Text variant='colored' fontWeight='bold' fontSize='20px'>
				{title}
			</Text>
			{children}
		</Stack>
	);
};
export default DetailsComponent;
