import { Stack } from '@chakra-ui/react';
import RentalIncomeOverview from './components/RentalIncomeOverview';
import CapitalGrowthAndEstimatedReturns from './components/CapitalGrowthAndEstimatedReturns';
import ProjectCostSummary from './components/ProjectCostSummary';

const FinancialSummaryTab = ({ projectInfo }) => {
	return (
		<Stack gap='26px' pb='40px'>
			<ProjectCostSummary data={projectInfo} />
			<RentalIncomeOverview data={projectInfo} />
			<CapitalGrowthAndEstimatedReturns data={projectInfo} />
		</Stack>
	);
};

export default FinancialSummaryTab;
