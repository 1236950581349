import { useState } from 'react';
import {
	Button,
	Flex,
	Icon,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Skeleton,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import ModalComponent from '../../../components/ModalComponent';
import CrossBoxComponent from '../../../components/CrossBoxComponent';
import { IconArrowDown, IconSend, IconEther } from '../../../assets/Icons';
import { tokenList } from '../../../data/optionsData';
import { useSelector } from 'react-redux';
import useTokenTransfer from '../../../hooks/useTokenTransfer';
import { useTranslation } from 'react-i18next';
import {
	availableTransakProductOptions,
	interactWithTransak,
} from '../../../lib/transak';

const SellTokensComponent = ({ isOpen, onClose, isMobile, balances }) => {
	const { colorMode } = useColorMode();
	const [selectedToken, setSelectedToken] = useState(tokenList[0]);
	const [amountToSell, setAmountToSell] = useState('');
	const [isLoadingAmount, setIsLoadingAmount] = useState(null);
	const [amountError, setAmountError] = useState(null);
	const user = useSelector(state => state.user);
	const { calculateMaxEthToSend } = useTokenTransfer();

	const { t } = useTranslation();

	const handleItemClick = item => {
		setSelectedToken(item);
	};

	const checkErrors = () => {
		let checked = { success: true, message: '' };
		if (Number(amountToSell) <= 0) {
			checked = {
				success: false,
				message: 'Introduce a value',
				type: 'amount',
			};
		}
		return checked;
	};

	const handleAmountToSendChange = event => {
		const value = event.target.value;
		setAmountToSell(value);

		const tokenKey = selectedToken.abbr.toLowerCase();

		const tokenBalance = parseFloat(balances[tokenKey]) || 0;

		const amount = parseFloat(value);

		if (isNaN(amount) && value !== '') {
			setAmountError('Invalid amount');
		} else if (amount > tokenBalance) {
			setAmountError('Insufficient balance');
		} else {
			setAmountError(null);
		}
	};

	const handleSetMaxAmount = async () => {
		if (selectedToken.abbr === 'ETH') {
			setIsLoadingAmount(true);
			const maxEth = await calculateMaxEthToSend(user.walletAddress);
			setAmountToSell(maxEth.slice(0, 6));
			setIsLoadingAmount(false);
		} else {
			const tokenKey = selectedToken.abbr.toLowerCase();
			const tokenBalance = parseFloat(balances[tokenKey]) || 0;
			setAmountToSell(tokenBalance);
		}
	};

	const handleOnCancel = () => {
		setAmountToSell('');
		setSelectedToken(tokenList[0]);
		onClose();
	};

	const handleSellConfirmation = () => {
		const error = checkErrors();

		if (!error.success) {
			if (error.type === 'amount') {
				setAmountError(error.message);
			}
		} else {
			interactWithTransak(
				{
					purchaseQuantity: amountToSell,
					userInfo: {
						email: user.email,
						walletAddress: user.walletAddress,
						userId: user.userId,
					},
					selectedCurrency: selectedToken.abbr,
					type: availableTransakProductOptions.SELL,
				},
				txResponse => console.log('Sell response', txResponse),
			);
			handleOnCancel();
		}
	};
	return (
		<ModalComponent isOpen={isOpen} onClose={onClose} modalCross={false}>
			<>
				<Flex w='100%' justify='end'>
					<CrossBoxComponent closeModal={onClose} />
				</Flex>
				<Stack
					bg={
						colorMode === 'dark'
							? 'transparencyWhite.100'
							: 'transparencyWhite.800'
					}
					border='0.5px solid'
					borderColor='transparencyBlack.200'
					borderRadius='12px'
					p='20px'
					w={isMobile ? 'fit-content' : '312px'}
					gap='20px'
				>
					<Text
						fontSize='20px'
						fontWeight='700'
						letterSpacing={-0.02}
						textAlign='center'
					>
						{t('walletPage.sellTokens')}
					</Text>
					<Flex w='100%' gap='12px'>
						<Stack minW='128px'>
							<Text
								fontSize='14px'
								fontWeight='700'
								letterSpacing={-0.02}
							>
								{t('walletPage.asset')}
							</Text>
							<Menu>
								<MenuButton
									w='100%'
									h='52px'
									fontSize='12px'
									fontWeight='light'
									borderRadius='5px'
									pl='5px'
									pr='10px'
									bg={
										colorMode === 'dark'
											? 'transparencyWhite.100'
											: 'transparencyBlack.100'
									}
									_hover={{
										bg:
											colorMode === 'dark'
												? 'transparencyWhite.200'
												: 'transparencyBlack.200',
									}}
									_active={{
										bg:
											colorMode === 'dark'
												? 'transparencyWhite.200'
												: 'transparencyBlack.200',
									}}
									spacing={7}
									rightIcon={
										<IconArrowDown
											boxSize='16px'
											color='transparencyWhite.500'
										/>
									}
									as={Button}
								>
									<Flex gap={1} alignItems='center'>
										<Icon as={IconEther} boxSize='25px' />
										<Stack textAlign='start'>
											<Text
												fontSize='13px'
												fontWeight='700'
												letterSpacing={-0.02}
												lineHeight='10px'
											>
												{selectedToken.abbr}
											</Text>
											<Text
												variant='gray'
												fontSize='13px'
												fontWeight='400'
												letterSpacing={-0.02}
												lineHeight='10px'
											>
												{selectedToken.token}
											</Text>
										</Stack>
									</Flex>
								</MenuButton>
								<MenuList fontSize='12px' zIndex={99}>
									{tokenList.map((elem, i) => (
										<MenuItem
											key={i}
											value={elem.token}
											onClick={() =>
												handleItemClick(elem)
											}
											gap='10px'
											fontSize='13px'
										>
											<Icon
												as={elem.icon}
												boxSize='17px'
											/>
											{elem.token}
										</MenuItem>
									))}
								</MenuList>
							</Menu>
						</Stack>
						<Stack>
							<Text
								fontSize='14px'
								fontWeight='700'
								letterSpacing={-0.02}
							>
								{t('walletPage.amount')}
							</Text>
							<Flex
								w='100%'
								h='52px'
								bg='transparencyWhite.100'
								align='center'
								borderRadius='5px'
								p='10px'
								boxShadow={
									amountError ? '0px 0px 0px 1px red' : 'none'
								}
							>
								<Stack
									spacing={0}
									pe='5px'
									h='40px'
									w='62.22px'
									justifyContent='center'
								>
									{!isLoadingAmount ? (
										<Input
											variant='noStyled'
											placeholder='Value'
											p={0}
											onChange={handleAmountToSendChange}
											value={amountToSell}
											type='number'
										/>
									) : (
										<Skeleton h='20px' />
									)}
								</Stack>
								<Button maxH='32px' variant='main'>
									<Text
										variant='inverse'
										fontSize='12px'
										fontWeight='700'
										lineHeight='20px'
										onClick={handleSetMaxAmount}
									>
										Max
									</Text>
								</Button>
							</Flex>
							<Text variant='error' fontSize='11px'>
								{amountError}
							</Text>
						</Stack>
					</Flex>
					<Stack flexDir='row' w='100%' justifyContent='center'>
						<Button
							variant='secondary'
							w='88.61px'
							alignSelf='center'
							gap='5px'
							onClick={onClose}
						>
							Cancel
						</Button>
						<Button
							variant='main'
							w='88.61px'
							alignSelf='center'
							gap='5px'
							onClick={handleSellConfirmation}
						>
							<IconSend boxSize='14px' />
							Sell
						</Button>
					</Stack>
				</Stack>
			</>
		</ModalComponent>
	);
};

export default SellTokensComponent;
