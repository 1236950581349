import { useState } from 'react';
import {
	Flex,
	Text,
	Button,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useColorMode,
	Icon,
} from '@chakra-ui/react';

import { IconArrowDown } from '../../../../assets/Icons';

/**
*
    @name SortFilter
	@description A custom filter dropdown component that allows users to select a filter option from a list. It displays an icon and the currently selected filter option. 
  	@returns {JSX.Element} SortFilter.js
    @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const SortFilterUnit = ({
	icon,
	w,
	filtersList,
	isTablet,
	setProjectListFiltered,
}) => {
	const { colorMode } = useColorMode();
	const [selectedFilter, setSelectedFilter] = useState(filtersList[0].name);

	const handleItemClick = item => {
		setSelectedFilter(item.name);
		setProjectListFiltered(prevState => ({
			...prevState,
			[item.group]: item.value,
		}));
	};

	return (
		<Menu variant={isTablet && 'filters'}>
			<MenuButton
				h='30px'
				w={w}
				fontSize='12px'
				fontWeight='light'
				borderRadius='50'
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyBlack.100'
				}
				_hover={{
					bg:
						colorMode === 'dark'
							? 'transparencyWhite.200'
							: 'transparencyBlack.200',
				}}
				_active={{
					bg:
						colorMode === 'dark'
							? 'transparencyWhite.200'
							: 'transparencyBlack.200',
				}}
				spacing={7}
				rightIcon={<IconArrowDown boxSize='12px' />}
				as={Button}
			>
				<Flex gap={1} alignItems='center'>
					<Icon as={icon} boxSize='12px' />
					<Text
						fontSize='12px'
						color={
							colorMode === 'dark'
								? 'transparencyWhite.700'
								: 'transparencyBlack.700'
						}
						fontWeight='medium'
						fontFamily='Urbanist'
					>
						| {selectedFilter}
					</Text>
				</Flex>
			</MenuButton>
			<MenuList fontSize='12px' zIndex={99}>
				{filtersList.map((elem, i) => (
					<MenuItem
						key={i}
						value={elem.value}
						onClick={() => handleItemClick(elem)}
					>
						{elem.name}
					</MenuItem>
				))}
			</MenuList>
		</Menu>
	);
};

export default SortFilterUnit;
