import {
	Text,
	Button,
	Stack,
	Flex,
	Icon,
	useColorMode,
	Link,
} from '@chakra-ui/react';

import { IconCheck2 } from '../assets/Icons';
import { useTranslation } from 'react-i18next';

/**
*
    @name SuccessfulBuyComponent
    @description Component to display a success message after a successful purchase or claim
    @returns {JS.Element} SuccessfulBuyComponent.js
	@author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const SuccessfulComponent = ({
	p,
	w,
	h = 'fit-content',
	text,
	txHash,
	buttonTitle,
	modal,
	handleClick,
	handleClose,
}) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();

	const bgColorModal =
		colorMode === 'dark' ? 'transparencyWhite.100' : 'transparencyBlack.50';

	const bgColorGeneral =
		colorMode === 'dark'
			? 'transparencyWhite.100'
			: 'transparencyWhite.900';

	return (
		<Flex
			bg={!modal ? bgColorModal : bgColorGeneral}
			w={w}
			h={h}
			flexDirection='column'
			alignItems='center'
			justifyContent='center'
			borderRadius='12px'
			p={p}
			gap='10px'
		>
			<Text fontSize='20' fontWeight='800'>
				{t('projectDetails.offerDetails.succesful.title')}
			</Text>
			<Text
				fontSize='14'
				fontWeight='400'
				textAlign='center'
				flexWrap='wrap'
				letterSpacing={0.02}
				maxW={'100%'}
			>
				{text}
			</Text>
			{txHash && (
				<Stack w='100%'>
					<Text
						fontSize='14'
						fontWeight='400'
						textAlign='center'
						flexWrap='wrap'
						letterSpacing={0.02}
						maxW={'100%'}
					>
						{t('projectDetails.offerDetails.succesful.txHash')}
					</Text>
					<Link
						fontSize='14'
						fontWeight='400'
						textAlign='center'
						letterSpacing={0.02}
						variant={'isActive'}
						href={`https://sepolia.arbiscan.io/tx/${txHash}`}
						isExternal
					>
						{txHash}
					</Link>
				</Stack>
			)}
			<Stack
				bg={
					colorMode === 'dark'
						? 'transparencyBlack.400'
						: 'transparencyBlack.100'
				}
				borderRadius='100'
				p='20px'
				my='10px'
			>
				<Icon
					as={IconCheck2}
					color={colorMode === 'dark' ? 'brand.200' : 'brand.500'}
					boxSize='1.8em'
				/>
			</Stack>
			<Button variant='main' onClick={handleClick}>
				{buttonTitle}
			</Button>
			{handleClose ? (
				<Button onClick={handleClose} mt={4}>
					{t('projectDetails.offerDetails.succesful.close')}
				</Button>
			) : null}
		</Flex>
	);
};

export default SuccessfulComponent;
