import axios from 'axios';
import { createHash } from '../utils/Passwords';
import { decodeToken, encodeToken } from '../utils/TokenUtils';

export const signUp = async userInfo => {
	try {
		const password = await createHash(userInfo.password);
		const avatarImage = userInfo.avatarImage;

		delete userInfo.avatarImage;

		const token = encodeToken({
			fullname: userInfo.fullname,
			username: userInfo.username,
			email: userInfo.email,
			password,
		});

		const formData = new FormData();
		formData.append('file', avatarImage);
		formData.append('token', token);

		const response = await axios.post('/user/create', formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (error.response.data.message) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const resetPassword = async email => {
	try {
		const token = encodeToken({ email });

		const response = await axios.post('/user/reset-password', {
			token,
		});

		return { success: true, data: response.data.data };
	} catch (error) {
		if (error.response.data.message) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const validateCode = async (code, type) => {
	try {
		const response = await axios.post('/code/verify', {
			code,
			type,
		});
		return { success: true, data: response.data.data };
	} catch (error) {
		if (error.response.data.message) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const changePassword = async (email, password) => {
	try {
		const oldPasswordEncrypted = password.oldPassword;
		const newPasswordEncrypted = await createHash(password.newPassword);

		const token = encodeToken({
			oldPassword: oldPasswordEncrypted,
			newPassword: newPasswordEncrypted,
		});

		const response = await axios.patch(`/user/change-password/${email}`, {
			token,
		});
		return { success: true, data: response.data.data };
	} catch (error) {
		if (error.response.data.message) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const changeWalletAddress = async ({ email, walletAddress }) => {
	try {
		const token = encodeToken({ walletAddress });

		const response = await axios.patch(
			`/user/change-wallet-address/${email}`,
			{
				token,
			},
		);
		return { success: true, data: response.data.message };
	} catch (error) {
		if (error.response.data.message) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const changeIsFirstTime = async email => {
	try {
		const response = await axios.patch(`/user/change-first-time/${email}`);
		return { success: true, data: response.data.data };
	} catch (error) {
		if (error.response.data.message) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const getUserProfile = async () => {
	try {
		const response = await axios.get('/user/profile');

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const updateUserSettings = async userInfo => {
	const avatarImage = userInfo?.avatarImage;
	const token = encodeToken({ ...userInfo });
	try {
		const formData = new FormData();
		formData.append('file', avatarImage);
		formData.append('token', token);

		const response = await axios.put('/user/update', formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});

		return { success: true, data: response.data.data };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const deleteUser = async () => {
	try {
		const response = await axios.delete('/user/delete');
		return { success: true, data: response.data.data };
	} catch (error) {
		const message = error.response?.data?.message;
		return { success: false, message: message || 'Something went wrong.' };
	}
};

export const getUserTotalInvested = async () => {
	try {
		const response = await axios.post('/log/user/invested');

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const getUserIncome = async () => {
	try {
		const response = await axios.post('/log/user/income');

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

/**
 * Get all the minted logs of the user
 */
export const getUserAllMintedLogs = async () => {
	try {
		const response = await axios.get('/log/user/all/minted');

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};
