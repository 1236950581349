import {
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	useColorMode,
	useMediaQuery,
} from '@chakra-ui/react';

import {
	IconDots,
	IconSales,
	IconShare,
	IconTrade,
	IconTransfer,
	IconView,
} from '../../../../assets/Icons';

const OptionsComponent = ({ onOpenSendModal, project }) => {
	const [isMobile] = useMediaQuery('(max-width: 1280px)');

	const { colorMode } = useColorMode();
	const colorBorder =
		colorMode === 'dark'
			? 'transparencyWhite.100'
			: 'transparencyBlack.100';

	return (
		<>
			{!isMobile && (
				<Flex w='fit-content' borderRadius='5px' overflow='hidden'>
					<Button
						variant='squared'
						borderRight='1px solid'
						borderColor={colorBorder}
					>
						<IconView boxSize='14px' mr={1} /> Details
					</Button>
					<Button
						variant='squared'
						borderRight='1px solid'
						borderColor={colorBorder}
						onClick={() => onOpenSendModal(project.metadataId)}
					>
						<IconTransfer boxSize='14px' mr={1} /> Transfer
					</Button>
					<Button
						variant='squared'
						borderRight='1px solid'
						borderColor={colorBorder}
					>
						<IconTrade boxSize='14px' mr={1} /> Trade
					</Button>
					<Button
						variant='squared'
						borderRight='1px solid'
						borderColor={colorBorder}
					>
						<IconSales boxSize='14px' mr={1} /> Quick Sell
					</Button>
					<Button variant='squared' p={2} py={4}>
						<IconShare boxSize='12px' mr={1} /> Share
					</Button>
				</Flex>
			)}
			{isMobile && (
				<Menu variant='simple'>
					<MenuButton>
						<IconDots boxSize='14px' />
						<MenuList>
							<MenuItem>
								<IconView boxSize='14px' mr={1} /> Details
							</MenuItem>
							<MenuItem>
								<IconTransfer boxSize='14px' mr={1} /> Transfer
							</MenuItem>
							<MenuItem>
								<IconTrade boxSize='14px' mr={1} /> Trade
							</MenuItem>
							<MenuItem>
								<IconSales boxSize='14px' mr={1} /> Quick Sell
							</MenuItem>
							<MenuItem>
								<IconShare boxSize='12px' mr={1} /> Share
							</MenuItem>
						</MenuList>
					</MenuButton>
				</Menu>
			)}
		</>
	);
};

export default OptionsComponent;
