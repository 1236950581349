import { useEffect, useState } from 'react';
import { magic } from '../lib/magic.js';
import { changeWalletAddress } from '../services/userService';
import { useSelector } from 'react-redux';
const { ethers } = require('ethers');

const useMagic = () => {
	const user = useSelector(state => state.user);
	const initialState = {
		email: null,
		publicAddress: null,
	};

	useEffect(() => {
		const checkUserAuth = async () => {
			try {
				const isLogged = await magic.user.isLoggedIn();
				setIsLoggedIn(isLogged);

				if (isLogged) {
					await obtainUserData();
				}
			} catch (error) {
				restoreDefaultState();
				throw error;
			}
		};

		checkUserAuth();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/* ╔══════════════════════════════════════════╗
       ║                MAGIC STATE               ║
       ╚══════════════════════════════════════════╝ */

	const [currentUser, setCurrentUser] = useState(initialState);
	const [balance, setBalance] = useState(0);
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	/* ╔══════════════════════════════════════════╗
       ║              MAGIC FUNCTIONS             ║
       ╚══════════════════════════════════════════╝ */

	/*
	 * @name restoreDefaultState
	 * @description Resets the current user state to its initial state.
	 * @returns {void}
	 */
	const restoreDefaultState = () => {
		setCurrentUser(initialState);
		setIsLoggedIn(false);
	};

	/*
	 * @name loginWithMagicLink
	 * @description Logs in the user using Magic Link.
	 * @param {string} email - The user's email.
	 * @param {boolean} showUI - Whether to show the UI.
	 * @returns {void}
	 */
	const loginWithMagicLink = async (
		email,
		showUI = false,
		firstTime = true,
	) => {
		try {
			if (isLoggedIn && user.walletAddress !== '') return;

			const loginMagicLink = await magic.auth.loginWithMagicLink({
				email,
				showUI,
			});

			setIsLoggedIn(true);

			const { email: userEmail, publicAddress: walletAddress } =
				await obtainUserData();

			if (!firstTime || !loginMagicLink) return walletAddress;

			changeWalletAddress({
				email: userEmail,
				walletAddress,
			});

			return walletAddress;
		} catch (error) {
			// TODO: Handle error.
			console.error('🚀 ~ useMagic ~ error:', error);
		}
	};

	/**
	 * @name obtainUserData
	 * @description Obtains the user's data.
	 * @returns {void}
	 */
	const obtainUserData = async () => {
		try {
			const web3Provider = new ethers.BrowserProvider(magic.rpcProvider);

			const [user] = await Promise.all([
				magic.user.getInfo(),
				web3Provider.getSigner(),
			]);

			const requestBalance = await web3Provider.getBalance(
				user.publicAddress,
			);
			const formattedBalance = ethers.formatEther(requestBalance);

			setCurrentUser(user);
			setBalance(formattedBalance);

			return {
				publicAddress: user.publicAddress,
				email: user.email,
			};
		} catch (error) {
			restoreDefaultState();
			throw error;
		}
	};

	/**
	 * @name logoutWithMagicLink
	 * @description Logs out the user using Magic Link.
	 * @returns {void}
	 */
	const logoutWithMagicLink = async () => {
		if (isLoggedIn) {
			await magic.user.logout();
			restoreDefaultState();
		}
	};

	return {
		currentUser,
		balance,
		isConnected: isLoggedIn,
		obtainUserData,
		loginWithMagicLink,
		logoutWithMagicLink,
	};
};

export default useMagic;
