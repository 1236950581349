import {
	Text,
	Flex,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuDivider,
	useColorMode,
} from '@chakra-ui/react';
import { IconArrowDown } from '../../../../assets/Icons';
import { useTranslation } from 'react-i18next';
import { valueTypeOptions } from '../../data';
import { currency2 } from '../../../../data/mockdata';

const MenuROIComponent = ({
	setValueInput,
	setValueType,
	currentValueType,
}) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();

	const onSelectType = type => {
		if (type !== currentValueType) {
			setValueType(type);
			setValueInput(undefined);
		}
	};

	return (
		<Menu variant={'withoutBg'}>
			<MenuButton bg='none' _hover='none' _active='none'>
				<Flex align='center' gap='5px'>
					<Text whiteSpace='nowrap'>
						{currentValueType === valueTypeOptions.Tokens
							? t(
									'projectDetails.offerDetails.calculatorROI.menuROI.projectToken',
								)
							: currency2[0]}
					</Text>
					<IconArrowDown
						boxSize='15px'
						color={
							colorMode === 'dark'
								? 'transparencyWhite.900'
								: 'transparencyBlack.700'
						}
					/>
				</Flex>
			</MenuButton>
			<MenuList>
				<MenuItem onClick={() => onSelectType(valueTypeOptions.Tokens)}>
					<Flex flexDirection='column' gap='5px' p='10px'>
						<Text variant='colored' fontWeight='600'>
							{' '}
							{t(
								'projectDetails.offerDetails.calculatorROI.menuROI.projectToken',
							)}
						</Text>
						<Text fontSize='11'>
							{t(
								'projectDetails.offerDetails.calculatorROI.menuROI.projectTokenDescription',
							)}
						</Text>
					</Flex>
				</MenuItem>
				<MenuDivider />
				<MenuItem onClick={() => onSelectType(valueTypeOptions.Fiat)}>
					<Flex flexDirection='column' gap='5px' p='5px'>
						<Text variant='colored' fontWeight='600'>
							$USD
						</Text>
						<Text fontSize='11'>
							{t(
								'projectDetails.offerDetails.calculatorROI.menuROI.divisaDescription',
							)}
						</Text>
					</Flex>
				</MenuItem>
			</MenuList>
		</Menu>
	);
};

export default MenuROIComponent;
