import InfoSummaryComponent from './InfoSummaryComponent';
import FinancialSummaryComponent from './FinancialSummaryComponent';
import { useTranslation } from 'react-i18next';
import {
	getElevexFee,
	getTotal,
} from '../../../../../../utils/projectDataUtils';

const ProjectCostSummary = ({ data }) => {
	const { t } = useTranslation();
	const { taxesAndOperatingCosts, currentPropertyValue } = data;

	return (
		<FinancialSummaryComponent
			title={t('projectDetails.projectMoreInfo.projectCostSummary.title')}
		>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.projectCostSummary.currentPropertyValue',
				)}
				amount={currentPropertyValue}
			/>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.projectCostSummary.taxesAndOperatingCosts',
				)}
				amount={taxesAndOperatingCosts}
			/>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.projectCostSummary.renovationAndFitoutsCosts',
				)}
				amount={taxesAndOperatingCosts}
			/>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.projectCostSummary.elevexFee',
				)}
				amount={getElevexFee(data)}
				total
			/>
			<InfoSummaryComponent
				header={t(
					'projectDetails.projectMoreInfo.projectCostSummary.total',
				)}
				amount={getTotal(data)}
				total
			/>
		</FinancialSummaryComponent>
	);
};

export default ProjectCostSummary;
