import { Grid, Text } from '@chakra-ui/react';
import PropertyCardComponent from '../../../../components/ProjectCardComponent/PropertyCardComponent';
import { useTranslation } from 'react-i18next';

const CardTokenGallery = ({ isTablet, isMobile, projects }) => {
	const { t } = useTranslation();
	let templateGallery;

	if (isTablet) {
		if (!isMobile) {
			templateGallery = 'repeat(2, minmax(0, 1fr))';
		}
	} else {
		templateGallery = 'repeat(3, minmax(0, 1fr))';
	}

	return (
		<>
			{projects?.length > 0 ? (
				<Grid templateColumns={templateGallery} gap='20px'>
					{projects.map(({ project, gallery }, idx) => {
						return (
							<PropertyCardComponent
								key={idx}
								project={project}
								gallery={gallery}
							/>
						);
					})}
				</Grid>
			) : (
				<Text>{t('dashboard.myProperties.noTokens')}</Text>
			)}
		</>
	);
};

export default CardTokenGallery;
