import { Flex, Skeleton, Text, useColorMode } from '@chakra-ui/react';
/* import { BillsIcon } from '../../../assets/Icons'; */

const SmallInfoComponent = ({ icon, smallText, bigText, blur = false }) => {
	const { colorMode } = useColorMode();
	return (
		<Flex h='100%' align='center' p='10px' gap='10px'>
			<Flex
				h='40px'
				w='40px'
				bg={colorMode === 'dark' ? 'brand.200' : 'brand.500'}
				borderRadius='5px'
				justify='center'
				align='center'
				color={colorMode === 'dark' ? 'black' : 'white'}
			>
				{icon}
			</Flex>
			<Flex flexDirection='column' justifyContent='center' gap='3px'>
				<Text
					variant='gray'
					fontSize='12px'
					fontWeight='400'
					letterSpacing={-0.03}
					lineHeight='15px'
				>
					{smallText}
				</Text>
				{bigText ? (
					<Text
						fontSize='20px'
						fontWeight='700'
						letterSpacing={-0.03}
						lineHeight='22px'
						filter={blur ? 'blur(6px)' : 'none'}
					>
						{bigText}
					</Text>
				) : (
					<Skeleton h='20px' w='50px' />
				)}
			</Flex>
		</Flex>
	);
};

export default SmallInfoComponent;
