import {
	Box,
	Flex,
	Image,
	Text,
	useColorMode,
	Stack,
	useTheme,
	useClipboard,
} from '@chakra-ui/react';

import { IconArrowDown, IconShare } from '../../../../assets/Icons';

import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { PublicRoutes } from '../../../../routes/Routes';
import { useTranslation } from 'react-i18next';
import { AWS_S3 } from '../../../../constants/constants';
import { getProjectState } from '../../../../services/propertyService';
import { propertyStatus } from '../../../../data/optionsData';

/**
*
    @name SliderPropertyCard
    @description It is a slider of the photos that the property has. This is the head part of the PropertyCard Component.
    @returns {JS.Element} SliderPropertyCard.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const SliderPropertyCard = ({ project }) => {
	const { onCopy } = useClipboard(
		`${window.location.origin}/${PublicRoutes.Project}/${project.project.projectId}`,
	);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const theme = useTheme();
	const { colorMode } = useColorMode();
	const [showNavigation, setShowNavigation] = useState(false);
	const swiperRef = useRef(null);
	const prevButtonRef = useRef(null);
	const nextButtonRef = useRef(null);
	const status = getProjectState(project.project);

	const gallery = project.gallery.map(
		item =>
			`${AWS_S3}/projects/${project.project.metadataId}/gallery/${item.fileName}`,
	);

	const bulletColor =
		colorMode === 'dark'
			? theme.colors.brand[200]
			: theme.colors.brand[500];

	const bgBulletColor = theme.colors.transparencyWhite[800];

	const handlePrev = () => {
		if (swiperRef.current) {
			swiperRef.current.slidePrev();
		}
	};

	const handleNext = () => {
		if (swiperRef.current) {
			swiperRef.current.slideNext();
		}
	};

	return (
		<Box
			onMouseEnter={() => setShowNavigation(true)}
			onMouseLeave={() => setShowNavigation(false)}
			position='relative'
		>
			<Flex
				color='black'
				w='100%'
				alignItems='center'
				position='absolute'
				left='5px'
				top='4px'
				p='1'
				gap='8px'
				zIndex={9}
			>
				<Stack
					bg={
						status === propertyStatus.Cancelled
							? 'red.500'
							: colorMode === 'dark'
								? 'brand.200'
								: 'brand.500'
					}
					borderRadius='15px'
					justifyContent='center'
					px='10px'
					py='5px'
				>
					<Text
						fontSize='10px'
						fontWeight='300'
						color={
							status === propertyStatus.Cancelled
								? 'transparencyWhite.800'
								: colorMode === 'dark'
									? 'transparencyBlack.800'
									: 'transparencyWhite.800'
						}
					>
						{t(`sliderPropertyCard.tag.${status}`)}
					</Text>
				</Stack>

				<Stack
					bg='transparencyWhite.800'
					borderRadius='15px'
					justifyContent='center'
					px='10px'
					py='5px'
				>
					<Text fontSize='10px' fontWeight='300'>
						{t(
							`sliderPropertyCard.tag.${project.project.category}`,
						)}
					</Text>
				</Stack>
			</Flex>

			<Flex
				bg='transparencyWhite.800'
				w='25px'
				h='25px'
				borderRadius='50%'
				position='absolute'
				top='8px'
				right='8px'
				zIndex={9}
				align='center'
				justify='center'
				onClick={onCopy}
				_hover={{ backgroundColor: 'transparencyWhite.500' }}
			>
				<IconShare color='transparencyBlack.800' boxSize='10px' />
			</Flex>

			<Swiper
				modules={[Navigation, Pagination, Scrollbar, A11y]}
				spaceBetween={0}
				slidesPerView={1}
				onSwiper={swiper => (swiperRef.current = swiper)}
				pagination={{ clickable: true, dynamicBullets: true }}
				style={{
					borderRadius: '12px 12px 0 0',
					aspectRatio: '16 / 12',
				}}
			>
				<SwiperSlide>
					<Image
						objectFit='cover'
						h='300px'
						w='100%'
						src={`${AWS_S3}/projects/${project.project.metadataId}/gallery/${project?.project?.featureImage}`}
						onClick={() =>
							navigate(
								`/${PublicRoutes.Project}/${project.project.projectId}`,
							)
						}
					/>
				</SwiperSlide>
				{gallery.map((url, idx) => (
					<SwiperSlide key={idx}>
						{url?.endsWith('.mp4') ? (
							<video
								title={`video-${idx}`}
								style={{
									margin: '0px',
									padding: '0px',
									border: 'none',
									objectFit: 'cover',
									borderRadius: '3px',
									height: '300px',
									width: '100%',
								}}
								src={url}
								autoPlay
								loop
								muted
								onClick={() =>
									navigate(
										`/${PublicRoutes.Project}/${project.project.projectId}`,
									)
								}
							/>
						) : (
							<Image
								objectFit='cover'
								h='300px'
								w='100%'
								src={url}
								onClick={() =>
									navigate(
										`/${PublicRoutes.Project}/${project.project.projectId}`,
									)
								}
							/>
						)}
					</SwiperSlide>
				))}
				<style>
					{`
						.swiper-pagination-bullet {
							width: 7px;
							height: 7px;
							background-color: ${bgBulletColor};
							opacity: 1 ;
							}
						.swiper-pagination-bullet-active {
							background-color: ${bulletColor};
						}
					`}
				</style>
			</Swiper>
			<>
				<Flex
					ref={prevButtonRef}
					bg='transparencyWhite.800'
					borderRadius='100%'
					boxSize='25px'
					position='absolute'
					top='50%'
					left='10px'
					zIndex='9'
					transform='translateY(-50%)'
					opacity={showNavigation ? '1' : '0'}
					transition={'opacity 0.4s, background-color 0.2s'}
					_hover={{ bg: 'transparencyWhite.900' }}
					p='2px'
					alignItems='center'
					justifyContent='center'
					onClick={handlePrev}
					cursor='pointer'
					boxShadow={'sm'}
				>
					<IconArrowDown
						color='transparencyBlack.800'
						boxSize='9px'
						sx={{ transform: 'rotate(90deg)' }}
					/>
				</Flex>

				<Flex
					ref={nextButtonRef}
					bg='transparencyWhite.800'
					borderRadius='100%'
					boxSize='25px'
					position='absolute'
					top='50%'
					right='10px'
					zIndex='9'
					transform='translateY(-50%)'
					opacity={showNavigation ? '1' : '0'}
					transition={'opacity 0.4s, background-color 0.2s'}
					_hover={{ bg: 'transparencyWhite.700' }}
					alignItems='center'
					justifyContent='center'
					onClick={handleNext}
					cursor='pointer'
				>
					<IconArrowDown
						color='transparencyBlack.800'
						boxSize='9px'
						sx={{ transform: 'rotate(270deg)' }}
						ml='1px'
					/>
				</Flex>
			</>
		</Box>
	);
};

export default SliderPropertyCard;
