import BoxComponent from '../../../components/BoxComponent';
import { Grid, Skeleton, Text } from '@chakra-ui/react';
import InfoDashboardComponent from './InfoDashboardComponent';
import { currencyFormatter } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getUserTotalInvested } from '../../../services/userService';

/**
*
    @name OverviewComponent
    @description Component that give the user a general view of the projects, the status of them and the general revenue.
	@returns {JSX.Element} OverviewComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const OverviewComponent = ({ ownedProjects, isTablet, userId }) => {
	const { t } = useTranslation();
	const [totalInvested, setTotalInvested] = useState();

	useEffect(() => {
		const getTotalInvested = async () => {
			const _totalInvested = await getUserTotalInvested(userId);
			setTotalInvested(_totalInvested);
		};
		getTotalInvested();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<BoxComponent
			h='100%'
			w='100%'
			maxW='500px'
			p='0'
			inPadding='15px 20px'
			gap='8px'
		>
			<Text fontSize='16' fontWeight='700'>
				{t('dashboard.overview.title')}
			</Text>
			<Grid
				templateColumns={
					isTablet
						? 'repeat(2, minmax(0, 1fr))'
						: 'repeat(3, minmax(0, 1fr))'
				}
				h='100%'
				placeItems='center'
				gap='10px'
			>
				{totalInvested?.success ? (
					<InfoDashboardComponent
						header={t('dashboard.overview.totalInvested')}
						info={currencyFormatter(totalInvested.data)}
					/>
				) : (
					<Skeleton h='14px' w='70px' />
				)}
				<InfoDashboardComponent
					header='Total P&L'
					info={currencyFormatter(25215)}
					blur={true}
				/>

				<InfoDashboardComponent header='ROI' info='15%' blur={true} />

				<InfoDashboardComponent
					header={t('dashboard.overview.projectExited')}
					info={10}
					blur={true}
				/>
				<InfoDashboardComponent
					header={t('dashboard.overview.projectOwner')}
					info={ownedProjects ?? 0}
				/>
				<InfoDashboardComponent
					header={t('dashboard.overview.averageMonthlyIncome')}
					info='1000 USD'
					blur={true}
				/>
			</Grid>
		</BoxComponent>
	);
};

export default OverviewComponent;
