import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Text,
	Textarea,
} from '@chakra-ui/react';
import { IconClose, IconPlus, IconSave } from '../../../../assets/Icons';
import ProjectUpdatesTable from './ProjectUpdatesTable';
import { useState } from 'react';

/**
 * @name ProjectUpdatesComponent
 * @description This component allows users to add, edit, and manage project updates for a given property. If updates exist, they are displayed in a table. The component manages the state for new and existing updates and handles editing logic.
 * @param {Object} propertyInfo - The information of the property containing the updates.
 * @param {Function} setPropertyInfo - A function to update the property information.
 * @param {number | null} selectedUpdateIndex - The index of the update currently being edited, or null if adding a new update.
 * @param {Function} setSelectedUpdateIndex - A function to set the index of the update being edited.
 * @returns {JSX.Element} The ProjectUpdatesComponent to handle project updates.
 * @author Ángel González | Unknown Gravity - All-in-one Blockchain Company
 * @version 1.5.0
 */

const newUpdateInitialValues = { title: '', description: '' };

const ProjectUpdatesComponent = ({
	initialValues,
	handleOnChange,
	currentValues,
	setTabChangeDisabled,
	setPropertyInfo,
}) => {
	const [newUpdate, setNewUpdate] = useState();
	const newUpdateList = currentValues?.updates ?? [];
	const updatedList = currentValues?.updatedUpdates ?? [];
	const allUpdates = initialValues.updates
		.concat(updatedList)
		.concat(newUpdateList);
	const [editIndex, setEditIndex] = useState();

	const disableAddButton =
		!newUpdate?.title?.length || !newUpdate?.description?.length;

	const onChangeInput = event => {
		const { name, value } = event.target;
		setNewUpdate({ ...newUpdate, [name]: value });
	};

	const onAddUpdate = () => {
		const newElement = {
			...newUpdate,
			id: Math.floor(Math.random() * 100),
		};
		const updateList = newUpdateList.concat(newElement);
		setNewUpdate(newUpdateInitialValues);
		handleOnChange({
			...currentValues,
			updates: updateList,
		});
	};

	const onEditUpdate = selectedIndex => {
		setTabChangeDisabled(true);
		setEditIndex(selectedIndex);
		const selectedElement = allUpdates[selectedIndex];
		setNewUpdate(selectedElement);
	};

	const onDeleteUpdate = selectedIndex => {
		const element = allUpdates[selectedIndex];

		if (element.updateId) {
			const filteredInitialValues = initialValues?.updates.filter(
				item => item.updateId !== element.updateId,
			);

			setPropertyInfo({
				...initialValues,
				updates: filteredInitialValues,
			});

			handleOnChange({
				...currentValues,
				deletedUpdates: [
					{ ...currentValues?.deletedUpdates, ...element },
				],
			});
		} else {
			const filteredList = newUpdateList.filter(
				item => item.id !== element.id,
			);
			handleOnChange({
				...currentValues,
				updates: filteredList,
			});
		}
	};

	const onSaveEditUpdate = () => {
		const element = newUpdate;
		let _newList = newUpdateList;
		let _updatedList = updatedList;

		if (element.updateId) {
			const filteredList = updatedList.filter(
				item => item.updateId !== newUpdate.updateId,
			);
			const filteredInitialValues = initialValues?.updates.filter(
				item => item.updateId !== element.updateId,
			);
			setPropertyInfo({
				...initialValues,
				updates: filteredInitialValues,
			});

			_updatedList = filteredList.concat(element);
			handleOnChange({ ...currentValues, updatedUpdates: _updatedList });
		} else {
			const filteredList = newUpdateList.filter(
				item => item.id !== newUpdate.id,
			);
			element.title = newUpdate.title;
			element.description = newUpdate.description;
			_newList = filteredList.concat(element);
			handleOnChange({ ...currentValues, updates: _newList });
		}
		setNewUpdate(newUpdateInitialValues);
		setEditIndex(undefined);
		setTabChangeDisabled(false);
	};

	const onCancelEdit = () => {
		setNewUpdate(newUpdateInitialValues);
		setEditIndex(undefined);
		setTabChangeDisabled(false);
	};

	return (
		<Grid templateColumns='repeat(12, 1fr)' gap='25px'>
			<FormControl gridColumn='span 12'>
				<FormLabel variant='propertyLabel'>Title</FormLabel>
				<Input
					placeholder='Add project update title'
					name='title'
					value={newUpdate?.title}
					onChange={onChangeInput}
				/>
			</FormControl>

			<FormControl gridColumn='span 12'>
				<FormLabel variant='propertyLabel'>Description</FormLabel>
				<Textarea
					rows={5}
					placeholder='Add project update description'
					name='description'
					value={newUpdate?.description}
					onChange={onChangeInput}
				/>
			</FormControl>
			<Flex gridColumn='span 12' justify='end'>
				{typeof editIndex === 'number' ? (
					<Flex gap='10px'>
						<Button
							variant='secondary'
							gap='5px'
							onClick={onCancelEdit}
						>
							<IconClose boxSize='13px' />
							<Text fontSize='13px'>Cancel</Text>
						</Button>
						<Button
							variant='main'
							gap='5px'
							onClick={onSaveEditUpdate}
						>
							<IconSave boxSize='13px' />
							<Text fontSize='13px'>Save edit</Text>
						</Button>
					</Flex>
				) : (
					<Button
						variant='secondary'
						gap='5px'
						onClick={onAddUpdate}
						isDisabled={disableAddButton}
					>
						<IconPlus boxSize='13px' mt='-2px' />
						<Text fontSize='13px'>Add update</Text>
					</Button>
				)}
			</Flex>
			{allUpdates.length > 0 && (
				<Box gridColumn='span 12'>
					<ProjectUpdatesTable
						currentUpdates={allUpdates}
						onEditUpdate={onEditUpdate}
						onDeleteUpdate={onDeleteUpdate}
					/>
				</Box>
			)}
		</Grid>
	);
};

export default ProjectUpdatesComponent;
