import {
	Card,
	CardBody,
	Flex,
	Image,
	useBoolean,
	useDisclosure,
} from '@chakra-ui/react';

import InfoPropertyCard from './InfoPropertyCard';
import PropertyCardHover from './PropertyCardHover';
import { propertyPercentage } from '../../utils/utils';
import { useRef } from 'react';
import { AWS_S3 } from '../../constants/constants';
import SendProjectTokensComponent from '../SendProjectTokensComponent';

/**
*
    @name PropertyCardComponent
    @description It is the component that shows the photos and the information of the property in a Card format.
    @returns {JS.Element} PropertyCardComponent.js
    @Version 1.0.0
*/
const PropertyCardComponent = ({ project, isMobile, gallery }) => {
	const imgRef = useRef();
	const [projectMenuView, setProjectMenuView] = useBoolean(false);

	const {
		isOpen: isOpenSendlModal,
		onOpen: onOpenSendModal,
		onClose: onCloseSendModal,
	} = useDisclosure();

	const onSendProjectTokens = e => {
		onOpenSendModal();
	};

	return (
		<>
			{project && (
				<Card borderRadius='12px'>
					<CardBody p='0' w='100%'>
						<Flex
							ref={imgRef}
							position='relative'
							overflow='hidden'
							borderTopRadius='12px'
							alignItems='center'
							justifyContent='center'
							onMouseEnter={() => setProjectMenuView.on()}
							onMouseLeave={() => setProjectMenuView.off()}
						>
							<Image
								objectFit='cover'
								h='250px'
								w='312px'
								src={`${AWS_S3}/projects/${project?.metadataId}/gallery/${project?.featureImage}`}
								filter={projectMenuView ? 'blur(30px)' : 'none'}
							/>
							{projectMenuView && (
								<PropertyCardHover
									projectId={project.projectId}
									onSend={onSendProjectTokens}
								/>
							)}
						</Flex>

						<InfoPropertyCard
							project={project}
							percentage={propertyPercentage(project)}
						/>
					</CardBody>
				</Card>
			)}
			{isOpenSendlModal && (
				<SendProjectTokensComponent
					isOpenSendModal={isOpenSendlModal}
					closeSendModal={onCloseSendModal}
					isMobile={isMobile}
					project={project}
					gallery={gallery}
				/>
			)}
		</>
	);
};

export default PropertyCardComponent;
