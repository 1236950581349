import { HStack, Icon, Stack, Text } from '@chakra-ui/react';
import IconArrowRight from '../assets/Icons/components/IconArrowRight';
import IconArrowLeft from '../assets/Icons/components/IconArrowLeft';

const PaginationComponent = ({ pageSize, totalProjects, page, setPage }) => {
	const pages = Math.ceil(totalProjects / pageSize);

	const handlePreviousPage = () => {
		setPage(page === 1 ? pages : page - 1);
	};

	const handleNextPage = () => {
		setPage(page === pages ? 1 : page + 1);
	};

	return (
		<HStack width='100%' justify={'end'} align={'center'} spacing={5}>
			<HStack spacing={2}>
				<Text>{page}</Text>
				<Text>-</Text>
				<Text>{pages}</Text>
				<Text>pages</Text>
			</HStack>
			<HStack spacing={5}>
				<Stack
					bgColor={'transparencyWhite.200'}
					p={1}
					rounded={4}
					cursor={'pointer'}
					_hover={{ bgColor: 'transparencyWhite.300' }}
					onClick={handlePreviousPage}
				>
					<Icon as={IconArrowLeft} boxSize='15px' color='white' />
				</Stack>
				<Stack
					bgColor={'transparencyWhite.200'}
					p={1}
					rounded={4}
					cursor={'pointer'}
					_hover={{ bgColor: 'transparencyWhite.300' }}
					onClick={handleNextPage}
				>
					<Icon as={IconArrowRight} boxSize='15px' color='white' />
				</Stack>
			</HStack>
		</HStack>
	);
};

export default PaginationComponent;
