import { AspectRatio, Stack, Text } from '@chakra-ui/react';
import FaqsComponent from './FaqsComponent';
import MapComponent from './MapComponent/OlayersMapComponent';
import DetailsComponent from './DetailsComponent';
import { useTranslation } from 'react-i18next';

const DetailsTab = ({ projectInfo }) => {
	const { t } = useTranslation();

	return (
		<Stack direction='column' gap='30px'>
			<DetailsComponent
				title={t('projectDetails.projectMoreInfo.detailsTab.title')}
				data={projectInfo}
			>
				<Text>
					{projectInfo.description ??
						t(
							'projectDetails.projectMoreInfo.detailsTab.noAddedDescription',
						)}
				</Text>
			</DetailsComponent>
			<DetailsComponent
				title={t('projectDetails.projectMoreInfo.detailsTab.location')}
			>
				{/* Para que se visualice el mapa de google hay que envolver el MapComponent dentro del AspectRatio */}
				{projectInfo.coordinates ? (
					<AspectRatio ratio={16 / 9}>
						<MapComponent coordinates={projectInfo.coordinates} />
					</AspectRatio>
				) : (
					<Text>
						{t(
							'projectDetails.projectMoreInfo.detailsTab.noAddedLocation',
						)}
					</Text>
				)}
			</DetailsComponent>
			<DetailsComponent title='FAQs'>
				{projectInfo.faqs.length ? (
					<FaqsComponent faqs={projectInfo.faqs} />
				) : (
					<Text>
						{t(
							'projectDetails.projectMoreInfo.detailsTab.noAddedFaqs',
						)}
					</Text>
				)}
			</DetailsComponent>
		</Stack>
	);
};

export default DetailsTab;
