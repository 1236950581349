/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Checkbox,
	CheckboxGroup,
	Divider,
	Flex,
	FormLabel,
	Grid,
	Stack,
	Text,
} from '@chakra-ui/react';

import {
	distributionFrequencyOptions,
	KYCOptions,
} from '../../../../data/optionsData';
import FormControlCustomComponent from './FormControlCustomComponent';

import AddDocumentsComponent from './AddDocumentsComponent';
import InfoBoxesComponent from './InfoBoxesComponent/InfoBoxesComponent';
import { availablePaymentMethods } from '../../data';

const FinancialDetailsComponent = ({
	initialValues,
	handleOnChange,
	incorrectData,
	deleteFromIconrrectData,
	currentValues,
	isPurchased,
	informationTabData,
	setPropertyInfo,
}) => {
	const getDefaultPaymentMethods = () => {
		const currentPaymentMethod =
			currentValues?.projectMethodPayments ??
			initialValues?.projectMethodPayments;
		return currentPaymentMethod
			? Object.keys(currentPaymentMethod).filter(
					key => currentPaymentMethod[key],
				)
			: [];
	};

	const onChangeSelector = selection => {
		const obj = { ...currentValues, ...selection };
		const name = Object.keys(selection)[0];
		deleteFromIconrrectData(name);
		handleOnChange(obj);
	};

	const onChangeTextInput = event => {
		const { name, value } = event.target;
		const obj = {
			...currentValues,
			[name]: value,
		};
		deleteFromIconrrectData(name);
		handleOnChange(obj);
	};

	const onChangeNumberInput = event => {
		const { name, value } = event.target;
		const parsedValue = parseFloat(Number(value));
		const obj = {
			...currentValues,
			[name]: value.length ? parsedValue : '',
		};
		deleteFromIconrrectData(name);
		handleOnChange(obj);
	};

	const onSelectPayment = values => {
		const selectedMethods = {
			eth: values.includes('eth'),
			usdc: values.includes('usdc'),
			usdt: values.includes('usdt'),
		};
		const obj = {
			...currentValues,
			projectMethodPayments: selectedMethods,
		};
		deleteFromIconrrectData('projectMethodPayments');
		handleOnChange(obj);
	};

	const onAddDocument = documents => {
		const obj = {
			...currentValues,
			...documents,
		};
		handleOnChange(obj);
	};

	return (
		<Flex gap='20px'>
			<Grid templateColumns='repeat(12, 1fr)' gap='25px'>
				<Text
					variant='colored'
					gridColumn='span 12'
					fontSize='20px'
					fontWeight='700'
					letterSpacing={-0.02}
				>
					Financial parameters
				</Text>
				<Box gridColumn='span 6'>
					<FormLabel variant='propertyLabel'>
						Payments methods
					</FormLabel>
					<CheckboxGroup
						onChange={onSelectPayment}
						defaultValue={getDefaultPaymentMethods}
					>
						<Stack
							direction='row'
							h='40px'
							bg='transparencyWhite.100'
							borderRadius='5px'
							justifyContent='space-evenly'
							gap='15px'
							fontSize='10px'
							outline={
								incorrectData?.includes('projectMethodPayments')
									? '1px solid red'
									: 'none'
							}
						>
							{availablePaymentMethods.map(
								(paymentMethod, index) => (
									<Checkbox
										value={paymentMethod.value}
										key={index}
									>
										<Text fontSize='13px' fontWeight='400'>
											{paymentMethod.label}
										</Text>
									</Checkbox>
								),
							)}
						</Stack>
					</CheckboxGroup>
				</Box>

				<FormControlCustomComponent
					gridColumn='span 6'
					formLabel='KYC'
					tooltipLabel='algo'
					options={KYCOptions}
					onChange={onChangeSelector}
					name='isKYCRequired'
					incorrectData={incorrectData}
					selectedOption={currentValues?.isKYCRequired}
					deleteFromIconrrectData={deleteFromIconrrectData}
				/>
				<FormControlCustomComponent
					formLabel='Price per token'
					tooltipLabel='The price assigned to each individual token in the project. Users will purchase tokens at this price, representing their share in the project.'
					symbol='$'
					name='pricePerToken'
					placeholder='Price per token'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.pricePerToken ??
						initialValues.data.pricePerToken
					}
					type='number'
					isDisabled={initialValues.data.pricePerToken}
				/>
				<FormControlCustomComponent
					formLabel='Quantity of Tokens'
					tooltipLabel='This refers to the total number of tokens available for purchase in the project. Each token represents a portion of the investment and its associated returns.'
					name='quantityOfTokens'
					placeholder='Quantity of Tokens'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.quantityOfTokens ??
						initialValues.data.quantityOfTokens
					}
					type='number'
					isDisabled={initialValues.data.quantityOfTokens}
				/>
				<FormControlCustomComponent
					formLabel='Payback Period'
					tooltipLabel='It indicates the number of months users must wait to start receiving returns on their investment.'
					symbol='Months'
					name='paybackPeriod'
					placeholder='Payback Period'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.paybackPeriod ??
						initialValues.data.paybackPeriod
					}
					type='number'
				/>
				<FormControlCustomComponent
					gridColumn={!isPurchased ? 'span 6' : 'span 4'}
					formLabel='Min Ticket Price'
					tooltipLabel='por definir'
					symbol='$'
					name='minTicketPrice'
					placeholder='Min Ticket Price'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.minTicketPrice ??
						initialValues.data.minTicketPrice
					}
					type='number'
				/>
				{isPurchased && (
					<FormControlCustomComponent
						formLabel='Investment Threshold'
						tooltipLabel='por definir'
						symbol='%'
						name='investmentThreshold'
						placeholder='Investment Threshold'
						onChange={onChangeNumberInput}
						incorrectData={incorrectData}
						value={
							currentValues?.investmentThreshold ??
							initialValues.data.investmentThreshold
						}
						type='number'
						isDisabled={initialValues.data.investmentThreshold}
					/>
				)}
				<FormControlCustomComponent
					gridColumn={!isPurchased ? 'span 6' : 'span 4'}
					formLabel='Distribution Frequency'
					tooltipLabel='This refers to how often returns or payments will be distributed to users. It outlines whether distributions will occur monthly, quarterly, annually, or at another specified interval. The frequency distribution sets the schedule for when users can expect to receive their returns.'
					options={distributionFrequencyOptions}
					onChange={onChangeSelector}
					name='distributionFrequency'
					incorrectData={incorrectData}
					selectedOption={
						currentValues?.distributionFrequency ??
						initialValues.data.distributionFrequency
					}
					deleteFromIconrrectData={deleteFromIconrrectData}
				/>
				<Divider gridColumn='span 12' border='1px' />
				<Text
					variant='colored'
					gridColumn='span 12'
					fontSize='20px'
					fontWeight='700'
					letterSpacing={-0.02}
				>
					Wallet
				</Text>
				<FormControlCustomComponent
					gridColumn='span 12'
					formLabel='Wallet address to withdraw funds'
					tooltipLabel='Por definir'
					name='withdrawWalletAddress'
					placeholder='Wallet address to withdraw funds'
					onChange={onChangeTextInput}
					incorrectData={incorrectData}
					value={
						currentValues?.withdrawWalletAddress ??
						initialValues.data.withdrawWalletAddress
					}
				/>
				<Text
					gridColumn='span 12'
					fontSize='12px'
					fontWeight='400'
					mt='-18px'
				>
					<Text as='span' variant='colored' fontSize='12px'>
						Disclaimer:
					</Text>{' '}
					Please ensure that the address below is correct. If you send
					to an incorrect address we will not be able to recover the
					project funds
				</Text>
				<Divider gridColumn='span 12' border='1px' />
				<Text
					variant='colored'
					gridColumn='span 12'
					fontSize='20px'
					fontWeight='700'
					letterSpacing={-0.02}
				>
					Project Cost Summary
				</Text>
				<FormControlCustomComponent
					formLabel='Current property value'
					tooltipLabel='It indicates the current market value of the property.'
					symbol='$'
					name='currentPropertyValue'
					placeholder='Current property value'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.currentPropertyValue ??
						initialValues.data.currentPropertyValue
					}
					type='number'
				/>
				<FormControlCustomComponent
					formLabel='Taxes and operating costs'
					tooltipLabel="This includes all registration fees, property taxes, maintenance costs, and any additional fees that the Project Creator wishes to add. It covers the ongoing expenses necessary for the property's operation and compliance."
					symbol='$'
					name='taxesAndOperatingCosts'
					placeholder='Taxes and operating costs'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.taxesAndOperatingCosts ??
						initialValues.data.taxesAndOperatingCosts
					}
					type='number'
				/>
				<FormControlCustomComponent
					formLabel='Renovation and fitouts costs'
					tooltipLabel="These are the estimated costs associated with any renovations, improvements, or fitouts required for the property. These expenses are crucial for enhancing the property's value and generating higher returns."
					symbol='$'
					name='renovationAndFitoutsCosts'
					placeholder='Renovation and fitouts costs'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.renovationAndFitoutsCosts ??
						initialValues.data.renovationAndFitoutsCosts
					}
					type='number'
				/>
				<Divider gridColumn='span 12' border='1px' />
				<Text
					variant='colored'
					gridColumn='span 12'
					fontSize='20px'
					fontWeight='700'
					letterSpacing={-0.02}
				>
					Rental Income Overview
				</Text>
				<FormControlCustomComponent
					gridColumn='span 6'
					formLabel='Estimated Annual Net Income from Rental'
					tooltipLabel='The projected net income from rental activities on an annual basis, after deducting expenses such as maintenance, property management, and taxes'
					symbol='$'
					name='estimatedAnnualIncomeFromRental'
					placeholder='Estimated Annual Net Income from Rental'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.estimatedAnnualIncomeFromRental ??
						initialValues.data.estimatedAnnualIncomeFromRental
					}
					type='number'
				/>
				<FormControlCustomComponent
					gridColumn='span 6'
					formLabel='Rental Timeline'
					tooltipLabel='The estimated duration over which the property or asset will be rented out. It includes the period from the start of rental activities to the anticipated end date.'
					symbol='Months'
					name='rentalTimeline'
					placeholder='Rental Timeline'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.rentalTimeline ??
						initialValues.data.rentalTimeline
					}
					type='number'
				/>
				<Divider gridColumn='span 12' border='1px' />
				<Text
					variant='colored'
					gridColumn='span 12'
					fontSize='20px'
					fontWeight='700'
					letterSpacing={-0.02}
				>
					Capital Growth and Estimated Returns
				</Text>
				<FormControlCustomComponent
					gridColumn='span 6'
					formLabel='Estimated Resale Price'
					tooltipLabel='It indicates the projected resale price of the property or asset. This estimate reflects the potential value at which the property could be sold, contributing to your overall return.'
					symbol='$'
					name='estimatedResalePrice'
					placeholder='Estimated Resale Price'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.estimatedResalePrice ??
						initialValues.data.estimatedResalePrice
					}
					type='number'
				/>
				<FormControlCustomComponent
					gridColumn='span 6'
					formLabel='Appreciation'
					tooltipLabel='The expected increase in the value of the property annually. Can be estimated by averaging the market growth over the past 3 years in the project location.'
					name='appreciation'
					placeholder='Appreciation'
					symbol='%'
					onChange={onChangeNumberInput}
					incorrectData={incorrectData}
					value={
						currentValues?.appreciation ??
						initialValues.data.appreciation
					}
					type='number'
				/>
				<Divider gridColumn='span 12' border='1px' />
				<Box gridColumn='span 12'>
					<AddDocumentsComponent
						handleAddDocuments={documents =>
							onAddDocument(documents)
						}
						initialValues={initialValues}
						currentValues={currentValues}
						setPropertyInfo={setPropertyInfo}
						incorrectData={incorrectData}
						deleteFromIconrrectData={deleteFromIconrrectData}
						status={initialValues?.data?.status}
					/>
				</Box>
			</Grid>
			<InfoBoxesComponent
				propertyInfo={{
					...initialValues.data,
					...currentValues,
					...informationTabData,
				}}
			/>
		</Flex>
	);
};

export default FinancialDetailsComponent;
