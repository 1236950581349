// import { useSelector } from 'react-redux';
import { Flex, Text, Button, useColorMode } from '@chakra-ui/react';
import { IconMarketplace } from '../../../../../assets/Icons';
import FeeComponent from '../../../../../components/FeeComponent';

import { currencyFormatter } from '../../../../../utils/utils';
import { useTranslation } from 'react-i18next';

const CreditCardPurchase = ({
	realCost,
	numTokens,
	closePurchaseModal,
	handlePurchaseConfirmation,
}) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();

	const onPurchaseConfirmation = () => {
		closePurchaseModal();
		handlePurchaseConfirmation();
	};

	return (
		<Flex
			flexDirection='column'
			align='center'
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyBlack.50'
			}
			border='0.5px solid'
			borderColor='transparencyBlack.200'
			borderRadius='12px'
			gap='15px'
			p='20px'
		>
			<Text
				variant='colored'
				fontSize='20px'
				fontWeight='bold'
				lineHeight='150%'
			>
				{t('projectDetails.offerDetails.addCredit.purchaseSummary')}
			</Text>
			<Flex flexDirection='column' gap='15px'>
				<Text
					variant='colored'
					fontSize='32px'
					fontWeight='bold'
					lineHeight='15px'
				>
					{currencyFormatter(realCost)}
				</Text>
				<Text fontSize='10px' fontWeight='300'>
					{numTokens} Project token/s
				</Text>
			</Flex>
			<FeeComponent />
			<Button variant='main' gap='8px' onClick={onPurchaseConfirmation}>
				<IconMarketplace boxSize='16px' />
				{t('projectDetails.offerDetails.addCredit.confirmYourPurchase')}
			</Button>
		</Flex>
	);
};

export default CreditCardPurchase;
