import UpcomingGeneralComponent from '../../components/UpcomingGeneralComponent.js';

const Tutorial = () => {
	return <UpcomingGeneralComponent 
	title='tutorialPage.title' 
	subtitle='tutorialPage.subtitle'
	paragraph='tutorialPage.paragraph'
	button='tutorialPage.button' />
};

export default Tutorial;
