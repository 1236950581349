import { BrowserProvider, Contract } from 'ethers';
import { magic } from '../../lib/magic';
import { messagesAbi } from '../../interfaces/auxs/message.abi';
import axios from 'axios';

const provider = new BrowserProvider(magic.rpcProvider);
const messagesContractAddress = process.env.REACT_APP_CONTRACT_ADDRESS_MESSAGES;

let signer;
let messagesContract;

/**
 * @description Initialize the message contract
 */

const initializeMessageContract = async () => {
	if (!signer) {
		signer = await provider.getSigner();
	}

	if (!messagesContract) {
		messagesContract = new Contract(
			messagesContractAddress,
			messagesAbi,
			signer,
		);
	}
};

/**
 * @description Generate a create project message
 * @param {Object} projectData - The project data
 */

const generateCreateProjectMsg = async projectData => {
	// Check if the message contract is initialized
	if (!messagesContract) {
		await initializeMessageContract();
	}

	const {
		projectId,
		projectType,
		totalSupply,
		projectPrice,
		withdrawAddress,
		paymentMethods,
		fundingThreshold,
		nonce,
	} = projectData;

	const message = await messagesContract.generateCreateProjectMsg(
		projectId,
		projectType,
		totalSupply,
		projectPrice,
		withdrawAddress,
		paymentMethods,
		fundingThreshold,
		nonce,
	);

	return message;
};

const generateUpdateProjectMessage = async projectData => {
	const {
		projectId,
		projectType,
		totalSupply,
		projectPrice,
		withdrawAddress,
		paymentMethods,
		fundingThreshold,
		nonce,
	} = projectData;

	const message = await messagesContract.generateUpdateProjectMsg(
		projectId,
		projectType,
		totalSupply,
		projectPrice,
		withdrawAddress,
		paymentMethods,
		fundingThreshold,
		nonce,
	);

	return message;
};

/**
 * @description Get a signature for a message
 * @param {Object} projectData - The project data
 */

const getCreateProjectSignature = async projectData => {
	try {
		const message = await generateCreateProjectMsg(projectData);
		const signature = await createSignature(message);
		return signature;
	} catch (error) {
		console.error('Cannot create signature', error);
	}
};

const getUpdateProjectSignature = async projectData => {
	try {
		const message = await generateUpdateProjectMessage(projectData);
		const signature = await createSignature(message);
		return signature;
	} catch (error) {
		console.error('Cannot create signature', error);
	}
};

const createSignature = async message => {
	const baseURL = process.env.REACT_APP_API_ROUTE;
	const url = `${baseURL}/request/signature`;
	const result = await axios.post(url, { message });
	const signature = result.data.data;
	return signature;
};

export { getCreateProjectSignature, getUpdateProjectSignature };
