/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { formatOrDash, formatNumber } from '../../../../../utils/utils';
import {
	getCapitalGainOnSale,
	getEstimatedTotalNetProfit,
	getTotalProjectFundraise,
} from '../../../../../utils/projectDataUtils';

const FinancialDetailsBoxInfo = ({ propertyInfo }) => {
	return (
		<Stack
			bg='transparencyWhite.100'
			borderRadius='12px'
			p='15px 20px'
			gap='15px'
		>
			<Text
				variant='colored'
				fontSize='20px'
				fontWeight='700'
				lineHeight='20px'
				letterSpacing={-0.02}
			>
				Financial Details
			</Text>
			<Stack py='9px' gap='9px'>
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Price per token
					</Text>
					<Text
						variant='gray'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(propertyInfo?.pricePerToken)}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Quantity of tokens
					</Text>
					<Text
						variant='gray'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatNumber(propertyInfo?.quantityOfTokens) !== 'NaN'
							? formatNumber(propertyInfo?.quantityOfTokens)
							: '-'}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Total Project Fundraise
					</Text>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(getTotalProjectFundraise(propertyInfo))}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Net Capital Gain on Sale
					</Text>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(getCapitalGainOnSale(propertyInfo))}
					</Text>
				</Flex>
				<Divider />
				<Flex justifyContent='space-between'>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						Estimated Total Net Profit
					</Text>
					<Text
						fontWeight='700'
						fontSize='12px'
						lineHeight='20px'
						letterSpacing={-0.02}
					>
						{formatOrDash(getEstimatedTotalNetProfit(propertyInfo))}
					</Text>
				</Flex>
			</Stack>
		</Stack>
	);
};

export default FinancialDetailsBoxInfo;
