import {
	Button,
	Divider,
	Flex,
	GridItem,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import {
	currencyFormatter,
	getFundedThreshold,
	multiplicationCalc,
} from '../../../utils/utils';
import {
	IconView,
	IconEdit,
	IconDots,
	IconPublish,
	IconTrash,
	IconTransactions,
	IconWithdraw,
	IconDeposit,
	IconClose,
} from '../../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import { PrivateRoutes, PublicRoutes } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';
import {
	publishProject,
	updatePropertyAndNonce,
} from '../../../services/propertyService';
import useProject from '../../../hooks/useProject';
import IconReload from '../../../assets/Icons/components/IconReload';
import { showToast } from '../../../utils/Toasts';
import { useState } from 'react';
import SuccessfulComponent from '../../../components/SuccessfulComponent';
import ModalComponent from '../../../components/ModalComponent';
import { projectStatus } from '../../../data/optionsData';
import useSeller from '../../../hooks/useSeller';
import useClaim from '../../../hooks/useClaim';
import ModalAddReward from './ModalAddReward';
import BoxComponent from '../../../components/BoxComponent';

const PublishedInfoComponent = ({
	isMobile,
	colorBorder,
	data,
	last,
	handleDeleteProject,
	loadingAction,
	onPublishProject,
	onWithdrawProject,
	onRefundProject,
}) => {
	const {
		metadataId,
		pricePerToken,
		quantityOfTokens,
		mintedSupply,
		investmentThreshold,
		title,
		projectId,
	} = data;

	const { createProjectAndUpdateStatus, existsProject } = useProject();
	const { withdrawProjectFunds, enableRefund } = useSeller();
	const { addFunds } = useClaim();
	const {
		onOpen: openAddRewardsModal,
		isOpen: isOpenAddRewardsModal,
		onClose: closeAddRewardsModal,
	} = useDisclosure();

	const {
		isOpen: isSucessfulOpen,
		onOpen: openSuccessfulModal,
		onClose: closeSuccesfulModal,
	} = useDisclosure();

	const navigate = useNavigate();
	const toast = useToast();
	const [txHash, setTxHash] = useState(null);
	const { t } = useTranslation();
	const [isPublishing, setIsPublishing] = useState(false);

	const handleEditProject = () => {
		navigate(`/${PrivateRoutes.AddProperty}`, {
			state: { projectId },
		});
	};

	const handleCloseAddRewardModal = () => {
		setTxHash(null);
		closeAddRewardsModal();
	};

	const handleViewProject = () => {
		navigate(`/${PublicRoutes.Project}/${projectId}`);
	};

	const handlePublishProject = async () => {
		try {
			if (data.status !== projectStatus.Published) {
				setIsPublishing(true);

				const publishedProjectData = await publishProject(projectId);

				if (!publishedProjectData.success) {
					setIsPublishing(false);
					showToast(
						toast,
						'There was an error',
						publishedProjectData.message,
					);
					return;
				}

				const newProject = await createProjectAndUpdateStatus(
					publishedProjectData.data.projectData,
					publishedProjectData.data.projectId,
				);

				if (newProject.newProject.status === projectStatus.Published) {
					setTxHash(newProject.receipt?.hash);
					onPublishProject(newProject.newProject);
					openSuccessfulModal();
					showToast(
						toast,
						'Success',
						'Property successfully published',
					);
				} else {
					showToast(
						toast,
						'There was an error',
						newProject.receipt.msg,
					);
				}
				setIsPublishing(false);
			}
		} catch (error) {
			setIsPublishing(false);
			showToast(toast, 'There was an error', error?.reason);
		}
	};

	const handleReloadProject = async () => {
		setIsPublishing(true);
		const projectAlreadyExists = await existsProject(metadataId);

		if (projectAlreadyExists) {
			const projectData = await publishProject(projectId);
			await updatePropertyAndNonce(
				{
					status: projectStatus.Published,
					nonce: projectData.data?.projectData.nonce,
				},
				projectId,
			);
		} else {
			await handlePublishProject();
		}
		// setStatus(projectStatus.Published);
		setIsPublishing(false);
	};

	const handleCancelProject = async () => {
		setIsPublishing(true);
		const cancelProjectTx = await enableRefund(metadataId);
		setTxHash(cancelProjectTx.receipt?.hash);
		openSuccessfulModal();
		onRefundProject(metadataId);
		setIsPublishing(false);
		return cancelProjectTx;
	};

	const handleWithdrawProject = async () => {
		setIsPublishing(true);
		const withdrawTx = await withdrawProjectFunds(metadataId);
		if (withdrawTx.success) {
			setTxHash(withdrawTx.receipt?.hash);
			openSuccessfulModal();
			setIsPublishing(false);
			onWithdrawProject(metadataId);
		}
	};

	const handleAddRewards = async ({
		tokenAmount,
		isFinalPayment = false,
	}) => {
		const addFundsTx = await addFunds({
			metadataId,
			projectId,
			tokenAmount,
			isFinalPayment,
		});

		if (addFundsTx.success) {
			setTxHash(addFundsTx.receipt?.hash);
			closeAddRewardsModal();
			openSuccessfulModal();
		}
	};

	const buttonConfig = {
		AddRewards: {
			variant: 'main',
			text: t('adminPage.pubProperties.AddReward'),
			icon: <IconDeposit />,
			isDisabled: false,
			onClick: openAddRewardsModal,
		},
		Draft: {
			variant: 'main',
			text: t('adminPage.pubProperties.publish'),
			icon: <IconPublish />,
			isDisabled: false,
			onClick: handlePublishProject,
		},
		Published: {
			variant: 'main',
			text: t('adminPage.pubProperties.withdraw'),
			icon: <IconWithdraw />,
			isDisabled:
				getFundedThreshold({ mintedSupply, quantityOfTokens }) <
				investmentThreshold,
			onClick: handleWithdrawProject,
		},
		Reload: {
			variant: 'reload',
			text: t('adminPage.pubProperties.reload'),
			icon: <IconTransactions />,
			isDisabled: false,
			onClick: handleReloadProject,
		},
		Pending: {
			variant: 'main',
			text: t('adminPage.pubProperties.Pending'),
			icon: <IconDots />,
			isLoading: true,
			isDisabled: true,
			onClick: handleReloadProject,
		},
		Refund: {
			variant: 'secondary',
			text: t('adminPage.pubProperties.Refund'),
			icon: <IconWithdraw />,
			isDisabled: true,
			onClick: null,
		},
		Ended: {
			variant: 'secondary',
			text: t('adminPage.pubProperties.Ended'),
			icon: <IconWithdraw />,
			isDisabled: true,
			onClick: null,
		},
	};

	const {
		variant: buttonVariant,
		text: buttonText,
		icon: buttonIcon,
		isDisabled: buttonIsDisabled,
		onClick: buttonOnClick,
	} = (data.isWithdrawn === true &&
		data.status !== projectStatus.Ended &&
		buttonConfig.AddRewards) ||
	buttonConfig[data.status];
	return (
		<>
			<GridItem py={isMobile ? '15px' : '0'}>
				<Text
					fontSize={isMobile ? '12px' : '14px'}
					fontWeight={isMobile ? '600' : '500'}
				>
					{title}
				</Text>
			</GridItem>
			<Text
				px='5px'
				variant='gray'
				fontSize={isMobile ? '10px' : '12px'}
				fontWeight='400'
			>
				{currencyFormatter(
					multiplicationCalc(quantityOfTokens, pricePerToken),
				)}
			</Text>
			<Text
				px='5px'
				variant='gray'
				fontSize={isMobile ? '10px' : '12px'}
				fontWeight='400'
			>
				{t(`adminPage.pubProperties.${data.status}`)}
			</Text>

			{!isMobile && (
				<Flex w='100%' align='center' p='6px' gap='10px'>
					{data.status !== projectStatus.Pending && (
						<Button
							variant='secondary'
							fontWeight='400'
							fontSize='13px'
							w='68px'
							p='10px'
							gap='5px'
							onClick={handleViewProject}
							isDisabled={loadingAction}
						>
							<IconView />
							{t('adminPage.pubProperties.view')}
						</Button>
					)}

					<Button
						variant={buttonVariant}
						w={
							data.status !== projectStatus.Pending
								? '96px'
								: '174px'
						}
						fontWeight='400'
						p='10px'
						isDisabled={buttonIsDisabled}
						onClick={buttonOnClick}
						isLoading={
							isPublishing ||
							data.status === projectStatus.Pending
						}
					>
						<Flex w='100%' alignItems='center'>
							{buttonIcon}
							<Text ms='5px' justifySelf='start' fontSize='13px'>
								{buttonText}
							</Text>
						</Flex>
					</Button>

					{data.status === projectStatus.Pending && (
						<Button
							variant='main'
							fontWeight='400'
							fontSize='13px'
							maxW='40px'
							p='10px'
							gap='5px'
							onClick={handleReloadProject}
							isLoading={isPublishing}
						>
							Try
						</Button>
					)}
					{data.status !== projectStatus.Pending &&
						data.status !== projectStatus.Refund && (
							<Menu>
								<MenuButton
									h='40px'
									w='40px'
									borderRadius='5px'
								>
									<IconDots />
								</MenuButton>
								<MenuList>
									<MenuItem
										gap='5px'
										isDisabled={loadingAction}
										onClick={handleEditProject}
									>
										<IconEdit />
										{t('adminPage.pubProperties.edit')}
									</MenuItem>
									{data.status === projectStatus.Draft && (
										<MenuItem
											gap='5px'
											isDisabled={loadingAction}
											onClick={handleDeleteProject}
										>
											<IconTrash />
											{t(
												'adminPage.pubProperties.delete',
											)}
										</MenuItem>
									)}
									{!data.isWithdrawn &&
										data.status ===
											projectStatus.Published && (
											<MenuItem
												gap='5px'
												isDisabled={loadingAction}
												onClick={handleCancelProject}
											>
												<IconClose />
												{t(
													'adminPage.pubProperties.cancel',
												)}
											</MenuItem>
										)}
								</MenuList>
							</Menu>
						)}
				</Flex>
			)}
			{isMobile && (
				<GridItem colSpan={isMobile ? 0 : 2}>
					<Menu>
						<MenuButton
							as={IconDots}
							boxSize='14px'
							cursor='pointer'
							transition='transform 0.2s'
							_hover={{ transform: 'scale(1.2)' }}
							_active={{ transform: 'scale(1)' }}
						/>
						<MenuList>
							<MenuItem
								gap='5px'
								onClick={handleEditProject}
								isDisabled={
									loadingAction ||
									data.status === projectStatus.Refund
								}
							>
								<IconView />
								{t('adminPage.pubProperties.edit')}
							</MenuItem>
							<MenuItem
								gap='5px'
								isDisabled={loadingAction}
								onClick={handleViewProject}
							>
								<IconView />
								{t('adminPage.pubProperties.view')}
							</MenuItem>
							{data.status !== projectStatus.Reload ? (
								<MenuItem
									gap='5px'
									isDisabled={
										loadingAction ||
										data.status !== projectStatus.Draft
									}
									onClick={handlePublishProject}
								>
									<IconPublish />
									{t('adminPage.pubProperties.publish')}
								</MenuItem>
							) : (
								<MenuItem
									gap='5px'
									onClick={handlePublishProject}
								>
									<IconReload />
									{t('adminPage.pubProperties.reload')}
								</MenuItem>
							)}

							<MenuItem
								gap='5px'
								isDisabled={
									loadingAction ||
									data.status !== projectStatus.Draft
								}
								onClick={handleDeleteProject}
							>
								<IconTrash />
								Delete
							</MenuItem>
						</MenuList>
					</Menu>
				</GridItem>
			)}
			{!last && (
				<Divider
					gridColumn={isMobile ? 'span 5' : 'span 6'}
					borderColor={colorBorder}
				/>
			)}

			<ModalComponent
				isOpen={isSucessfulOpen}
				onClose={closeSuccesfulModal}
				onOpen={openSuccessfulModal}
				modalCross={false}
			>
				<SuccessfulComponent
					w='312px'
					p='20px'
					modal={false}
					buttonTitle={'Close'}
					handleClick={closeSuccesfulModal}
					text={t(
						'projectDetails.offerDetails.succesful.transactionSuccess',
					)}
					txHash={txHash}
				/>
			</ModalComponent>

			<ModalAddReward
				isOpen={isOpenAddRewardsModal}
				onClose={handleCloseAddRewardModal}
				onAddReward={handleAddRewards}
				data={data}
				txHash={txHash}
			>
				<BoxComponent></BoxComponent>
			</ModalAddReward>
		</>
	);
};

export default PublishedInfoComponent;
