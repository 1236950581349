export const elevexTokenAbi = [
	{
		inputs: [
			{ internalType: 'string', name: '_name', type: 'string' },
			{ internalType: 'string', name: '_symbol', type: 'string' },
			{ internalType: 'string', name: '_baseUri', type: 'string' },
			{ internalType: 'string', name: '_collectionUri', type: 'string' },
			{
				internalType: 'address payable',
				name: '_royaltyAddress',
				type: 'address',
			},
			{ internalType: 'uint96', name: '_royaltyPoints', type: 'uint96' },
			{
				internalType: 'address',
				name: '_msgGeneratorAddress',
				type: 'address',
			},
			{
				internalType: 'address',
				name: '_oracleAddress',
				type: 'address',
			},
		],
		stateMutability: 'nonpayable',
		type: 'constructor',
	},
	{ inputs: [], name: 'AccessControlBadConfirmation', type: 'error' },
	{
		inputs: [
			{ internalType: 'address', name: 'account', type: 'address' },
			{ internalType: 'bytes32', name: 'neededRole', type: 'bytes32' },
		],
		name: 'AccessControlUnauthorizedAccount',
		type: 'error',
	},
	{ inputs: [], name: 'ECDSAInvalidSignature', type: 'error' },
	{
		inputs: [{ internalType: 'uint256', name: 'length', type: 'uint256' }],
		name: 'ECDSAInvalidSignatureLength',
		type: 'error',
	},
	{
		inputs: [{ internalType: 'bytes32', name: 's', type: 'bytes32' }],
		name: 'ECDSAInvalidSignatureS',
		type: 'error',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'sender', type: 'address' },
			{ internalType: 'uint256', name: 'balance', type: 'uint256' },
			{ internalType: 'uint256', name: 'needed', type: 'uint256' },
			{ internalType: 'uint256', name: 'tokenId', type: 'uint256' },
		],
		name: 'ERC1155InsufficientBalance',
		type: 'error',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'approver', type: 'address' },
		],
		name: 'ERC1155InvalidApprover',
		type: 'error',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'idsLength', type: 'uint256' },
			{ internalType: 'uint256', name: 'valuesLength', type: 'uint256' },
		],
		name: 'ERC1155InvalidArrayLength',
		type: 'error',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'operator', type: 'address' },
		],
		name: 'ERC1155InvalidOperator',
		type: 'error',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'receiver', type: 'address' },
		],
		name: 'ERC1155InvalidReceiver',
		type: 'error',
	},
	{
		inputs: [{ internalType: 'address', name: 'sender', type: 'address' }],
		name: 'ERC1155InvalidSender',
		type: 'error',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'operator', type: 'address' },
			{ internalType: 'address', name: 'owner', type: 'address' },
		],
		name: 'ERC1155MissingApprovalForAll',
		type: 'error',
	},
	{ inputs: [], name: 'EnforcedPause', type: 'error' },
	{ inputs: [], name: 'ExpectedPause', type: 'error' },
	{
		inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
		name: 'OwnableInvalidOwner',
		type: 'error',
	},
	{
		inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
		name: 'OwnableUnauthorizedAccount',
		type: 'error',
	},
	{ inputs: [], name: 'ReentrancyGuardReentrantCall', type: 'error' },
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'account',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'operator',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'bool',
				name: 'approved',
				type: 'bool',
			},
		],
		name: 'ApprovalForAll',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'from',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'tokenId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'Burn',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: 'projectId',
				type: 'uint256',
			},
		],
		name: 'MetadataUpdate',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'to',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'tokenId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'Mint',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'previousOwner',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'newOwner',
				type: 'address',
			},
		],
		name: 'OwnershipTransferred',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'account',
				type: 'address',
			},
		],
		name: 'Paused',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'projectId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'ProjectAddRaise',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'projectId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'totalSupply',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'projectPrice',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'withdrawAddress',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'rewardContractAddress',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'string[]',
				name: 'paymentMethods',
				type: 'string[]',
			},
			{
				indexed: false,
				internalType: 'uint8',
				name: 'fundingThreshold',
				type: 'uint8',
			},
		],
		name: 'ProjectCreated',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'projectId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'string[]',
				name: 'paymentMethods',
				type: 'string[]',
			},
		],
		name: 'ProjectPaymentMethodsUpdated',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'projectId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'price',
				type: 'uint256',
			},
		],
		name: 'ProjectPriceUpdated',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'projectId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'rewardAddress',
				type: 'address',
			},
		],
		name: 'ProjectRewardAddressUpdated',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'projectId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'totalSupply',
				type: 'uint256',
			},
		],
		name: 'ProjectSupplyUpdated',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'projectId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint8',
				name: 'threshold',
				type: 'uint8',
			},
		],
		name: 'ProjectThresholdUpdated',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'projectId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256',
			},
		],
		name: 'ProjectTotalRaised',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'projectId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'withdrawAddress',
				type: 'address',
			},
		],
		name: 'ProjectWithdrawAddressUpdated',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32',
			},
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'previousAdminRole',
				type: 'bytes32',
			},
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'newAdminRole',
				type: 'bytes32',
			},
		],
		name: 'RoleAdminChanged',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'account',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'sender',
				type: 'address',
			},
		],
		name: 'RoleGranted',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'account',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'sender',
				type: 'address',
			},
		],
		name: 'RoleRevoked',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'newRoyaltiesAddress',
				type: 'address',
			},
		],
		name: 'SetRoyaltiesAddress',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint96',
				name: 'newRoyaltiesBasicPoints',
				type: 'uint96',
			},
		],
		name: 'SetRoyaltiesBasicPoints',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: 'projectId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'bool',
				name: 'isBurnEnabled',
				type: 'bool',
			},
		],
		name: 'ToggleBurn',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: 'projectId',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'bool',
				name: 'isRefundEnabled',
				type: 'bool',
			},
		],
		name: 'ToggleRefund',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'operator',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'from',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'to',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256[]',
				name: 'ids',
				type: 'uint256[]',
			},
			{
				indexed: false,
				internalType: 'uint256[]',
				name: 'values',
				type: 'uint256[]',
			},
		],
		name: 'TransferBatch',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'operator',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'from',
				type: 'address',
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'to',
				type: 'address',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'id',
				type: 'uint256',
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'value',
				type: 'uint256',
			},
		],
		name: 'TransferSingle',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'string',
				name: 'value',
				type: 'string',
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'id',
				type: 'uint256',
			},
		],
		name: 'URI',
		type: 'event',
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'account',
				type: 'address',
			},
		],
		name: 'Unpaused',
		type: 'event',
	},
	{
		inputs: [],
		name: 'DEFAULT_ADMIN_ROLE',
		outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'MSG_GENERATOR',
		outputs: [
			{
				internalType: 'contract IELVX_Messages',
				name: '',
				type: 'address',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'ORACLES',
		outputs: [
			{
				internalType: 'contract IELVX_Oracles',
				name: '',
				type: 'address',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'REWARD_CONTRACT_ADDRESS',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'REWARD_ROLE',
		outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'ROYALTY_POINTS',
		outputs: [{ internalType: 'uint96', name: '', type: 'uint96' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'ROYALTY_RECIPIENT',
		outputs: [
			{ internalType: 'address payable', name: '', type: 'address' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'SELLER_ROLE',
		outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
			{ internalType: 'uint256', name: 'amount', type: 'uint256' },
		],
		name: 'addRaiseToProject',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'account', type: 'address' },
			{ internalType: 'uint256', name: 'id', type: 'uint256' },
		],
		name: 'balanceOf',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address[]', name: 'accounts', type: 'address[]' },
			{ internalType: 'uint256[]', name: 'ids', type: 'uint256[]' },
		],
		name: 'balanceOfBatch',
		outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'from', type: 'address' },
			{ internalType: 'uint256', name: 'id', type: 'uint256' },
			{ internalType: 'uint256', name: 'amount', type: 'uint256' },
		],
		name: 'burn',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'account', type: 'address' },
			{ internalType: 'uint256[]', name: 'ids', type: 'uint256[]' },
			{ internalType: 'uint256[]', name: 'values', type: 'uint256[]' },
		],
		name: 'burnBatch',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'contractURI',
		outputs: [{ internalType: 'string', name: '', type: 'string' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
			{
				internalType: 'enum ELVX_Projects.ProjectType',
				name: 'projectType',
				type: 'uint8',
			},
			{ internalType: 'uint256', name: 'totalSupply', type: 'uint256' },
			{ internalType: 'uint256', name: 'projectPrice', type: 'uint256' },
			{
				internalType: 'address',
				name: 'withdrawAddress',
				type: 'address',
			},
			{
				internalType: 'string[]',
				name: 'paymentMethods',
				type: 'string[]',
			},
			{ internalType: 'uint8', name: 'fundingThreshold', type: 'uint8' },
			{ internalType: 'string', name: 'legalContract', type: 'string' },
			{ internalType: 'uint256', name: 'nonce', type: 'uint256' },
			{ internalType: 'bytes', name: '_signature', type: 'bytes' },
		],
		name: 'createProject',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'disableProjectRefund',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'enableProjectRefund',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }],
		name: 'exists',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'existsProject',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'getFullProject',
		outputs: [
			{
				components: [
					{
						internalType: 'enum ELVX_Projects.ProjectType',
						name: 'projectType',
						type: 'uint8',
					},
					{
						internalType: 'string[]',
						name: 'paymentMethods',
						type: 'string[]',
					},
					{
						components: [
							{
								internalType: 'uint256',
								name: 'maxSupply',
								type: 'uint256',
							},
							{
								internalType: 'uint256',
								name: 'minted',
								type: 'uint256',
							},
						],
						internalType: 'struct ELVX_Projects.Supply',
						name: 'supply',
						type: 'tuple',
					},
					{
						components: [
							{
								internalType: 'address',
								name: 'withdrawAddress',
								type: 'address',
							},
							{
								internalType: 'address',
								name: 'rewardAddress',
								type: 'address',
							},
							{
								internalType: 'address',
								name: 'creator',
								type: 'address',
							},
						],
						internalType: 'struct ELVX_Projects.Addresses',
						name: 'addresses',
						type: 'tuple',
					},
					{ internalType: 'uint256', name: 'price', type: 'uint256' },
					{ internalType: 'bool', name: 'burnActive', type: 'bool' },
					{
						internalType: 'bool',
						name: 'refundActive',
						type: 'bool',
					},
					{
						internalType: 'uint8',
						name: 'fundingThreshold',
						type: 'uint8',
					},
					{
						internalType: 'uint256',
						name: 'totalRaised',
						type: 'uint256',
					},
					{
						internalType: 'string',
						name: 'legalContract',
						type: 'string',
					},
				],
				internalType: 'struct ELVX_Projects.Project',
				name: '',
				type: 'tuple',
			},
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'getLegalContract',
		outputs: [{ internalType: 'string', name: '', type: 'string' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'getProjectCreator',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'getProjectFundingThreshold',
		outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'getProjectMaxSupply',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'getProjectMintedSupply',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'getProjectPrice',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'getProjectRewardAddress',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'getProjectTotalRaised',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'getProjectTotalToRaise',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'getProjectType',
		outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
		name: 'getRoleAdmin',
		outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'bytes32', name: 'role', type: 'bytes32' },
			{ internalType: 'uint256', name: 'index', type: 'uint256' },
		],
		name: 'getRoleMember',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
		name: 'getRoleMemberCount',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'address', name: '_user', type: 'address' }],
		name: 'getUserProjects',
		outputs: [
			{ internalType: 'uint256[]', name: '', type: 'uint256[]' },
			{ internalType: 'uint256[]', name: '', type: 'uint256[]' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'getWithdrawAddress',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'bytes32', name: 'role', type: 'bytes32' },
			{ internalType: 'address', name: 'account', type: 'address' },
		],
		name: 'grantRole',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'bytes32', name: 'role', type: 'bytes32' },
			{ internalType: 'address', name: 'account', type: 'address' },
		],
		name: 'hasRole',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'isActiveProject',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'account', type: 'address' },
			{ internalType: 'address', name: 'operator', type: 'address' },
		],
		name: 'isApprovedForAll',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
			{ internalType: 'string', name: 'token', type: 'string' },
		],
		name: 'isPaymentEnabled',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'isProjectBurnActive',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'isRefundActive',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'to', type: 'address' },
			{ internalType: 'uint256', name: 'id', type: 'uint256' },
			{ internalType: 'uint256', name: 'amount', type: 'uint256' },
		],
		name: 'mint',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'name',
		outputs: [{ internalType: 'string', name: '', type: 'string' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'owner',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'pause',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'paused',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		name: 'projects',
		outputs: [
			{
				internalType: 'enum ELVX_Projects.ProjectType',
				name: 'projectType',
				type: 'uint8',
			},
			{
				components: [
					{
						internalType: 'uint256',
						name: 'maxSupply',
						type: 'uint256',
					},
					{
						internalType: 'uint256',
						name: 'minted',
						type: 'uint256',
					},
				],
				internalType: 'struct ELVX_Projects.Supply',
				name: 'supply',
				type: 'tuple',
			},
			{
				components: [
					{
						internalType: 'address',
						name: 'withdrawAddress',
						type: 'address',
					},
					{
						internalType: 'address',
						name: 'rewardAddress',
						type: 'address',
					},
					{
						internalType: 'address',
						name: 'creator',
						type: 'address',
					},
				],
				internalType: 'struct ELVX_Projects.Addresses',
				name: 'addresses',
				type: 'tuple',
			},
			{ internalType: 'uint256', name: 'price', type: 'uint256' },
			{ internalType: 'bool', name: 'burnActive', type: 'bool' },
			{ internalType: 'bool', name: 'refundActive', type: 'bool' },
			{ internalType: 'uint8', name: 'fundingThreshold', type: 'uint8' },
			{ internalType: 'uint256', name: 'totalRaised', type: 'uint256' },
			{ internalType: 'string', name: 'legalContract', type: 'string' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
		],
		name: 'reduceSupply',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'renounceOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'bytes32', name: 'role', type: 'bytes32' },
			{
				internalType: 'address',
				name: 'callerConfirmation',
				type: 'address',
			},
		],
		name: 'renounceRole',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'bytes32', name: 'role', type: 'bytes32' },
			{ internalType: 'address', name: 'account', type: 'address' },
		],
		name: 'revokeRole',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: '_collectionId', type: 'uint256' },
			{ internalType: 'uint256', name: '_salePrice', type: 'uint256' },
		],
		name: 'royaltyInfo',
		outputs: [
			{ internalType: 'address', name: 'receiver', type: 'address' },
			{ internalType: 'uint256', name: 'royaltyAmount', type: 'uint256' },
		],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'from', type: 'address' },
			{ internalType: 'address', name: 'to', type: 'address' },
			{ internalType: 'uint256[]', name: 'ids', type: 'uint256[]' },
			{ internalType: 'uint256[]', name: 'values', type: 'uint256[]' },
			{ internalType: 'bytes', name: 'data', type: 'bytes' },
		],
		name: 'safeBatchTransferFrom',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'from', type: 'address' },
			{ internalType: 'address', name: 'to', type: 'address' },
			{ internalType: 'uint256', name: 'id', type: 'uint256' },
			{ internalType: 'uint256', name: 'value', type: 'uint256' },
			{ internalType: 'bytes', name: 'data', type: 'bytes' },
		],
		name: 'safeTransferFrom',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'operator', type: 'address' },
			{ internalType: 'bool', name: 'approved', type: 'bool' },
		],
		name: 'setApprovalForAll',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'string', name: '_newExtension', type: 'string' },
		],
		name: 'setBaseExtension',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'string', name: '_newUri', type: 'string' }],
		name: 'setBaseURI',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'string', name: '_newUri', type: 'string' }],
		name: 'setContractURI',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: '_legalContractBase',
				type: 'string',
			},
		],
		name: 'setLegalContractBase',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'msgGeneratorAddress',
				type: 'address',
			},
		],
		name: 'setMsgGenerator',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'string', name: '_name', type: 'string' }],
		name: 'setName',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
			{ internalType: 'address', name: 'newCreator', type: 'address' },
		],
		name: 'setProjectCreator',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
			{
				internalType: 'address',
				name: 'rewardContractAddress',
				type: 'address',
			},
		],
		name: 'setProjectRewardContract',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newRewardContract',
				type: 'address',
			},
		],
		name: 'setRewardContract',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{
				internalType: 'address payable',
				name: 'rAddress',
				type: 'address',
			},
		],
		name: 'setRoyaltiesAddress',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint96', name: '_royaltyPoints', type: 'uint96' },
		],
		name: 'setRoyaltiesBasicPoints',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '_signAddress', type: 'address' },
		],
		name: 'setSigner',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'string', name: '_symbol', type: 'string' }],
		name: 'setSymbol',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
			{ internalType: 'uint256', name: 'amount', type: 'uint256' },
		],
		name: 'setTotalRaise',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'signAddress',
		outputs: [{ internalType: 'address', name: '', type: 'address' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' },
		],
		name: 'supportsInterface',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [],
		name: 'symbol',
		outputs: [{ internalType: 'string', name: '', type: 'string' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
			{ internalType: 'bool', name: 'isEnabled', type: 'bool' },
		],
		name: 'toggleBurn',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'totalSupply',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: 'id', type: 'uint256' }],
		name: 'totalSupply',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: 'newOwner', type: 'address' },
		],
		name: 'transferOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [],
		name: 'unpause',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: 'projectId', type: 'uint256' },
			{
				internalType: 'address',
				name: 'newWithdrawAddr',
				type: 'address',
			},
			{
				internalType: 'string[]',
				name: 'paymentMethods',
				type: 'string[]',
			},
			{ internalType: 'uint256', name: 'nonce', type: 'uint256' },
			{ internalType: 'bytes', name: '_signature', type: 'bytes' },
		],
		name: 'updateProject',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'uint256', name: '_tokenid', type: 'uint256' },
		],
		name: 'uri',
		outputs: [{ internalType: 'string', name: '', type: 'string' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		name: 'usedNonces',
		outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
		stateMutability: 'view',
		type: 'function',
	},
	{
		inputs: [
			{ internalType: 'address', name: '', type: 'address' },
			{ internalType: 'uint256', name: '', type: 'uint256' },
		],
		name: 'userBalances',
		outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
		stateMutability: 'view',
		type: 'function',
	},
];
