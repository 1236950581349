import {
	Button,
	Divider,
	Flex,
	Grid,
	GridItem,
	Link,
	Skeleton,
	Stack,
	Text,
	useColorMode,
	useDisclosure,
	useTheme,
	VStack,
} from '@chakra-ui/react';
import BoxComponent from '../../../components/BoxComponent';
import OfferInfoComponent from './OfferInfoComponent';

import { useNavigate } from 'react-router-dom';
import { PrivateRoutes } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';
import PaginationComponent from '../../../components/PaginationComponent';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../redux/states/user';
import IconReload from '../../../assets/Icons/components/IconReload';
import useProject from '../../../hooks/useProject';
import useClaim from '../../../hooks/useClaim';
import SendProjectTokensComponent from '../../../components/SendProjectTokensComponent';

/**
*
    @name TokenHoldingsComponent
    @description Component that list the offers that are avaliable for the user in this moment and shows how many time remains for the next payment
	@returns {JSX.Element} TokenHoldingsComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const TokenHoldingsComponent = ({ isTablet, projects }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const theme = useTheme();
	const user = useSelector(state => state.user);
	const {
		isOpen: isOpenSendModal,
		onOpen: onOpenSendModal,
		onClose: onCloseSendModal,
	} = useDisclosure();
	const [selectedProject, setSelectedProject] = useState();

	const { colorMode } = useColorMode();
	const [page, setPage] = useState(1);
	const [userProjects, setUserProjects] = useState(
		projects ? projects.slice(0, 5) : [],
	);
	const [isLoadingInformation, setIsLoadingInformation] = useState(false);
	const { getUserProjects } = useProject();
	const { checkUnclaimed, claim } = useClaim();
	const [isRowLoading, setRowIsLoading] = useState(false);

	const [isRefreshing, setIsRefreshing] = useState(false);

	const dispatch = useDispatch();

	const eraseProjectsFromArray = metadataId => {
		const _projects = projects.filter(
			item => item.metadataId === metadataId,
		);
		setUserProjects(_projects.slice(0, 5));
		dispatch(updateUser({ projects: _projects }));
	};

	const handleRefresh = async () => {
		setIsRefreshing(true);
		const projects = await getUserProjects(user.walletAddress);
		if (projects) {
			const userProjects = projects.slice((page - 1) * 5, page * 5);
			const checkedProjects = await checkUnclaimed(
				userProjects,
				user.walletAddress,
			);

			setUserProjects(checkedProjects);
			dispatch(updateUser({ projects: checkedProjects }));
		}
		setIsRefreshing(false);
	};

	const handleSendModal = metadataId => {
		const _project = projects.find(
			project => project.project.metadataId === metadataId,
		);
		setSelectedProject(_project);
		onOpenSendModal();
	};

	const handleClaimTokens = async metadataId => {
		setRowIsLoading(metadataId);
		const claimResponse = await claim({
			walletAddress: user.walletAddress,
			metadataId,
		});

		if (claimResponse.success) {
			await updateCheckedProjectsStatus();
		}
		setRowIsLoading(undefined);
		return claimResponse.success && claimResponse.receipt;
	};

	const updateCheckedProjectsStatus = async () => {
		const checkedProjects = await checkUnclaimed(
			userProjects,
			user.walletAddress,
		);
		setUserProjects(checkedProjects);
	};

	useEffect(() => {
		const checkUnclaimedProjects = async () => {
			if (userProjects?.length > 0) {
				setIsLoadingInformation(true);
				await updateCheckedProjectsStatus();
				setIsLoadingInformation(false);
			}
		};
		checkUnclaimedProjects();
	}, [page]);

	const colorBorderTablet =
		colorMode === 'dark'
			? theme.colors.transparencyWhite[100]
			: theme.colors.transparencyBlack[100];
	const colorBorderDesktop =
		colorMode === 'dark'
			? theme.colors.transparencyWhite[100]
			: theme.colors.transparencyBlack[200];

	return (
		<BoxComponent
			maxW={isTablet ? '500px' : 'none'}
			w='100%'
			h='100%'
			p='0'
			inPadding='15px'
			gap='11px'
		>
			<Flex justifyContent='space-between' alignItems='center'>
				<Text
					fontWeight='700'
					fontSize='16'
					lineHeight='100%'
					letterSpacing={0.02}
				>
					{t('dashboard.tokenHoldings.title')}
				</Text>
				<Button
					variant='secondary'
					fontWeight='400'
					fontSize='13px'
					py='10px'
					pl='5px'
					pe='10px'
					gap='5px'
					onClick={handleRefresh}
					isLoading={isRefreshing}
				>
					<IconReload boxSize='20px' color='white' />
					Refresh
				</Button>
			</Flex>
			<Flex
				flexDirection='column'
				justifyContent='flex-start'
				h='100%'
				gap='5px'
			>
				<Grid
					templateColumns='repeat(4, 1fr)'
					placeItems='center'
					textAlign='center'
					fontSize={isTablet ? '12px' : '14px'}
					border={isTablet ? '2px solid' : 'none'}
					borderColor={colorBorderTablet}
					borderRadius={isTablet ? '12px' : '0'}
					rowGap={isTablet ? '0' : '0'}
				>
					<GridItem
						borderRight={
							isTablet ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isTablet ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							wordBreak='break-all'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
						>
							{t('dashboard.tokenHoldings.name')}
						</Text>
					</GridItem>
					<GridItem
						borderRight={
							isTablet ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isTablet ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
						>
							{t('dashboard.tokenHoldings.totalToken')}
						</Text>
					</GridItem>
					<GridItem
						borderRight={
							isTablet ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isTablet ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
						>
							{t('dashboard.tokenHoldings.value')}
						</Text>
					</GridItem>
					<GridItem
						borderRight={
							isTablet ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isTablet ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
						>
							ROI
						</Text>
					</GridItem>
					<GridItem
						py={isTablet ? '12px' : '5px'}
						px='5px'
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isTablet ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isTablet ? '0px' : '6px'}
							wordBreak='break-all'
						>
							{t('dashboard.tokenHoldings.action')}
						</Text>
					</GridItem>
					<Divider
						gridColumn={isTablet ? 'span 5' : 'span 6'}
						borderWidth='1px'
						borderColor={colorBorderDesktop}
					/>

					{!userProjects ? (
						<Stack>
							<Skeleton height='198.45px' />
							<Skeleton height='198.45px' />
							<Skeleton height='198.45px' />
						</Stack>
					) : userProjects.length === 0 ? (
						<GridItem
							py={isTablet ? '24px' : '16px'}
							px='2px'
							colSpan={5}
						>
							<Text>{t('dashboard.myProperties.noTokens')}</Text>
						</GridItem>
					) : (
						userProjects.map((offer, index) => {
							return (
								<OfferInfoComponent
									key={index}
									data={offer}
									last={index === userProjects.length - 1}
									isTablet={isTablet}
									colorBorder={colorBorderDesktop}
									walletAddress={user.walletAddress}
									index={index}
									handleClaimTokens={handleClaimTokens}
									eraseFromArray={eraseProjectsFromArray}
									isLoadingInformation={isLoadingInformation}
									isRowLoading={
										offer.project.metadataId ===
										isRowLoading
									}
									handleRefresh={handleRefresh}
									onOpenSendModal={handleSendModal}
								/>
							);
						})
					)}
				</Grid>
				{projects && projects.length > 5 && (
					<Grid templateColumns='repeat(5, 1fr)'>
						<GridItem colSpan={3}>
							<VStack
								w={'100%'}
								justify={'center '}
								alignItems={'end'}
							>
								<Link
									variant='isActive'
									fontSize='13'
									fontWeight='400'
									letterSpacing={0.02}
									textAlign='center'
									textDecoration='underline'
									onClick={() =>
										navigate(`/${PrivateRoutes.Wallet}`)
									}
								>
									{t('dashboard.tokenHoldings.viewAll')}
								</Link>
							</VStack>
						</GridItem>
						<GridItem colSpan={2}>
							<PaginationComponent
								totalProjects={projects.length}
								page={page}
								setPage={setPage}
								pageSize={5}
							/>
						</GridItem>
					</Grid>
				)}
			</Flex>
			{isOpenSendModal && (
				<SendProjectTokensComponent
					isOpenSendModal={isOpenSendModal}
					closeSendModal={onCloseSendModal}
					isMobile={isTablet}
					project={selectedProject?.project}
				/>
			)}
		</BoxComponent>
	);
};

export default TokenHoldingsComponent;
