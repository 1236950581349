import { Stack } from '@chakra-ui/react';
import FinancialDetailsBoxInfo from './FinancialDetailsBoxInfo';
import ProjectCostSummaryBoxInfo from './ProjectCostSummaryBoxInfo';
import RentalIncomeBoxInfo from './RentalIncomeBoxInfo';
import CapitalGrowthBoxInfo from './CapitalGrowthBoxInfo';

const InfoBoxesComponent = ({ propertyInfo }) => {
	return (
		<Stack w='403px' gap='25px'>
			<FinancialDetailsBoxInfo propertyInfo={propertyInfo} />
			<ProjectCostSummaryBoxInfo propertyInfo={propertyInfo} />
			<RentalIncomeBoxInfo propertyInfo={propertyInfo} />
			<CapitalGrowthBoxInfo propertyInfo={propertyInfo} />
		</Stack>
	);
};

export default InfoBoxesComponent;
