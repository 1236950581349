export const blockedTemporaryEmailDomains = [
	'0-mail.com',
	'027168.com',
	'0815.ru',
	'0wnd.net',
	'0wnd.org',
	'10minutemail.com',
	'20minutemail.com',
	'30minutemail.com',
	'anonbox.net',
	'anonymbox.com',
	'babau.cf',
	'binkmail.com',
	'bobmail.info',
	'bofthew.com',
	'crazymailing.com',
	'cuvox.de',
	'discard.email',
	'discardmail.com',
	'fakeinbox.com',
	'guerrillamail.com',
	'guerrillamail.net',
	'mailinator.com',
	'maildrop.cc',
	'temp-mail.org',
	'temporary-mail.net',
	'yopmail.com',
	'trashmail.com',
	'getnada.com',
	'mintemail.com',
	'mytrashmail.com',
	'spam4.me',
	'spamdecoy.net',
];
