import BoxComponent from '../../../components/BoxComponent';
import { Box, Divider, Text } from '@chakra-ui/react';
import TransactionInfoComponent from './TransactionInfoComponent';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getUserAllMintedLogs } from '../../../services/userService';

/**
*
    @name TransactionComponent
    @description Component that list all the transactions a user has done. It overflows and generates a scroll
	@returns {JSX.Element} TransactionComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const TransactionComponent = () => {
	const { t } = useTranslation();
	const [transactions, setTransactions] = useState([]);

	useEffect(() => {
		const getUserAllMintedTransactions = async () => {
			const transactions = await getUserAllMintedLogs();
			if (transactions.success) {
				setTransactions(transactions?.data);
			}
		};
		getUserAllMintedTransactions();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<BoxComponent
			h='100%'
			w='100%'
			maxW='500px'
			p='0'
			inPadding='15px 20px'
		>
			<Text fontSize='16' fontWeight='700' pb='8px'>
				{t('dashboard.transaction.title')}2
			</Text>
			{transactions.length ? (
				<>
					{transactions.map((transaction, index) => {
						return (
							<Box key={index}>
								<TransactionInfoComponent data={transaction} />
								{transactions.length - 1 > index && (
									<Divider my='5px' />
								)}
							</Box>
						);
					})}
				</>
			) : (
				<Text w='100%' textAlign='center' mt={1}>
					{t('dashboard.myProperties.noTokens')}
				</Text>
			)}
		</BoxComponent>
	);
};

export default TransactionComponent;
