import { Flex, Skeleton, Stack, Text, useColorMode } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import useTokenTransfer from '../hooks/useTokenTransfer';
import { convertEthereuminUSD } from '../services/ethereumServices';
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from '../utils/utils';

const FeeComponent = () => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const [estimatedFee, setEstimatedFee] = useState(null);
	const { getEstimatedFee } = useTokenTransfer();

	useEffect(() => {
		const calculateEstimatedFees = async () => {
			const estimated = await getEstimatedFee();
			const estimatedInUsd = await convertEthereuminUSD(estimated);
			setEstimatedFee({ ethFee: estimated, ethFeeInUsd: estimatedInUsd });
		};
		calculateEstimatedFees();
	}, []);

	return (
		<Flex
			flexDirection='column'
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyBlack.50'
			}
			w='100%'
			lineHeight='12px'
			border='0.5px solid'
			borderColor='transparencyBlack.100'
			borderRadius='10px'
			p='20px'
			gap='10px'
		>
			<Flex justifyContent='space-between'>
				<Text fontSize='12px' fontWeight='400'>
					{t('feeComponent.estimatedFee')}
				</Text>
				<Stack spacing={0}>
					{estimatedFee ? (
						<Text variant='colored' fontSize='12px'>
							{estimatedFee.ethFee} ETH
						</Text>
					) : (
						<Skeleton h='12px' w='113.92px' />
					)}
				</Stack>
			</Flex>
			<Flex justifyContent='space-between'>
				<Flex>
					<Text variant='gray' fontSize='12px' fontWeight='400'>
						Market
					</Text>
					<Text
						color={colorMode === 'dark' ? 'brand.250' : 'brand.550'}
						fontSize='12px'
						fontWeight='400'
					>
						-30 sec
					</Text>
				</Flex>
				<Stack spacing={0}>
					{estimatedFee ? (
						<Text fontSize='14px' fontWeight='700'>
							{currencyFormatter(estimatedFee.ethFeeInUsd)}
						</Text>
					) : (
						<Skeleton w='62.98px' h='12px' />
					)}
				</Stack>
			</Flex>
		</Flex>
	);
};

export default FeeComponent;
