import { createIcon } from '@chakra-ui/react';

const IconArrowLeft = createIcon({
	displayName: 'IconArrowLeft',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M10.9358 1.84375L4.93579 7.84375L10.9358 13.8438'
			stroke='currentColor'
			fill='none'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconArrowLeft;
