import {
	Flex,
	Stack,
	Text,
	Button,
	useMediaQuery,
	Box,
	useColorMode,
	Image,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const UpcomingGeneralComponent = ({ title, subtitle, paragraph, button }) => {
	const [isMobile] = useMediaQuery('(max-width: 900px)');
	const [isTablet] = useMediaQuery('(max-width: 1140px)');
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const navigate = useNavigate();

	return (
		<Stack
			flexDirection={'column'}
			justifyContent='center'
			alignItems='center'
			spacing={20}
			textAlign={'center'}
			py={5}
		>
			<Stack justify={'center'} align={'center'}>
				<Text
					variant='colored'
					fontSize={isMobile ? '42px' : isTablet ? '65px' : '75px'}
					fontWeight='bold'
				>
					{t(title)}
				</Text>
				{subtitle ? (
					<Text
						fontWeight='700'
						fontSize={isTablet ? '20px' : '24px'}
					>
						{t(subtitle)}
					</Text>
				) : null}
				<Text
					fontWeight='400'
					fontSize={isTablet ? '12px' : '14px'}
					maxWidth='500px'
				>
					{t(paragraph)}
				</Text>
				{button ? (
					<Button
						variant='main'
						onClick={() => navigate('/')}
						zIndex={'10'}
					>
						{t(button)}
					</Button>
				) : null}
			</Stack>

			<Flex
				justifyContent='center'
				width='1100px'
				mt={18}
				position='relative'
				zIndex='1'
			>
				<Box position='absolute' top='-85px' zIndex='2'>
					<Image
						src={
							colorMode === 'dark'
								? '/assets/images/comingsoon/comingsoon-behind-dark.png'
								: '/assets/images/comingsoon/comingsoon-behind-light.png'
						}
					/>
				</Box>
			</Flex>

			<Flex
				justifyContent='center'
				width='800px'
				position='relative'
				zIndex='3'
			>
				<Box
					width='600px'
					borderRadius='lg'
					position='relative'
					top='-55px'
					zIndex='3'
				>
					<Image
						src={
							colorMode === 'dark'
								? '/assets/images/comingsoon/comingsoon-front-dark.png'
								: '/assets/images/comingsoon/comingsoon-front-light.png'
						}
						borderRadius='md'
					/>
				</Box>
			</Flex>
		</Stack>
	);
};

export default UpcomingGeneralComponent;
