import { useTranslation } from 'react-i18next';
import SingleUpdateComponent from './SingleUpdateComponent';
import { Stack, Text } from '@chakra-ui/react';

const ProjectUpdatesTab = ({ updates }) => {
	const { t } = useTranslation();
	const now = new Date();
	return (
		<Stack gap='20px'>
			{updates.length ? (
				updates.map((update, index) => (
					<SingleUpdateComponent
						key={index}
						update={update}
						now={now}
					/>
				))
			) : (
				<Text>
					{t(
						'projectDetails.projectMoreInfo.detailsTab.noAddedUpdate',
					)}
				</Text>
			)}
		</Stack>
	);
};

export default ProjectUpdatesTab;
