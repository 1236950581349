import InfoCurrencyBox from './InfoCurrencyBox';

import {
	Box,
	Flex,
	Stack,
	Text,
	useClipboard,
	useDisclosure,
} from '@chakra-ui/react';

import {
	IconUSDC,
	IconUSDT,
	IconEther,
	IconCopy,
	IconView,
	IconArrowUp,
	IconWallet,
	IconSales,
	IconTRNSK,
} from '../../../assets/Icons';
import { useState } from 'react';
import QRScreenComponent from '../../../components/QRScreenComponent';
import SendTokensComponent from './SendTokensComponent';
import { useTranslation } from 'react-i18next';
import { interactWithTransak } from '../../../lib/transak';
import { ENV } from '../../../constants/constants';
import SellTokensComponent from './SellTokensComponent';

const CryptoCurrencyComponent = ({
	user,
	balances,
	hiddenInfo,
	colorMode,
	color,
	isMobile,
	onHideInfo,
}) => {
	const { onCopy } = useClipboard(user.walletAddress);
	const [isOpenQRModal, setIsOpenQRModal] = useState(false);
	const [isOpenSendModal, setIsOpenSendModal] = useState(false);
	const {
		isOpen: isOpenSellModal,
		onOpen: onOpenSellModal,
		onClose: onCloseSellModal,
	} = useDisclosure();

	const { t } = useTranslation();

	const openQRModal = () => {
		setIsOpenQRModal(true);
	};
	const closeQRModal = () => {
		setIsOpenQRModal(false);
	};

	const openSendModal = () => {
		setIsOpenSendModal(true);
	};
	const closeSendModal = () => {
		setIsOpenSendModal(false);
	};

	return (
		<Box
			minW={isMobile ? '312px' : '530px'}
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyWhite.950'
			}
			border='0.5px solid'
			borderColor='transparencyBlack.200'
			borderRadius='12px'
			p='20px'
		>
			<Stack
				gap='20px'
				textAlign='center'
				alignItems='center'
				alignSelf='center'
				my='auto'
			>
				<Text
					variant='colored'
					fontSize='24px'
					fontWeight='bold'
					lineHeight='20px'
					letterSpacing={-0.02}
				>
					{t('walletPage.cryptoWallet')}
				</Text>
				<Stack gap='5px'>
					<Flex gap='10px'>
						<InfoCurrencyBox
							icon={
								ENV === 'dev' ? (
									<IconTRNSK boxSize='14px' />
								) : (
									<IconUSDT
										alignSelf='center'
										w='14px'
										color={
											colorMode === 'dark'
												? 'transparencyWhite.400'
												: 'transparencyBlack.500'
										}
									/>
								)
							}
							currency={ENV === 'dev' ? 'TRNSK' : 'USDT'}
							amount={balances.usdt}
							hiddenInfo={hiddenInfo}
						/>
						<InfoCurrencyBox
							icon={
								<IconUSDC
									alignSelf='center'
									w='14px'
									color={
										colorMode === 'dark'
											? 'transparencyWhite.500'
											: 'transparencyBlack.600'
									}
								/>
							}
							currency='USDC'
							amount={balances.usdc}
							hiddenInfo={hiddenInfo}
						/>
						<InfoCurrencyBox
							icon={
								<IconEther
									alignSelf='center'
									w='14px'
									color={
										colorMode === 'dark'
											? 'transparencyWhite.500'
											: 'transparencyBlack.600'
									}
								/>
							}
							currency='ETH'
							amount={balances.eth}
							hiddenInfo={hiddenInfo}
						/>
					</Flex>
					{/* <Text
						variant='gray'
						fontWeight='400'
						fontSize='10px'
						letterSpacing={-0.02}
						textDecoration='underline'
					>
						{t('walletPage.tokenFound')}
					</Text> */}
					<Text
						variant='gray'
						fontWeight='400'
						fontSize='12px'
						letterSpacing={-0.02}
						onClick={onHideInfo}
						cursor={'pointer'}
						w='fit-content'
						alignSelf='center'
					>
						<IconView
							w='13px'
							color={
								colorMode === 'dark'
									? 'transparencyWhite.900'
									: 'transparencyBlack.600'
							}
							mr='5px'
						/>
						{hiddenInfo
							? t('walletPage.view')
							: t('walletPage.hide')}
					</Text>
				</Stack>
				<Flex
					bg={
						colorMode === 'dark'
							? 'transparencyWhite.100'
							: 'transparencyBlack.50'
					}
					maxW='296px'
					alignItems='center'
					justifyContent='center'
					borderRadius='5px'
					p='10px'
					gap='10px'
				>
					<Flex flexDirection='column' gap='5px'>
						<Text
							variant='gray'
							fontWeight='400'
							fontSize='10px'
							textAlign='start'
							lineHeight='100%'
						>
							{t('walletPage.walletAddress')}
						</Text>
						<Text
							fontSize='10px'
							fontWeight='600px'
							textAlign='start'
							id='wallet'
							lineHeight='100%'
						>
							{user.walletAddress}
						</Text>
					</Flex>
					<IconCopy
						cursor='pointer'
						transition='transform 0.2s'
						_hover={{ transform: 'scale(1.1)' }}
						_active={{ transform: 'scale(1)' }}
						onClick={onCopy}
					/>
				</Flex>
				<Flex gap='22px'>
					<Stack>
						<Stack
							w='40px'
							h='40px'
							bg={color}
							borderRadius='50%'
							justifyContent='center'
							alignItems='center'
							transition='transform 0.2s'
							_hover={{ transform: 'scale(1.1)' }}
							_active={{ transform: 'scale(1)' }}
							gap='5px'
							cursor='pointer'
							onClick={() =>
								interactWithTransak(
									{
										userInfo: {
											email: user.email,
											walletAddress: user.walletAddress,
											userId: user.userId,
										},
									},
									txResponse =>
										console.log('Tx Response', txResponse),
								)
							}
						>
							<IconWallet
								color={
									colorMode === 'dark'
										? 'transparencyBlack.950'
										: 'transparencyWhite.950'
								}
								boxSize='16px'
							/>
						</Stack>
						<Text fontSize='10px'>{t('walletPage.buy')}</Text>
					</Stack>
					<Stack>
						<Stack
							w='40px'
							h='40px'
							bg={color}
							borderRadius='50%'
							justifyContent='center'
							alignItems='center'
							transition='transform 0.2s'
							_hover={{ transform: 'scale(1.1)' }}
							_active={{ transform: 'scale(1)' }}
							gap='5px'
							cursor='pointer'
							onClick={onOpenSellModal}
						>
							<IconSales
								color={
									colorMode === 'dark'
										? 'transparencyBlack.950'
										: 'transparencyWhite.950'
								}
								boxSize='16px'
							/>
						</Stack>
						<Text fontSize='10px'>{t('walletPage.sell')}</Text>
					</Stack>
					<Stack>
						<Stack
							w='40px'
							h='40px'
							bg={color}
							borderRadius='50%'
							justifyContent='center'
							alignItems='center'
							transition='transform 0.2s'
							_hover={{ transform: 'scale(1.1)' }}
							_active={{ transform: 'scale(1)' }}
							gap='5px'
							cursor='pointer'
							onClick={openSendModal}
						>
							<IconArrowUp
								color={
									colorMode === 'dark'
										? 'transparencyBlack.950'
										: 'transparencyWhite.950'
								}
								boxSize='16px'
							/>
						</Stack>
						<Text fontSize='10px'>{t('walletPage.send')}</Text>
					</Stack>
					<Stack>
						<Stack
							w='40px'
							h='40px'
							bg={color}
							borderRadius='50%'
							justifyContent='center'
							alignItems='center'
							transition='transform 0.2s'
							_hover={{ transform: 'scale(1.1)' }}
							_active={{ transform: 'scale(1)' }}
							gap='5px'
							cursor='pointer'
							onClick={openQRModal}
						>
							<IconArrowUp
								transform='rotate(180deg)'
								boxSize='16px'
								color={
									colorMode === 'dark'
										? 'transparencyBlack.950'
										: 'transparencyWhite.950'
								}
							/>
						</Stack>
						<Text fontSize='10px' fontWeight='400'>
							{t('walletPage.receive')}
						</Text>
					</Stack>
				</Flex>
			</Stack>
			<QRScreenComponent
				isOpenQRModal={isOpenQRModal}
				closeQRModal={closeQRModal}
			/>
			<SendTokensComponent
				isOpenSendModal={isOpenSendModal}
				closeSendModal={closeSendModal}
				isMobile={isMobile}
			/>
			<SellTokensComponent
				isOpen={isOpenSellModal}
				onClose={onCloseSellModal}
				isMobile={isMobile}
				balances={balances}
			/>
		</Box>
	);
};

export default CryptoCurrencyComponent;
