import { PrivateRoutes } from '../../routes/Routes';
import { useNavigate } from 'react-router-dom';
import {
	Box,
	Button,
	Flex,
	Grid,
	GridItem,
	Stack,
	Text,
	useColorMode,
	useMediaQuery,
} from '@chakra-ui/react';
import SmallInfoComponent from './components/SmallInfoComponent';
import DollarIcon from '../../assets/Icons/currency/DollarIcon';
import {
	IconPlus,
	IconBuilding,
	IconPaidUser,
	IconInvest,
	IconUser,
	IconSales,
	IconWallet,
} from '../../assets/Icons';
import { currencyFormatter, formatOrDash } from '../../utils/utils';
import MarketplaceTransactionsComponent from './components/MarketplaceTransactionsComponent';
import HigherROIComponent from './components/HigherROIComponent';
import GraphComponent from './components/GraphComponent';
import PublishedPropertiesComponent from './components/PublishedPropertiesComponent';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { getAdminData, getGraphData } from '../../services/adminService';
import LoadingComponent from '../../components/LoadingComponent';

const AdminPanel = () => {
	const [isSmallMobile] = useMediaQuery('(max-width: 450px)');
	const [isMobile] = useMediaQuery('(max-width: 800px)');
	const [isTablet] = useMediaQuery('(max-width: 1140px)');
	const [adminData, setAdminData] = useState();
	const { colorMode } = useColorMode();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [graphDays, setGraphDays] = useState(30);
	const [graphData, setGraphData] = useState();
	useMemo(async () => {
		setLoading(true);
		setGraphData(await getGraphData(graphDays));
		setLoading(false);
	}, [graphDays]);

	useEffect(() => {
		const getAdminPanelInformation = async () => {
			const { data, success } = await getAdminData();
			if (success) {
				setAdminData(data);
			}
		};
		getAdminPanelInformation();
	}, []);

	const templateColGrid1 = () => {
		let templateCol1;

		if (isSmallMobile) {
			templateCol1 = '1fr';
		} else if (isMobile) {
			templateCol1 = 'repeat(2, 1fr)';
		} else if (isTablet) {
			templateCol1 = 'repeat(6, 1fr)';
		} else {
			templateCol1 = 'repeat(5, 1fr)';
		}
		return templateCol1;
	};

	const getColSpanGrid1 = index => {
		let colSpan = 1;
		if (isTablet) {
			colSpan = index === 1 || index === 2 ? 3 : 2;
		}
		if (isMobile) {
			colSpan = index === 5 ? 2 : 1;
		}
		if (isSmallMobile) {
			colSpan = 1;
		}

		return colSpan;
	};

	const templateColGrid3 = () => {
		let templateCol3;

		if (isSmallMobile) {
			templateCol3 = '1fr';
		} else if (isTablet) {
			templateCol3 = 'repeat(2, 1fr)';
		} else {
			templateCol3 = 'repeat(4, 1fr)';
		}
		return templateCol3;
	};
	const bgStyle =
		colorMode === 'dark'
			? 'transparencyWhite.100'
			: 'transparencyWhite.950';

	return (
		<Stack gap='18px' align='center' w='100%' minH='800px'>
			<Flex
				w='100%'
				justifyContent='space-between'
				align='center'
				pb='13px'
			>
				<Text fontSize='32' fontWeight='700' lineHeight='40px'>
					{t('adminPage.title')}
				</Text>
				<Button
					variant='main'
					gap='5px'
					onClick={() => navigate(`/${PrivateRoutes.AddProperty}`)}
				>
					<IconPlus
						color={
							colorMode === 'dark'
								? 'transparencyBlack.500'
								: 'white'
						}
						boxSize='13px'
					/>

					<Text fontSize='13' lineHeight='40px'>
						{t('adminPage.addProperty')}
					</Text>
				</Button>
			</Flex>
			<Grid
				w='100%'
				templateRows='60px'
				templateColumns={templateColGrid1()}
				gap='18px'
			>
				<GridItem
					colSpan={getColSpanGrid1(1)}
					bg={bgStyle}
					border='0.5px solid'
					borderColor={'transparencyBlack.200'}
					borderRadius='12px'
				>
					<SmallInfoComponent
						icon={<IconBuilding boxSize='20px' />}
						smallText={t('adminPage.regProjects')}
						bigText={adminData?.numberOfProjects}
					/>
				</GridItem>
				<GridItem
					colSpan={getColSpanGrid1(2)}
					bg={bgStyle}
					border='0.5px solid'
					borderColor={'transparencyBlack.200'}
					borderRadius='12px'
				>
					<SmallInfoComponent
						icon={<IconUser boxSize='20px' />}
						smallText={t('adminPage.regUsers')}
						bigText={adminData?.registeredUsers}
					/>
				</GridItem>
				<GridItem
					colSpan={getColSpanGrid1(3)}
					bg={bgStyle}
					border='0.5px solid'
					borderColor={'transparencyBlack.200'}
					borderRadius='12px'
				>
					<SmallInfoComponent
						icon={<DollarIcon boxSize='20px' />}
						smallText={t('adminPage.income')}
						bigText={currencyFormatter(125230)}
						blur={true}
					/>
				</GridItem>
				<GridItem
					colSpan={getColSpanGrid1(4)}
					bg={bgStyle}
					border='0.5px solid'
					borderColor={'transparencyBlack.200'}
					borderRadius='12px'
				>
					<SmallInfoComponent
						icon={<IconPaidUser boxSize='20px' />}
						smallText={t('adminPage.paidToUsers')}
						bigText={formatOrDash(adminData?.totalPaidToUsers)}
					/>
				</GridItem>
				<GridItem
					colSpan={getColSpanGrid1(5)}
					bg={bgStyle}
					border='0.5px solid'
					borderColor={'transparencyBlack.200'}
					borderRadius='12px'
				>
					<SmallInfoComponent
						icon={<IconInvest boxSize='20px' />}
						smallText={t('adminPage.totalCollected')}
						bigText={formatOrDash(adminData?.totalCollected)}
					/>
				</GridItem>
			</Grid>

			<Grid
				templateRows={'160px'}
				templateColumns='repeat(12, 1fr)'
				gap='18px'
				w='100%'
			>
				<GridItem
					colSpan={isMobile ? 12 : 6}
					rowSpan={2}
					bg={bgStyle}
					border='0.5px solid'
					borderColor={'transparencyBlack.200'}
					borderRadius='12px'
				>
					{loading ? (
						<LoadingComponent />
					) : (
						<GraphComponent
							data={graphData?.data}
							setGraphDays={setGraphDays}
							graphDays={graphDays}
						/>
					)}
				</GridItem>
				<GridItem
					colSpan={isMobile ? 12 : 6}
					bg={bgStyle}
					border='0.5px solid'
					borderColor={'transparencyBlack.200'}
					borderRadius='12px'
				>
					<MarketplaceTransactionsComponent isTablet={isTablet} />
				</GridItem>
				<GridItem
					colSpan={isMobile ? 12 : 6}
					bg={bgStyle}
					border='0.5px solid'
					borderColor={'transparencyBlack.200'}
					borderRadius='12px'
				>
					<HigherROIComponent isTablet={isTablet} />
				</GridItem>
			</Grid>
			<Grid
				templateRows='60px'
				templateColumns={templateColGrid3()}
				gap='18px'
				w='100%'
			>
				<GridItem
					colSpan={1}
					bg={bgStyle}
					border='0.5px solid'
					borderColor={'transparencyBlack.200'}
					borderRadius='12px'
				>
					<SmallInfoComponent
						icon={<IconWallet boxSize='20px' />}
						smallText={t('adminPage.walletBalances')}
						bigText={currencyFormatter(126524)}
						blur={true}
					/>
				</GridItem>
				<GridItem
					colSpan={1}
					bg={bgStyle}
					border='0.5px solid'
					borderColor={'transparencyBlack.200'}
					borderRadius='12px'
				>
					<SmallInfoComponent
						icon={<DollarIcon boxSize='20px' />}
						smallText={t('adminPage.marketShareRevenues')}
						bigText={currencyFormatter(125203)}
						blur={true}
					/>
				</GridItem>
				<GridItem
					colSpan={1}
					bg={bgStyle}
					border='0.5px solid'
					borderColor={'transparencyBlack.200'}
					borderRadius='12px'
				>
					<SmallInfoComponent
						icon={<IconSales boxSize='20px' />}
						smallText={t('adminPage.rentalROI')}
						bigText='12%'
						blur={true}
					/>
				</GridItem>
				<GridItem
					colSpan={1}
					bg={bgStyle}
					border='0.5px solid'
					borderColor={'transparencyBlack.200'}
					borderRadius='12px'
				>
					<SmallInfoComponent
						icon={<IconSales boxSize='20px' />}
						smallText={t('adminPage.exchangeROI')}
						bigText='15%'
						blur={true}
					/>
				</GridItem>
			</Grid>
			<Box
				w='100%'
				bg={bgStyle}
				border='0.5px solid'
				borderColor={'transparencyBlack.200'}
				borderRadius='12px'
			>
				<PublishedPropertiesComponent isMobile={isMobile} />
			</Box>
		</Stack>
	);
};

export default AdminPanel;
