import { Stack, Text, useColorMode } from '@chakra-ui/react';

const SmallBoxBigNumberComponent = ({
	bigText,
	smallText,
	isTablet,
	blur = false,
}) => {
	const { colorMode } = useColorMode();
	return (
		<Stack
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyBlack.50'
			}
			borderRadius='10px'
			justifyContent='center'
			align='center'
			gap='12px'
			p={isTablet ? ' 10px ' : '20px'}
		>
			<Text
				fontSize={isTablet ? '32px' : '40px'}
				fontWeight='700'
				lineHeight='30px'
				letterSpacing={0.02}
				filter={blur ? 'blur(10px)' : 'none'}
			>
				{bigText}
			</Text>
			<Text
				variant='gray'
				fontSize='12px'
				fontWeight='400'
				lineHeight='9px'
				letterSpacing={0.02}
			>
				{smallText}
			</Text>
		</Stack>
	);
};

export default SmallBoxBigNumberComponent;
