import { Grid, useMediaQuery, Spacer, Stack } from '@chakra-ui/react';

import PropertyCardDiscovery from './PropertyCardDiscovery';
import UpcomingGeneralComponent from '../../../components/UpcomingGeneralComponent';
import PaginationComponent from '../../../components/PaginationComponent';

const PropertyGallery = ({
	projects,
	totalProjects,
	page,
	setPage,
	pageSize,
	isScreenLarge,
}) => {
	const [isMobile] = useMediaQuery('(max-width: 900px)');
	const cardWidth = isMobile ? '350px' : '312px';
	return (
		<Stack
			width='100%'
			minH={'calc(100vh - 250px)'}
			overflowX='hidden'
			p='15px'
			key='projects'
			alignItems={'center'}
		>
			{projects?.length > 0 ? (
				<>
					<Grid
						templateColumns={`repeat(auto-fit, minmax(0, ${cardWidth}))`}
						gridAutoColumns={{ xl: cardWidth }}
						gap='28px'
						placeContent='start'
						w={'100%'}
					>
						{projects?.map(project => (
							<PropertyCardDiscovery
								key={project.id}
								project={project}
								maxW={cardWidth}
							/>
						))}
					</Grid>
					{(isMobile && totalProjects > 1) ||
						(!isMobile && totalProjects > 12 && (
							<>
								<Spacer />
								<Stack justify={'center'}>
									<PaginationComponent
										totalProjects={totalProjects}
										page={page}
										setPage={setPage}
										pageSize={pageSize}
									/>
								</Stack>
							</>
						))}
				</>
			) : (
				<UpcomingGeneralComponent
					title='discovery.noProperties'
					paragraph='discovery.noPropertiesSubtitle'
				/>
			)}
		</Stack>
	);
};

export default PropertyGallery;
