import { Flex, Grid, GridItem, Text, useMediaQuery } from '@chakra-ui/react';
import BalanceComponent from './components/BalanceComponent';
import MyPropertiesComponent from './components/MyPropertiesComponent';
import OverviewComponent from './components/OverviewComponent';
import IncomeComponent from './components/IncomeComponent';
import TransactionComponent from './components/TransactionComponent';
import TrendComponent from './components/TrendComponent';
import TokenHoldingsComponent from './components/TokenHoldingsComponent';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import useProject from '../../hooks/useProject';
import { updateUser } from '../../redux/states/user';
import BlurComponent from '../../components/BlurComponent';

const Dashboard = () => {
	const { t } = useTranslation();
	const [isTablet] = useMediaQuery('(max-width: 1200px)');
	const user = useSelector(state => state.user);
	const { getUserProjects } = useProject();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!user.projects) {
			const getUserProjectsFromBlockchain = async () => {
				try {
					const projects = await getUserProjects(user.walletAddress);

					dispatch(updateUser({ projects }));
				} catch (error) {
					console.error(error);
				}
			};
			getUserProjectsFromBlockchain();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Flex
				flexDirection={isTablet ? 'column' : 'row'}
				justifyContent='space-between'
				align='center'
				mb='12px'
				gap='12px'
				w='100%'
			>
				<Text fontSize={32} fontWeight='700'>
					{t('dashboard.title')}
				</Text>
				<BalanceComponent user={user} isTablet={isTablet} />
			</Flex>
			<Grid
				templateColumns={
					isTablet ? '1fr' : '355px repeat(2, minmax(0, 1fr))'
				}
				templateRows={isTablet ? 'auto' : '165px 270px 380px'}
				rowGap='14px'
				columnGap='12px'
			>
				<GridItem
					rowSpan={3}
					colSpan={isTablet ? 3 : 1}
					w={isTablet ? 'auto' : '100%'}
					display='flex'
					justifyContent='center'
				>
					<MyPropertiesComponent
						projects={user.projects}
						isTablet={isTablet}
					/>
				</GridItem>
				<GridItem
					colSpan={isTablet ? 3 : 1}
					display='flex'
					justifyContent='center'
				>
					<OverviewComponent
						ownedProjects={user?.projects?.length}
						isTablet={isTablet}
						userId={user.userId}
					/>
				</GridItem>
				<GridItem
					colSpan={isTablet ? 3 : 1}
					display='flex'
					justifyContent='center'
					w='100%'
				>
					<IncomeComponent />
				</GridItem>
				<GridItem
					colSpan={isTablet ? 3 : 1}
					display='flex'
					justifyContent='center'
				>
					<TransactionComponent isTablet={isTablet} />
				</GridItem>
				<GridItem
					colSpan={isTablet ? 3 : 1}
					display='flex'
					justifyContent='center'
				>
					<BlurComponent blurAmount='6px'>
						<TrendComponent isTablet={isTablet} />
					</BlurComponent>
				</GridItem>
				<GridItem
					colSpan={isTablet ? 3 : 2}
					display='flex'
					justifyContent='center'
				>
					<TokenHoldingsComponent
						isTablet={isTablet}
						projects={user.projects}
					/>
				</GridItem>
			</Grid>
		</>
	);
};

export default Dashboard;
