import UpcomingGeneralComponent from '../../components/UpcomingGeneralComponent.js';

const Stake = () => {
	return (
		<UpcomingGeneralComponent
			title='stakePage.title'
			subtitle='stakePage.subtitle'
			paragraph='stakePage.paragraph'
			button='stakePage.button'
		/>
	);
};

export default Stake;
