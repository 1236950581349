import { Contract, JsonRpcProvider } from 'ethers';

import { convertUSDCinUSD, getUSDCBalance } from './usdcService';
import { convertUSDTinUSD, getUSDTBalance } from './usdtServices';
import { oracleABI } from '../interfaces/oracle.abi';
import { convertEthereuminUSD, getEthereumBalance } from './ethereumServices';
import { getNumberWithDecimals } from '../utils/utils';

const rpc = process.env.REACT_APP_RPC;
export const provider = new JsonRpcProvider(rpc);
const oracleAddress = process.env.REACT_APP_CONTRACT_ADDRESS_ORACLE;
export const oracle = await new Contract(oracleAddress, oracleABI, provider);

const formatBalance = async balance => {
	if (balance !== '0.000') {
		const formattedBalance = getNumberWithDecimals(balance);
		return formattedBalance;
	}
};

export const formatBalanceWallet = async balance => {
	return balance === '0.000' ? '0' : balance;
};

export const getWalletBalances = async walletAddress => {
	try {
		const [ethBalance, usdtBalance, usdcBalance] = await Promise.all([
			getEthereumBalance(walletAddress),
			getUSDTBalance(walletAddress),
			getUSDCBalance(walletAddress),
		]);

		const [
			formattedEthBalance,
			formattedUSDCBalance,
			formattedUSDTBalance,
		] = await Promise.all([
			formatBalance(ethBalance),
			formatBalance(usdcBalance),
			formatBalance(usdtBalance),
		]);

		const [ethInUsd, usdcInUsd, usdtInUsd] = await Promise.all([
			convertEthereuminUSD(formattedEthBalance),
			convertUSDCinUSD(formattedUSDCBalance),
			convertUSDTinUSD(formattedUSDTBalance),
		]);

		return {
			eth: formattedEthBalance,
			usdc: formattedUSDCBalance,
			usdt: formattedUSDTBalance,
			ethInUsd,
			usdcInUsd,
			usdtInUsd,
		};
	} catch (error) {
		console.error(
			'Error getting the wallet balances (getWalletBalances):',
			error,
		);
	}
};

export const getUSDBalanceInWallet = async balances => {
	const [ethBalance, usdcBalance, usdtBalance] = await Promise.all([
		convertEthereuminUSD(balances.eth),
		convertUSDCinUSD(balances.usdc),
		convertUSDCinUSD(balances.usdt),
	]);

	return parseFloat(ethBalance) + usdcBalance + usdtBalance;
};
