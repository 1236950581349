import { useTranslation } from 'react-i18next';
import { IconHelp } from '../../../../assets/Icons';
import { Text, Box, Flex, useColorMode } from '@chakra-ui/react';
import {
	getAnnualInvestmentReturn,
	getEstimatedAnnualRentalIncome,
	getEstimatedTotalROI,
} from '../../../../utils/projectDataUtils';
import FinancialStatisticalComponent from './FinancialStatisticalComponent';
import { formatOrDash } from '../../../../utils/utils';

const IncomesInfoBox = ({ projectInfo, amount }) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const estimatedTotalRoi = getEstimatedTotalROI(projectInfo);
	const annualRentalIncome = getEstimatedAnnualRentalIncome(
		projectInfo,
		amount,
	);
	const annualInvestmentReturn = getAnnualInvestmentReturn(projectInfo);

	return (
		<Box
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyBlack.50'
			}
			borderRadius='15px'
			py='15px'
			px='10px'
		>
			<Flex fontWeight='700' pb='10px' justify='space-between'>
				<Flex align='center' gap='4px'>
					<Text fontSize='14px'>
						{t(
							'projectDetails.offerDetails.projectIncomesInfo.incomesInfoBox.roi',
						)}
					</Text>
					<Box
						color={
							colorMode === 'dark'
								? 'transparencyWhite.500'
								: 'transparencyBlack.50'
						}
					>
						<IconHelp size='13px' color='gray' />
					</Box>
				</Flex>
				<Text fontSize='16px'>{estimatedTotalRoi}%</Text>
			</Flex>
			<Box
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyBlack.100'
				}
				borderRadius='10px'
				p='10px'
			>
				<FinancialStatisticalComponent
					label={t(
						'projectDetails.offerDetails.projectIncomesInfo.incomesInfoBox.annualInvestmentReturn',
					)}
					value={formatOrDash(annualInvestmentReturn)}
					icon={IconHelp}
				/>
				{projectInfo.category === 'Flip' && (
					<FinancialStatisticalComponent
						label={t(
							'projectDetails.offerDetails.projectIncomesInfo.incomesInfoBox.annualRentalIncome',
						)}
						value={formatOrDash(annualRentalIncome)}
						icon={IconHelp}
					/>
				)}
			</Box>
		</Box>
	);
};

export default IncomesInfoBox;
