import {
	getUSDBalanceInWallet,
	getWalletBalances,
} from '../services/walletService';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { updateUser } from '../redux/states/user';
import useContracts from './useContracts';
import { getEthereumBalance } from '../services/ethereumServices';
import { getNumberWithDecimals, getTokenAmount } from '../utils/utils';

const useInfoData = ({ walletAddress }) => {
	const user = useSelector(state => state.user);
	const [balances, setBalances] = useState(user.balances || {});
	const { usdcContract, usdtContract } = useContracts();
	const dispatch = useDispatch();

	const getBalances = async () => {
		const walletBalances = await getWalletBalances(walletAddress);
		const usdBalance = await getUSDBalanceInWallet(walletBalances);

		dispatch(updateUser({ balances: { usdBalance, ...walletBalances } }));
		setBalances({ usdBalance, ...walletBalances });
	};

	useEffect(() => {
		if (user.userId.length) {
			const handleTransferEvent = (
				from,
				to,
				value,
				tokenKey,
				formattedBalanceKey,
			) => {
				if (from === walletAddress || to === walletAddress) {
					updateTokenBalance(
						from,
						to,
						value,
						walletAddress,
						tokenKey,
						formattedBalanceKey,
					);
				}
			};

			try {
				getBalances();
				if (usdcContract && usdtContract) {
					usdcContract.on('Transfer', (from, to, value) => {
						handleTransferEvent(from, to, value, 'USDC', 'usdc');
					});

					usdtContract.on('Transfer', (from, to, value) => {
						handleTransferEvent(from, to, value, 'USDT', 'usdt');
					});
				}
			} catch (error) {
				console.error('Error updating balances', error);
			}

			const interval = setInterval(updateETHBalance, 10000);

			return () => {
				clearInterval(interval);
				usdcContract.off('Transfer', handleTransferEvent);
				usdtContract.off('Transfer', handleTransferEvent);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Dependencias incluidas

	const updateBalancesInState = (key, newBalance) => {
		try {
			const formattedBalance = getNumberWithDecimals(newBalance);
			if (balances[key] !== formattedBalance) {
				setBalances(prevBalances => ({
					...prevBalances,
					[key]: formattedBalance,
				}));

				dispatch(
					updateUser({
						balances: {
							...balances,
							[key]: formattedBalance,
						},
					}),
				);
			}
		} catch (error) {
			console.error('Error updating balance in state', error);
		}
	};

	const updateBalanceInStorage = (key, newBalance) => {
		try {
			const newUser = {
				...user,
				balances: { ...balances, [key]: newBalance },
			};
			localStorage.setItem(key, JSON.stringify(newUser));
		} catch (error) {
			console.error('Error updating balance in storage', error);
		}
	};

	const updateTokenBalance = async (
		from,
		to,
		value,
		walletAddress,
		formattedBalanceKey,
	) => {
		try {
			const amount =
				formattedBalanceKey === 'usdc'
					? getTokenAmount(value, 6)
					: getTokenAmount(value, 18); // Conversión de token a formato decimal
			const currentBalance = parseFloat(balances[formattedBalanceKey]);

			const newBalance =
				from === walletAddress
					? currentBalance - amount
					: currentBalance + amount;

			updateBalancesInState(formattedBalanceKey, newBalance);
			updateBalanceInStorage(formattedBalanceKey, newBalance);
		} catch (error) {
			console.error('Error updating token balance', error);
		}
	};

	const updateETHBalance = async () => {
		const ethBalance = await getEthereumBalance(walletAddress);
		if (ethBalance !== balances.eth) {
			updateBalancesInState('eth', ethBalance);
		}
	};

	return { balances };
};

export default useInfoData;
