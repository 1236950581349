import {
	Button,
	Divider,
	Flex,
	GridItem,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
} from '@chakra-ui/react';
import { currencyFormatter } from '../../../utils/utils';
import { IconDots } from '../../../assets/Icons';
import { useTranslation } from 'react-i18next';
import { getEstimatedTotalROI } from '../../../utils/projectDataUtils';
import useSeller from '../../../hooks/useSeller';
import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '../../../routes/Routes';
/**
*
    @name OfferInfoComponent
    @description Component that list 1 offer of the TokenHoldingComponent
	@returns {JSX.Element} OfferInfoComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const OfferInfoComponent = ({
	last,
	data,
	isTablet,
	colorBorder,
	walletAddress,
	eraseFromArray,
	handleClaimTokens,
	isLoadingInformation,
	isRowLoading = false,
	handleRefresh,
	onOpenSendModal,
}) => {
	const { refund } = useSeller();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { project } = data;

	const handleRefund = async () => {
		const refundResponse = await refund(walletAddress, project.metadataId);
		if (refundResponse.success) {
			eraseFromArray(project.metadataId);
			return refundResponse.receipt;
		}
	};

	const onNavigateProjectDetails = () => {
		navigate(`/${PublicRoutes.Project}/${project.projectId}`);
	};

	const handleClaim = async () => {
		const claimResponse = await handleClaimTokens(project.metadataId);
		if (!claimResponse.success) {
			handleRefresh();
		}
		return claimResponse.receipt;
	};

	const buttonConfig = {
		Published: {
			variant: 'main',
			text: project.isWithdrawn
				? t('dashboard.tokenHoldings.offerInfo.claim')
				: t('dashboard.tokenHoldings.offerInfo.buy'),
			isDisabled: project.claimed,
			onClick: project.isWithdrawn
				? handleClaim
				: onNavigateProjectDetails,
			isLoading: isRowLoading,
		},

		Funded: {
			variant: 'main',
			text: t('dashboard.tokenHoldings.offerInfo.claim'),
			isDisabled: true,
			onClick: null,
			isLoading: isRowLoading,
		},

		Refund: {
			variant: 'main',
			text: t('adminPage.pubProperties.Refund'),
			isDisabled: false,
			isLoading: isRowLoading,
			onClick: handleRefund,
		},
	};

	const {
		variant: buttonVariant,
		text: buttonText,
		isDisabled: buttonIsDisabled,
		onClick: buttonOnClick,
		isLoading: buttonIsLoading,
	} = buttonConfig[project.status] || buttonConfig.Published;

	return (
		<>
			<Text
				fontSize={isTablet ? '12px' : '14px'}
				fontWeight={isTablet ? '600' : '500'}
				py={isTablet ? '15px' : '0'}
				px='2px'
			>
				{project.title}
			</Text>
			<Text
				px='5px'
				variant='gray'
				fontSize={isTablet ? '10px' : '12px'}
				fontWeight='400'
			>
				{project.mintedSupply}
			</Text>
			<Text
				px='5px'
				variant='gray'
				fontSize={isTablet ? '10px' : '12px'}
				fontWeight='400'
			>
				{currencyFormatter(project.value)}
			</Text>
			<Text
				px='5px'
				variant='gray'
				fontSize={isTablet ? '10px' : '12px'}
				fontWeight='400'
			>
				{getEstimatedTotalROI(project)}%
			</Text>
			{!isTablet && (
				<Flex
					w='100%'
					justifyContent='center'
					align='center'
					p='6px'
					gap='10px'
				>
					<Button
						variant={buttonVariant}
						fontWeight='400'
						fontSize='13px'
						p='10px'
						w={isTablet ? '100%' : '100px'}
						isDisabled={buttonIsDisabled}
						onClick={buttonOnClick}
						isLoading={buttonIsLoading || isLoadingInformation}
					>
						{buttonText}
					</Button>
					<Button
						variant='secondary'
						fontWeight='400'
						fontSize='13px'
						p='10px'
						onClick={() => onOpenSendModal(data.project.metadataId)}
					>
						{t('dashboard.tokenHoldings.offerInfo.transfer')}
					</Button>
				</Flex>
			)}
			{isTablet && (
				<GridItem colSpan={isTablet ? 0 : 2}>
					<Menu>
						<MenuButton
							as={IconDots}
							boxSize='14px'
							cursor='pointer'
							transition='transform 0.2s'
							_hover={{ transform: 'scale(1.2)' }}
							_active={{ transform: 'scale(1)' }}
						/>
						<MenuList>
							<MenuItem>
								{t('dashboard.tokenHoldings.offerInfo.sell')}
							</MenuItem>
							<MenuItem>
								{t(
									'dashboard.tokenHoldings.offerInfo.transfer',
								)}
							</MenuItem>
							<MenuItem>
								{t('dashboard.tokenHoldings.offerInfo.buy')}
							</MenuItem>
						</MenuList>
					</Menu>
				</GridItem>
			)}
			{!last && (
				<Divider
					gridColumn={isTablet ? 'span 5' : 'span 6'}
					borderColor={colorBorder}
				/>
			)}
		</>
	);
};

export default OfferInfoComponent;
