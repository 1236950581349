import { createIcon } from '@chakra-ui/react';

const IconTRNSK = createIcon({
	displayName: 'IconTRNSK',
	fill: 'none',
	viewBox: '0 0 256 256',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<g clipPath='url(#clip1_4054_1866)'>
			<path
				d='M143.132 143.055L133.571 169.347C133.312 170.078 133.312 170.874 133.571 171.605L150.018 216.844L169.041 205.827L146.21 143.055C145.692 141.609 143.65 141.609 143.132 143.055Z'
				fill='currentColor'
			/>
			<path
				d='M162.3 98.8403C161.782 97.3941 159.74 97.3941 159.221 98.8403L149.661 125.132C149.402 125.863 149.402 126.659 149.661 127.391L176.608 201.456L195.631 190.439L162.3 98.8403Z'
				fill='currentColor'
			/>
			<path
				d='M128.5 41.2684C128.97 41.2684 129.44 41.3984 129.861 41.6259L202.405 83.6308C203.248 84.1183 203.766 85.0282 203.766 85.987V169.981C203.766 170.956 203.248 171.849 202.405 172.337L129.861 214.342C129.456 214.585 128.97 214.699 128.5 214.699C128.03 214.699 127.56 214.569 127.139 214.342L54.5949 172.369C53.7523 171.882 53.2338 170.972 53.2338 170.013V86.0032C53.2338 85.0282 53.7523 84.1345 54.5949 83.647L127.139 41.6421C127.56 41.3984 128.03 41.2684 128.5 41.2684ZM128.5 29C125.924 29 123.331 29.6662 121.014 31.0149L48.4861 73.0036C43.8519 75.6848 41 80.6409 41 86.0032V169.997C41 175.359 43.8519 180.315 48.4861 182.996L121.03 225.001C123.347 226.334 125.924 227.016 128.516 227.016C131.093 227.016 133.685 226.35 136.002 225.001L208.546 182.996C213.181 180.315 216.032 175.359 216.032 169.997V86.0032C216.032 80.6409 213.181 75.6848 208.546 73.0036L135.986 31.0149C133.669 29.6662 131.076 29 128.5 29Z'
				fill='currentColor'
			/>
			<path
				d='M80.5215 201.553L87.1974 183.224L100.63 194.42L88.0724 205.924L80.5215 201.553Z'
				fill='currentColor'
			/>
			<path
				d='M122.392 79.9908H104C102.623 79.9908 101.392 80.8521 100.922 82.152L61.498 190.536L80.5212 201.553L123.931 82.2008C124.336 81.1283 123.542 79.9908 122.392 79.9908Z'
				fill='currentColor'
			/>
			<path
				d='M154.572 79.9908H136.18C134.803 79.9908 133.572 80.8521 133.102 82.152L88.0879 205.908L107.111 216.925L156.111 82.2008C156.5 81.1283 155.706 79.9908 154.572 79.9908Z'
				fill='currentColor'
			/>
		</g>
	),
});

export default IconTRNSK;
