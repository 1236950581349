import { Stack, Text, useColorMode } from '@chakra-ui/react';

/**
*
    @name BoxComponent
    @description This component is the styled box that we use for different components like login, register...
    @returns {JS.Element} BoxComponent.js
    @author Edunknown | All-in-one Blockchain Company
    @Version 1.0.0
*/
const BoxComponent = ({
	transform,
	w,
	p = '10px',
	h,
	inPadding = '20px',
	borderRadius = '12px',
	gap = '0px',
	containerGap = '60px',
	maxW,
	minW,
	overflowY,
	headerMsg,
	headerFontSize = '30px',
	explainMsg,
	children,
	mx,
}) => {
	const { colorMode } = useColorMode();

	return (
		<Stack
			gap={containerGap}
			transform={transform}
			w={w}
			h={h}
			maxW={maxW}
			minW={minW}
			p={p}
			mx={mx}
		>
			{headerMsg && explainMsg && (
				<Stack spacing={0} textAlign='center'>
					<Text fontSize={headerFontSize} fontWeight='800'>
						{headerMsg}
					</Text>

					{explainMsg && (
						<Text fontSize='14px' fontWeight='400'>
							{explainMsg}
						</Text>
					)}
				</Stack>
			)}
			<Stack
				borderWidth='0.5px'
				borderColor='transparencyBlack.200'
				bg={
					colorMode === 'dark'
						? 'transparencyWhite.100'
						: 'transparencyWhite.950'
				}
				borderRadius={borderRadius}
				p={inPadding}
				h={h}
				gap={gap}
				overflowY={overflowY}
				sx={{
					'&::-webkit-scrollbar': {
						display: 'none',
					},
				}}
			>
				{children}
			</Stack>
		</Stack>
	);
};

export default BoxComponent;
