import { List, ListItem, Link, useColorMode } from '@chakra-ui/react';
import { IconPDF } from '../../../../../assets/Icons';
import { PINATA_CDN } from '../../../../../constants/constants';

/**
*
    @name DocumentList
    @description Component that renders a list of PDF files as clickable links with file icons.
    @param {Array} pdfFiles - Array of strings representing the names of PDF files.
    @returns {JS.Element} React component that displays a list of PDF files.
    @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const DocumentList = ({ documents }) => {
	const { colorMode } = useColorMode();
	return (
		<List spacing={'12px'}>
			{documents.map((doc, index) => (
				<ListItem
					key={index}
					bg={
						colorMode === 'dark'
							? 'transparencyWhite.100'
							: 'transparencyWhite.600'
					}
					width='fit-content'
					borderRadius='10px'
					border='0.5px solid '
					borderColor='transparencyBlack.200'
					py='6px'
					px='12px'
				>
					<Link
						href={`https://${PINATA_CDN}/ipfs/${doc?.cid}`}
						fontSize={14}
						fontWeight='medium'
						isExternal
						display='flex'
						gap={2}
					>
						<IconPDF
							alignSelf='center'
							color={
								colorMode === 'dark'
									? 'transparencyWhite.700'
									: 'transparencyBlack.600'
							}
						/>

						{doc.fileName}
					</Link>
				</ListItem>
			))}
		</List>
	);
};
export default DocumentList;
