import {
	Grid,
	Flex,
	Input,
	InputGroup,
	InputLeftElement,
	useMediaQuery,
	useColorMode,
	Menu,
	MenuButton,
	MenuList,
} from '@chakra-ui/react';
import { IconFilter, IconSearch } from '../../../../assets/Icons';

import TagFilter from './TagFilter';

import SortFiltersComponent from './SortFiltersComponent.js';

const Filters = ({ setProjectListFiltered }) => {
	const { colorMode } = useColorMode();
	const [isMobile] = useMediaQuery('(max-width: 420px)');
	const [isTablet] = useMediaQuery('(max-width: 1140px)');

	const handleTagFilter = event => {
		const { name, value } = event.target;

		setProjectListFiltered(prevState => ({
			...prevState,
			[name]: value,
		}));
	};

	return (
		<Grid
			gridTemplateColumns={
				isTablet ? 'minmax(0, 1fr)' : '280px minmax(0, 1fr)'
			}
			gap={isTablet ? '25px' : 0}
			mb={isTablet ? '5px' : '30px'}
			px='15px'
		>
			{isTablet && (
				<Flex gap='10px'>
					<InputGroup>
						<Input
							type='search'
							name='search'
							onChange={handleTagFilter}
							variant='search'
							placeholder='Search'
						/>
						<InputLeftElement>
							<IconSearch
								color='transparencyWhite.500'
								boxSize='14px'
								mt='2px'
								ml='15px'
							/>
						</InputLeftElement>
					</InputGroup>

					<Menu w='100%'>
						<Flex
							bg={
								colorMode === 'dark' ? 'brand.200' : 'brand.500'
							}
							display='flex'
							align='center'
							justifyContent='center'
							boxSize='40px'
							borderRadius='50%'
						>
							<MenuButton boxSize='40px' borderRadius='50%'>
								<IconFilter
									boxSize='20px'
									color={
										colorMode === 'dark'
											? 'transparencyBlack.950'
											: 'transparencyWhite.950'
									}
								/>
							</MenuButton>
							<MenuList zIndex='98' p='10px'>
								<SortFiltersComponent
									setProjectListFiltered={
										setProjectListFiltered
									}
									isTablet={isTablet}
									isMobile={isMobile}
								/>
							</MenuList>
						</Flex>
					</Menu>
				</Flex>
			)}
			{!isTablet && (
				<>
					<TagFilter onChange={handleTagFilter} />

					<SortFiltersComponent
						setProjectListFiltered={setProjectListFiltered}
						isTablet={isTablet}
						isMobile={isMobile}
					/>
				</>
			)}
		</Grid>
	);
};

export default Filters;
