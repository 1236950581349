import {
	Image,
	Spacer,
	Stack,
	Text,
	useColorMode,
	useMediaQuery,
} from '@chakra-ui/react';
import darkImage from '../assets/upcoming/dark.png';
import lightImage from '../assets/upcoming/light.png';
import { useTranslation } from 'react-i18next';

const UpcomingComponent = ({ title }) => {
	const { colorMode } = useColorMode();
	const [isTablet] = useMediaQuery('(max-width: 1140px)');
	const [isMobile] = useMediaQuery('(max-width: 768px)');
	const { t } = useTranslation();

	return (
		<Stack
			h='100%'
			w='100%'
			justifyContent='center'
			alignItems='center'
			flexDirection={isTablet ? 'column' : 'row'}
			spacing={5}
		>
			<Stack
				gridColumn='span 3'
				h='100%'
				w='100%'
				display='flex'
				justifyContent='center'
			>
				<Text
					variant='colored'
					fontSize={isMobile ? '16px' : isTablet ? '28px' : '36px'}
					letterSpacing={-0.03}
				>
					{t('upcomingTraderComponent.upcoming')}
				</Text>
				<Text
					fontWeight='700'
					fontSize={isMobile ? '24px' : isTablet ? '40px' : '50px'}
				>
					{t(`upcomingTraderComponent.${title}`)}
				</Text>
			</Stack>
			<Spacer />
			<Image src={colorMode === 'dark' ? darkImage : lightImage} />
		</Stack>
	);
};

export default UpcomingComponent;
