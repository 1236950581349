import { onboardingImages } from '../assets/onboarding';
import { addDays } from 'date-fns';

export const projectListData = [
	{
		id: 1,
		name: 'Villa in Palm Jumeirah',
		type: 'Villa',
		country: 'SpainCircle',
		city: 'Dubai',
		address: 'Frond D, the Palm Jumeirah, 61',
		postedBy: 'Elevex',
		propertyType: 'Villa',
		state: 'open',
		category: 'rental',
		subcategory: 'buy to rent',
		area: 560,
		bedrooms: 5,
		bathrooms: 5,
		totalPrize: 8490000,
		funded: 255000,
		appreciation: 4.75,
		projectDuration: 4,
		startDate: new Date('2024-06-15'),
		endDate: new Date('2024-10-01'),
		tokensPrize: 300,
		tokensPurchased: 100,
		roi: '12.17%',
		status: 'Published',
		img: [
			{ id: 1, url: 'Jumeirah-1.png' },
			{ id: 2, url: 'Jumeirah-2.png' },
			{ id: 3, url: 'Jumeirah-3.png' },
			{ id: 4, url: 'Jumeirah-4.png' },
			{ id: 5, url: 'Jumeirah-5.png' },
			{ id: 6, url: 'Jumeirah-6.png' },
		],
		steps: [
			{
				title: 'Lastest funding date',
				description:
					'This is a conservative estimate for the closing date of the property funding',
				startDate: new Date(2024, 5, 25),
				endDate: null,
			},
			{
				title: 'Ownership documents distributed',
				description:
					'Your property share certificates will be issued 2 weeks after the property is funded',
				startDate: new Date(2024, 7, 9),
				endDate: null,
			},
			{
				title: 'Expected first rental payment',
				description:
					'We project that the first rental payment will be paid by 31 August 2024',
				startDate: new Date(2024, 7, 31),
				endDate: new Date(2024, 8, 23),
			},
		],

		details:
			'Imagine a contemporary style house, with elegant lines that blend harmoniously with its natural surroundings. At the heart of the lush garden is a large swimming pool with crystal clear water that invites you to plunge into its freshness on warm days. The house, with white walls and large windows that allow abundant natural light to enter, offers a serene and luminous refuge. Its interior design combines modern comfort with touches of warmth, creating cozy spaces ideal for relaxing and enjoying life. With its sparkling pool as its centerpiece, this home represents the perfect balance of elegance, comfort and relaxation.',
		lat: 25.074539664860634,
		lng: 55.142678056771004,
	},
	{
		id: 2,
		name: 'Apartment in Goya',
		type: 'Apartment',
		country: 'spain_circle.png',
		city: 'Madrid',
		address: 'Calle Goya, 56',
		state: 'ended',
		postedBy: 'Elevex',
		propertyType: 'Apartment',
		category: 'flip',
		subcategory: 'buy to sell',
		appreciation: 4.75,
		area: 210,
		bedrooms: 4,
		bathrooms: 5,
		totalPrize: 1825000,
		funded: 1980000,
		projectDuration: 6,
		startDate: new Date('2024-05-30'),
		endDate: new Date('2024-11-30'),
		tokensPrize: 2500,
		tokensPurchased: 526,
		roi: '12.17%',
		status: 'Draft',
		img: [
			{ id: 1, url: 'Goya-1.png' },
			{ id: 2, url: 'Goya-2.png' },
			{ id: 3, url: 'Goya-3.png' },
			{ id: 4, url: 'Goya-4.png' },
			{ id: 5, url: 'Goya-4.png' },
		],
		steps: [
			{
				title: 'Lastest funding date',
				description:
					'This is a conservative estimate for the closing date of the property funding',
				startDate: new Date(2024, 5, 25),
				endDate: null,
			},
			{
				title: 'Ownership documents distributed',
				description:
					'Your property share certificates will be issued 2 weeks after the property is funded',
				startDate: new Date(2024, 6, 10),
				endDate: null,
			},
			{
				title: 'Expected key handover',
				description:
					'We expect the keys to be handed over to you on 15 December 2024',
				startDate: new Date(2024, 11, 15),
				endDate: null,
			},
		],
		details:
			'Imagine a luxurious villa set amidst expansive gardens, designed with classic architectural lines that harmonize seamlessly with its natural surroundings. Nestled at the heart of the estate is a sprawling swimming pool, its pristine waters beckoning you for a refreshing dip on sunny days. The villa features majestic white façades complemented by generous windows that flood the interiors with natural light, creating an atmosphere of tranquility and brilliance. The interior spaces are a blend of contemporary elegance and cozy warmth, offering ideal settings for relaxation and enjoyment. With its enchanting garden and exquisite pool, this villa epitomizes the perfect balance of luxury, comfort, and leisure.',
		lat: 40.4381311,
		lng: -3.6972481,
	},

	{
		id: 3,
		name: 'Casa de Campo',
		type: 'House',
		postedBy: 'Elevex',
		propertyType: 'House',
		country: 'spain_circle.png',
		city: 'Barcelona',
		address: 'Carrer del Rosselló, 132',
		state: 'open',
		category: 'rental',
		subcategory: 'off-plan to rent',
		area: 100,
		bedrooms: 4,
		bathrooms: 3,
		totalPrize: 2700000,
		appreciation: 4.75,
		funded: 1200000,
		projectDuration: 5,
		startDate: new Date('2024-07-10'),
		endDate: new Date('2024-12-31'),
		tokensNumber: 12,
		tokensPrize: 800,
		roi: '10.75%',
		status: '11,2%',
		img: [
			{ id: 1, url: 'Campo-1.png' },
			{ id: 2, url: 'Campo-2.png' },
			{ id: 3, url: 'Campo-3.png' },
			{ id: 4, url: 'Campo-4.png' },
			{ id: 4, url: 'Campo-5.png' },
		],
		steps: [
			{
				title: 'Lastest funding date',
				description:
					'This is a conservative estimate for the closing date of the property funding',
				startDate: new Date(2024, 6, 15),
				endDate: null,
			},
			{
				title: 'Ownership documents distributed',
				description:
					'Your property share certificates will be issued 2 weeks after the property is funded',
				startDate: new Date(2024, 7, 1),
				endDate: null,
			},
			{
				title: 'First rental contract signing',
				description:
					'We project that the first tenant will sign the rental contract by 15 September 2024',
				startDate: new Date(2024, 8, 15),
				endDate: null,
			},
		],
		details:
			'Imagine a charming house nestled in a serene corner of Barcelona, blending traditional Spanish architecture with modern comforts. Surrounded by lush greenery, the house features a cozy courtyard and a small fountain, creating a tranquil oasis in the bustling city. Inside, large windows bathe the interiors in natural light, highlighting the rustic yet elegant decor. The living spaces are designed for both relaxation and entertainment, offering a perfect balance of comfort and style. This house is ideal for those seeking a peaceful retreat with easy access to the vibrant life of Barcelona.',
		lat: 41.391979,
		lng: 2.174355,
	},

	{
		id: 4,
		name: 'Villa Spa',
		type: 'Villa',
		postedBy: 'Elevex',
		propertyType: 'Villa',
		country: 'spain_circle.png',
		city: 'Valencia',
		address: 'Carrer de les Garrigues',
		state: 'open',
		category: 'rental',
		subcategory: 'buy & renovate to sell',
		area: 150,
		bedrooms: 6,
		appreciation: 4.75,
		bathrooms: 4,
		totalPrize: 3200000,
		funded: 1500000,
		projectDuration: 2,
		startDate: new Date('2024-08-01'),
		endDate: new Date('2024-10-01'),
		tokensNumber: 15,
		tokensPrize: 1500,
		roi: '11.50%',
		status: '11,2%',
		img: [
			{ id: 1, url: 'VillaSpa-1.png' },
			{ id: 2, url: 'VillaSpa-2.png' },
			{ id: 3, url: 'VillaSpa-3.png' },
			{ id: 4, url: 'VillaSpa-4.png' },
			{ id: 4, url: 'VillaSpa-5.png' },
		],
		steps: [
			{
				title: 'Lastest funding date',
				description:
					'This is a conservative estimate for the closing date of the property funding',
				startDate: new Date(2024, 7, 15),
				endDate: null,
			},
			{
				title: 'Ownership documents distributed',
				description:
					'Your property share certificates will be issued 2 weeks after the property is funded',
				startDate: new Date(2024, 8, 1),
				endDate: null,
			},
			{
				title: 'Completion and handover',
				description:
					'The property construction will be completed and handed over by 1 December 2024',
				startDate: new Date(2024, 11, 1),
				endDate: null,
			},
		],
		details:
			'Imagine a luxurious villa nestled in the heart of Valencia, surrounded by picturesque orange groves and lush gardens. The villa features a magnificent swimming pool, its crystal-clear waters offering a refreshing escape from the Mediterranean sun. The architecture blends traditional Spanish elements with modern design, characterized by terracotta tiles and whitewashed walls. Inside, the villa boasts spacious living areas flooded with natural light, adorned with elegant furnishings and vibrant artworks. With its expansive grounds and serene ambiance, this villa promises an unparalleled lifestyle of luxury and relaxation.',
		lat: 39.4699075,
		lng: -0.3762881,
	},

	{
		id: 5,
		name: 'Cabin',
		type: 'Villa',
		postedBy: 'Elevex',
		propertyType: 'Villa',
		country: 'spain_circle.png',
		city: 'Sevilla',
		address: 'Calle Virgen de Luján',
		state: 'ended',
		category: 'build',
		subcategory: 'build to rent',
		area: 215,
		bedrooms: 7,
		bathrooms: 5,
		appreciation: 4.75,
		totalPrize: 3800000,
		funded: 2200000,
		projectDuration: 6,
		startDate: new Date('2024-03-06'),
		endDate: new Date('2024-09-15'),
		tokensNumber: 6,
		tokensPrize: 3000,
		roi: '13.25%',
		status: '9,1%',
		img: [
			{ id: 1, url: 'Cabin-1.png' },
			{ id: 2, url: 'Cabin-2.png' },
			{ id: 3, url: 'Cabin-3.png' },
			{ id: 4, url: 'Cabin-4.png' },
		],
		steps: [
			{
				title: 'Lastest funding date',
				description:
					'This is a conservative estimate for the closing date of the property funding',
				startDate: new Date(2024, 1, 15),
				endDate: null,
			},
			{
				title: 'Ownership documents distributed',
				description:
					'Your property share certificates will be issued 2 weeks after the property is funded',
				startDate: new Date(2024, 3, 1),
				endDate: null,
			},
			{
				title: 'Tenant move-in',
				description:
					'We anticipate the tenants will move in by 1 June 2024',
				startDate: new Date(2024, 5, 1),
				endDate: null,
			},
		],
		details:
			'Imagine a stunning villa in the heart of Sevilla, surrounded by vibrant gardens and offering breathtaking views of the historic city. The villa boasts a luxurious swimming pool, perfect for unwinding under the Andalusian sun. Its architecture combines traditional Moorish elements with modern sophistication, featuring whitewashed walls and intricately tiled floors. Inside, the villa exudes elegance with spacious rooms filled with natural light, adorned with stylish furnishings and colorful artwork. With its idyllic setting and unparalleled charm, this villa offers an exquisite blend of luxury and comfort.',
		lat: 37.3826417,
		lng: -5.9962951,
	},

	/* {
		id: 6,
		name: 'Casa Blanca',
		type: 'House',
		country: 'spain_circle.png',
		city: 'Bilbao',
		address: 'Alameda de Recalde, 11',
		state: 'open',
		category: 'purchased,
		subcategory: 'buy to sell',
		area: 200,
		bedrooms: 5,
		bathrooms: 4,
		totalPrize: 2950000,
		funded: 1750000,
		projectDuration: 8,
		startDate: new Date('2024-10-05'),
		endDate: new Date('2025-01-15'),
		tokensNumber: 10,
		tokensPrize: 2000,
		roi: '12.00%',
		status: '8,9%',
		img: [
			{ id: 1, url: 'Minimalist-1.png' },
			{ id: 2, url: 'Minimalist-2.png' },
			{ id: 3, url: 'Minimalist-3.png' },
			{ id: 4, url: 'Minimalist-4.png' },
			{ id: 4, url: 'Minimalist-5.png' },
			{ id: 4, url: 'Minimalist-6.png' },
			{ id: 4, url: 'Minimalist-7.png' },
		],
		steps: [
			{
				title: 'Lastest funding date',
				description:
					'This is a conservative estimate for the closing date of the property funding',
				startDate: new Date(2024, 8, 1),
				endDate: null,
			},
			{
				title: 'Ownership documents distributed',
				description:
					'Your property share certificates will be issued 2 weeks after the property is funded',
				startDate: new Date(2024, 9, 1),
				endDate: null,
			},
			{
				title: 'Final inspection and handover',
				description:
					'The final inspection and handover to the buyer will occur by 15 January 2025',
				startDate: new Date(2025, 0, 15),
				endDate: null,
			},
		],
		details:
			'Imagine a modern house nestled in the vibrant city of Bilbao, characterized by sleek lines and minimalist design that complements its urban surroundings. The house features a private rooftop terrace with panoramic views of the city skyline, perfect for entertaining or relaxing with friends and family. Inside, the interiors are bathed in natural light, creating a bright and airy atmosphere. The living spaces are designed for both comfort and style, offering a harmonious blend of modern amenities and contemporary elegance. With its prime location and sophisticated charm, this house presents an ideal urban retreat for those seeking a cosmopolitan lifestyle.',
		lat: 43.2630126,
		lng: -2.9349852,
	},
	{
		id: 7,
		name: 'Modern Apartment',
		type: 'Villa',
		country: 'spain_circle.png',
		city: 'Sevilla',
		address: 'Calle Virgen de Luján',
		state: 'ended',
		modality: 'rental',
		area: 215,
		bedrooms: 7,
		bathrooms: 5,
		totalPrize: 3800000,
		funded: 2200000,
		projectDuration: 6,
		startDate: new Date('2024-03-06'),
		endDate: new Date('2024-09-15'),
		tokensNumber: 6,
		tokensPrize: 3000,
		roi: '13.25%',
		status: '9,1%',
		img: [
			{ id: 1, url: 'Apartment-1.png' },
			{ id: 2, url: 'Apartment-2.png' },
			{ id: 3, url: 'Apartment-3.png' },
			{ id: 4, url: 'Apartment-4.png' },
		],
		steps: [
			{
				title: 'Lastest funding date',
				description:
					'This is a conservative estimate for the closing date of the property funding',
				startDate: new Date(2024, 1, 15),
				endDate: null,
			},
			{
				title: 'Ownership documents distributed',
				description:
					'Your property share certificates will be issued 2 weeks after the property is funded',
				startDate: new Date(2024, 3, 1),
				endDate: null,
			},
			{
				title: 'Tenant move-in',
				description:
					'We anticipate the tenants will move in by 1 June 2024',
				startDate: new Date(2024, 5, 1),
				endDate: null,
			},
		],
		details:
			'Imagine a stunning villa in the heart of Sevilla, surrounded by vibrant gardens and offering breathtaking views of the historic city. The villa boasts a luxurious swimming pool, perfect for unwinding under the Andalusian sun. Its architecture combines traditional Moorish elements with modern sophistication, featuring whitewashed walls and intricately tiled floors. Inside, the villa exudes elegance with spacious rooms filled with natural light, adorned with stylish furnishings and colorful artwork. With its idyllic setting and unparalleled charm, this villa offers an exquisite blend of luxury and comfort.',
		lat: 37.3826417,
		lng: -5.9962951,
	}, */
];

export const languages = [
	'Mandarín',
	'Español',
	'Inglés',
	'Hindi',
	'Árabe',
	'Bengalí',
	'Portugués',
	'Ruso',
	'Japonés',
	'Lahnda (Punjabi Occidental)',
	'Maratí',
	'Telugú',
	'Wu (Shanghainés)',
	'Turco',
	'Coreano',
	'Francés',
	'Alemán',
	'Vietnamita',
	'Tamil',
	'Urdu',
];

export const timeZones = [
	'GMT-12',
	'GMT-11',
	'GMT-10',
	'GMT-9',
	'GMT-8',
	'GMT-7',
	'GMT-6',
	'GMT-5',
	'GMT-4',
	'GMT-3',
	'GMT-2',
	'GMT-1',
	'GMT+0',
	'GMT+1',
	'GMT+2',
	'GMT+3',
	'GMT+4',
	'GMT+5',
	'GMT+6',
	'GMT+7',
	'GMT+8',
	'GMT+9',
	'GMT+10',
	'GMT+11',
	'GMT+12',
	'GMT+13',
	'GMT+14',
];

export const currency = [
	{ id: 1, name: 'USD', symbol: '$' },
	{ id: 2, name: 'EUR', symbol: '€' },
	{ id: 3, name: 'JPY', symbol: '¥' },
	{ id: 4, name: 'GBP', symbol: '£' },
	{ id: 5, name: 'AUD', symbol: 'A$' },
	{ id: 6, name: 'CAD', symbol: 'C$' },
	{ id: 7, name: 'CHF', symbol: 'CHF' },
	{ id: 8, name: 'CNY', symbol: '¥' },
	{ id: 9, name: 'SEK', symbol: 'kr' },
	{ id: 10, name: 'NZD', symbol: 'NZ$' },
];

export const currency2 = ['USD $', 'EUR €'];

export const userExample = {
	fullname: 'Jhon Doe',
	username: 'jhondoe2',
	email: 'jhondoe23@gmail.com',
	phoneNumber: '+25 248 568 785',
	city: 'Bangkok, Thailand',
	photo: 'man.jpg',
	accBalance: 40000,
	thether: 34498,
	circleCoin: 34497,
	ether: 3.7,
	claim: 23200,
	properties: projectListData,
};

export const colorModes = ['Dark', 'Light'];

export const faqs = [
	{
		id: 1,
		question: 'What is real estate tokenization?',
		answer: 'Real estate tokenization is the process of converting property ownership rights into a digital token on a blockchain. This allows properties to be divided and sold in small parts, making real estate investment more accessible.',
	},
	{
		id: 2,
		question: 'How does real estate tokenization work?',
		answer: 'Real estate tokenization works by creating a digital token that represents a share of the property. Investors can buy these tokens, thereby obtaining a proportional stake in the property. The tokens are managed and transferred via a blockchain-based platform.',
	},
	{
		id: 3,
		question: 'What are the advantages of real estate tokenization?',
		answer: 'The advantages include greater liquidity, as tokens can be easily bought and sold; accessibility, allowing more people to invest in real estate; and transparency, thanks to blockchain technology that securely and immutably records all transactions.',
	},
	{
		id: 4,
		question: 'Is it safe to invest in tokenized real estate?',
		answer: 'Investing in tokenized real estate can be safe as long as the platform used complies with all relevant legal and security regulations. Additionally, blockchain technology provides an extra layer of security by transparently and immutably recording all transactions.',
	},
	{
		id: 5,
		question: 'What types of properties can be tokenized?',
		answer: 'Different types of properties can be tokenized, including residential, commercial, industrial, and land. Tokenization allows investors to diversify their portfolios by investing in a variety of properties.',
	},
];

export const onboardingSlides = [
	{
		image: onboardingImages.invest,
		header: 'invest',
		info: 'Discover and filter between real estate projects such as flips and rentals available for funding. Find the best opportunities tailored to your preferences.',
	},
	{
		image: onboardingImages.tokens,
		header: 'projectTokens',
		info: 'Receive project tokens directly to your wallet after funding a project or purchasing them from the secondary marketplace. Start earning yield immediately.',
	},
	{
		image: onboardingImages.trade,
		header: 'trade',
		info: 'Buy, sell, bid, and trade project tokens through our marketplace. Easily explore and interact with various project collections.',
	},
	{
		image: onboardingImages.dashboard,
		header: 'yourDashboard',
		info: 'Manage your project tokens effortlessly. Track your purchases, monitor yields, and stay updated with your portfolio´s performance.',
	},
	{
		image: onboardingImages.stake,
		header: 'stake',
		info: 'Stake your tokens to participate in new project launches and gain access to the Elevex sniperbot for enhanced investment opportunities. Stay tuned for this exciting feature!',
	},
];

export const financialSummary = {
	ProjectId: 1,

	publicOfferPrice: {
		value: 120230.99,
		taxes: 25253.63,
		renovation: 12531.81,
		expenses: 1760.53,
		total: 261450.25,
	},

	finalPrice: {
		offer: 261450.9,
		gain: 1760.53,
		sellingPrice: 301450.25,
	},

	yields: {
		return: 25253.9,
		roi: 120230.9,
		distribution: 'Monthly',
	},
};

export const projectUpdates = [
	{
		projectID: 1,
		update: 'Changed name',
		time: new Date(2024, 6, 3, 11, 39),
		description: 'The name has been changed to Villa Lele',
	},
	{
		projectId: 1,
		update: 'Changed Price',
		time: new Date(2024, 6, 3, 10, 21),
		description: 'Price changed',
	},
	{
		projectId: 1,
		update: 'Location update',
		time: new Date(2024, 6, 2, 16, 50),
		description:
			'We noticed the location was not correct so we fixed the location of the property',
	},
	{
		projectId: 1,
		update: 'Location update',
		time: new Date(2024, 5, 28, 13, 3),
		description:
			'We noticed the location was not correct so we fixed the location of the property',
	},
	{
		projectId: 1,
		update: 'Location update',
		time: new Date(2024, 2, 30, 14, 28),
		description:
			'We noticed the location was not correct so we fixed the location of the property',
	},
	{
		projectId: 1,
		update: 'Location update',
		time: new Date(2023, 2, 15, 12, 33),
		description:
			'We noticed the location was not correct so we fixed the location of the property',
	},
];

export const income = {
	nextMonth: 12321,
	upcoming: 25215,
	lastMonth: 11567,
	nextPayment: addDays(new Date(), 12),
};

export const transactions = [
	{
		name: 'Elevex - House New York',
		date: new Date(),
		type: 1,
		price: 250,
	},
	{
		name: 'Elevex - House New York',
		date: new Date(),
		type: 0,
		price: 2500,
	},
	{
		name: 'Elevex - House New York',
		date: new Date(),
		type: 1,
		price: 250,
	},
	{
		name: 'Elevex - House New York',
		date: new Date(),
		type: 0,
		price: 1230,
	},
];

export const offerData = [
	{
		name: 'House in Washington',
		totalToken: 200,
		value: 4000, // 20 por token
		roi: 14.5,
		status: 'Published',
	},
	{
		name: 'Apartment in Thailand',
		totalToken: 150,
		value: 3000, // 20 por token
		roi: 12.5,
		status: 'Funded',
	},
	{
		name: 'Villa in Marbella',
		totalToken: 250,
		value: 7500, // 30 por token
		roi: 11.2,
		status: 'Published',
	},
	{
		name: 'Villa in Italy',
		totalToken: 180,
		value: 5400, // 30 por token
		roi: 11.2,
		status: 'Cancelled',
	},
	{
		name: 'Apartment in New York',
		totalToken: 320,
		value: 12800, // 40 por token
		roi: 9.1,
		status: 'Funded',
	},
	{
		name: 'Apartment in Huelin',
		totalToken: 370,
		value: 14800, // 40 por token
		roi: 93.7,
		status: 'Published',
	},
];
