import UpcomingGeneralComponent from '../../components/UpcomingGeneralComponent.js';

const Offers = () => {
	return <UpcomingGeneralComponent 
	title='offersPage.title' 
	subtitle='offersPage.subtitle'
	paragraph='offersPage.paragraph'
	button='offersPage.button' />
};

export default Offers;
