import { Contract, JsonRpcProvider } from 'ethers';
import { usdcABI } from '../interfaces/usdcABI';
import { formatBalanceWallet, oracle } from './walletService';
import { getNumberWithDecimals, getTokenAmount } from '../utils/utils';

const rpc = process.env.REACT_APP_RPC;
export const provider = new JsonRpcProvider(rpc);
const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS_USDC;
export const usdcContract = new Contract(contractAddress, usdcABI, provider);

export const getUSDCBalance = async walletAddress => {
	try {
		const balance = await usdcContract.balanceOf(walletAddress);
		return getTokenAmount(balance, 6);
	} catch (error) {
		console.error(
			'Error getting the USDC balance (getUSDCBalance):',
			error,
		);
	}
};

export const getUSDCValueinUSD = async () => {
	try {
		const price = await oracle.getLatestPrice('USDC');
		return getTokenAmount(price, 8);
	} catch (error) {
		console.error(
			'Error getting the USDC value (getUSDCValueinUSD):',
			error,
		);
	}
};

export const convertUSDCinUSD = async usdcAmount => {
	try {
		const usdcAmountNumber = parseFloat(usdcAmount);
		const usdcPrice = await getUSDCValueinUSD();
		return getNumberWithDecimals(usdcAmountNumber * usdcPrice);
	} catch (error) {
		console.error(
			'Error converting usdc in usd (convertUSDCinUSD):',
			error,
		);
	}
};

export const formatBalanceUSDC = async usdcAmount => {
	try {
		const formattedUSDCBalance = usdcAmount.toFixed(3).toString();
		return formatBalanceWallet(formattedUSDCBalance);
	} catch (error) {
		console.error('Error formatting balance (formatBalanceUSDC):', error);
	}
};
