import GoogleAutocomplete from './GoogleAutocomplete';
import { savePlaceDetails } from '../../utils/GoogleMapsInfo';

const AddressAutocomplete = ({
	setSelectedSuggestion,
	incorrectData,
	initialAddressValue,
	placeholder,
	fieldName = 'address',
	deleteFromIconrrectData,
}) => {
	const onSelectAddress = async address => {
		const fullAddress = await savePlaceDetails(address);
		setSelectedSuggestion({
			city: fullAddress?.city,
			country: fullAddress?.country,
			postalCode: fullAddress?.postalCode,
			address: fullAddress?.formattedAddress,
			coordinates: fullAddress?.coordinates,
			selectedAddress: address,
		});
		deleteFromIconrrectData(fieldName);
	};

	return (
		<GoogleAutocomplete
			onSelectAddress={e => onSelectAddress(e)}
			placeholder={placeholder}
			incorrectData={
				incorrectData?.includes(fieldName) ? '1px solid red' : 'none'
			}
			addressValue={initialAddressValue}
		/>
	);
};

export default AddressAutocomplete;
