import { formatEther, JsonRpcProvider } from 'ethers';
import { formatBalanceWallet, oracle } from './walletService';
import { getNumberWithDecimals, getTokenAmount } from '../utils/utils';

const rpc = process.env.REACT_APP_RPC;
export const provider = new JsonRpcProvider(rpc);

/**
 * @name getEthereumBalance
 * @description Get the Ethereum balance
 * @param {string} walletAddress
 * @returns {Promise}
 */
export const getEthereumBalance = async walletAddress => {
	try {
		const balance = await provider.getBalance(walletAddress);
		return formatEther(balance).toString();
	} catch (error) {
		console.error(
			'Error getting the Ethereum balance (getEthereumBalance):',
			error,
		);
	}
};

/**
 * @name getEthValueinUSD
 * @description Get the Ethereum value in USD
 * @returns {Promise}
 */
export const getEthValueinUSD = async () => {
	try {
		const price = await oracle.getLatestPrice('ETH');
		return getTokenAmount(price, 8);
	} catch (error) {
		console.error(
			'Error getting the Ethereum price (getEthValueinUSD):',
			error,
		);
	}
};

/**
 * @name convertEthereuminUSD
 * @description Convert the Ethereum in USD
 * @param {number} ethAmount
 * @returns {Promise}
 */
export const convertEthereuminUSD = async ethAmount => {
	try {
		const ethAmountNumber = parseFloat(ethAmount);
		const ethPrice = await getEthValueinUSD();
		return getNumberWithDecimals(ethAmountNumber * parseFloat(ethPrice));
	} catch (error) {
		console.error(
			'Error converting eth to usd (convertEthereuminUSD):',
			error,
		);
	}
};

/**
 * @name formatEthBalance
 * @description Format the Ethereum balance
 * @param {number} ethAmount
 * @returns {Promise}
 */
export const formatEthBalance = async ethAmount => {
	try {
		const formattedEthBalance = ethAmount.toFixed(3).toString();
		return formatBalanceWallet(formattedEthBalance);
	} catch (error) {
		console.error('Error formatting balance (formatEthBalance):', error);
	}
};

/**
 * @name performContractFunction
 * @description Perform a contract function
 * @param {Contract} contract
 * @param {string} functionName
 * @param {any} params
 * @returns {Promise}
 */
export const performContractFunction = async (
	contract,
	functionName,
	...params
) => {
	try {
		await contract[functionName].staticCall(...params);

		const tx = await contract[functionName](...params);

		const receipt = await tx.wait();

		return { success: true, receipt };
	} catch (error) {
		console.error(
			'Error performing contract function (performContractFunction):',
			error,
		);
		return { success: false, msg: error.message };
	}
};
