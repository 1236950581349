import {
	Divider,
	Flex,
	Box,
	Avatar,
	Text,
	useColorMode,
	useMediaQuery,
	Image,
} from '@chakra-ui/react';
import BoxSmallComponent from '../../../components/BoxSmallComponent';
import { useTranslation } from 'react-i18next';
import {
	getCountryCode,
	splitStringFromCommaPosition,
} from '../../../utils/utils';
import { propertyStatus } from '../../../data/optionsData';

const ProjectGeneralInfo = ({ projectInfo, projectStatus }) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const [isMobile] = useMediaQuery('(max-width: 768px)');

	const ISO = getCountryCode(projectInfo.country);

	return (
		<Box>
			<Flex
				justify='space-between'
				direction={isMobile ? 'column' : 'row'}
				gap='10px'
			>
				<Flex align='center'>
					<Text fontWeight='bold' fontSize='24px'>
						{projectInfo?.title}
					</Text>
				</Flex>
				<Flex gap='10px' alignItems='center'>
					<Image
						src={`/assets/countries/${ISO}.webp`}
						boxSize='20px'
						rounded={'50px'}
					/>
					<Box
						bg={
							projectStatus === propertyStatus.Cancelled
								? 'red.500'
								: colorMode === 'dark'
									? 'brand.200'
									: 'brand.500'
						}
						h={'fit-content'}
						borderRadius='20px'
						px='10px'
						py='5px'
					>
						<Text
							color={
								projectStatus === propertyStatus.Cancelled
									? 'transparencyWhite.800'
									: colorMode === 'dark'
										? 'transparencyBlack.800'
										: 'transparencyWhite.800'
							}
							fontSize='12px'
							fontWeight='light'
						>
							{t(
								`projectDetails.projectGeneralInfo.state.${projectStatus.toLowerCase()}`,
							)}
						</Text>
					</Box>
					{projectInfo?.category ? (
						<Box
							bg={
								colorMode === 'dark'
									? 'transparencyWhite.200'
									: 'transparencyWhite.800'
							}
							border='0.5px solid'
							borderColor='transparencyBlack.100'
							h='fit-content'
							borderRadius='20px'
							px='10px'
							py='5px'
						>
							<Text
								variant='colored'
								fontSize='12px'
								fontWeight='light'
							>
								{t(
									`projectDetails.projectGeneralInfo.category.${projectInfo.category.toLowerCase()}`,
								)}
							</Text>
						</Box>
					) : null}

					{projectInfo.subcategory ? (
						<Box
							bg={
								colorMode === 'dark'
									? 'transparencyWhite.200'
									: 'transparencyWhite.800'
							}
							border='0.5px solid'
							borderColor='transparencyBlack.100'
							h='fit-content'
							borderRadius='20px'
							px='10px'
							py='5px'
						>
							<Text
								variant='colored'
								fontSize='12px'
								fontWeight='light'
								textAlign='center'
							>
								{t(
									`projectDetails.projectGeneralInfo.subcategory.${projectInfo.subcategory.toLowerCase()}`,
								)}
							</Text>
						</Box>
					) : null}
				</Flex>
			</Flex>

			<Divider my='30px'></Divider>

			<Flex wrap='wrap' gap='10px' width='100%' maxW='704px'>
				<BoxSmallComponent
					textTitle={t('projectDetails.projectGeneralInfo.type')}
					textContent={projectInfo.propertyType ?? '-'}
				/>
				<BoxSmallComponent
					textTitle={t('projectDetails.projectGeneralInfo.city')}
					textContent={projectInfo.city ?? '-'}
				/>
				<BoxSmallComponent
					textTitle={t('projectDetails.projectGeneralInfo.address')}
					textContent={splitStringFromCommaPosition(
						projectInfo.address ?? '-',
						3,
					)}
				/>
				<BoxSmallComponent
					textTitle={t('projectDetails.projectGeneralInfo.area')}
					textContent={
						projectInfo.areaM2 ? `${projectInfo.areaM2}m²` : '-'
					}
				/>
			</Flex>
			<Flex wrap='wrap' gap='10px' width='100%' mt={'10px'}>
				{projectInfo.equipments?.room > 0 && (
					<BoxSmallComponent
						textTitle={t('projectDetails.projectGeneralInfo.rooms')}
						textContent={projectInfo.equipments?.room}
					/>
				)}
				{projectInfo.equipments?.bathroom > 0 && (
					<BoxSmallComponent
						textTitle={t(
							'projectDetails.projectGeneralInfo.bathrooms',
						)}
						textContent={projectInfo.equipments?.bathroom}
					/>
				)}

				{projectInfo.equipments?.balcony && (
					<BoxSmallComponent
						textTitle={t(
							'projectDetails.projectGeneralInfo.balcony',
						)}
						textContent={t('projectDetails.projectGeneralInfo.yes')}
					/>
				)}
				{projectInfo.equipments?.barbecue && (
					<BoxSmallComponent
						textTitle={t(
							'projectDetails.projectGeneralInfo.barbecue',
						)}
						textContent={t('projectDetails.projectGeneralInfo.yes')}
					/>
				)}
				{projectInfo.equipments?.gym && (
					<BoxSmallComponent
						textTitle={t('projectDetails.projectGeneralInfo.gym')}
						textContent={t('projectDetails.projectGeneralInfo.yes')}
					/>
				)}
				{projectInfo.equipments?.library && (
					<BoxSmallComponent
						textTitle={t(
							'projectDetails.projectGeneralInfo.library',
						)}
						textContent={t('projectDetails.projectGeneralInfo.yes')}
					/>
				)}
				{projectInfo.equipments?.parking > 0 && (
					<BoxSmallComponent
						textTitle={t(
							'projectDetails.projectGeneralInfo.parking',
						)}
						textContent={projectInfo.equipments.parking}
					/>
				)}
				{projectInfo.equipments?.pool && (
					<BoxSmallComponent
						textTitle={t('projectDetails.projectGeneralInfo.pool')}
						textContent={t('projectDetails.projectGeneralInfo.yes')}
					/>
				)}
				{projectInfo.equipments?.seaView && (
					<BoxSmallComponent
						textTitle={t(
							'projectDetails.projectGeneralInfo.seaView',
						)}
						textContent={t('projectDetails.projectGeneralInfo.yes')}
					/>
				)}
			</Flex>

			<Divider my='30px' />

			<Flex gap='15px'>
				<BoxSmallComponent
					textTitle={t('projectDetails.projectGeneralInfo.postedBy')}
					textContent={projectInfo.postedBy ?? '-'}
					avatar
				>
					<Avatar
						size='md'
						src={'/assets/images/brand/isologo.png'}
						cursor='pointer'
						p={0}
					/>
				</BoxSmallComponent>
				<BoxSmallComponent
					textTitle={t(
						'projectDetails.projectGeneralInfo.propertiesType',
					)}
					textContent={projectInfo.propertyType ?? '-'}
					avatar
				>
					<Avatar size='md' />
				</BoxSmallComponent>
			</Flex>

			<Divider my='30px' />
		</Box>
	);
};

export default ProjectGeneralInfo;
