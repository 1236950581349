import { useTranslation } from 'react-i18next';
import {
	Button,
	Checkbox,
	Flex,
	FormLabel,
	Grid,
	HStack,
	Input,
	Link,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Stack,
	Text,
	useColorMode,
	useToast,
} from '@chakra-ui/react';
import {
	IconArrowDown,
	IconCheck,
	IconClose,
	IconEdit,
	IconPDF,
	IconTrash,
} from '../../../../assets/Icons';
import { useEffect, useState } from 'react';
import {
	documentLanguageOptions,
	projectStatus,
} from '../../../../data/optionsData';
import MenuSelectComponent from '../../../../components/MenuSelectComponent';
import { showToast } from '../../../../utils/Toasts';
import { PINATA_CDN } from '../../../../constants/constants';

const AddDocumentsComponent = ({
	handleAddDocuments,
	initialValues,
	currentValues,
	setPropertyInfo,
	incorrectData,
	deleteFromIconrrectData,
	status,
}) => {
	const { t } = useTranslation();
	const toast = useToast();
	const { colorMode } = useColorMode();
	const [uploadedDocuments, setUploadedDocuments] = useState(
		currentValues?.documents ?? [],
	);
	const [checkLegalDocument, setCheckLegalDocument] = useState(false);
	const initialDocuments = initialValues?.documents ?? [];
	const [deletedDocuments, setDeletedDocuments] = useState(
		currentValues?.deletedDocuments ?? [],
	);

	const [updatedDocuments, setUpdatedDocuments] = useState(
		currentValues?.updatedDocuments ?? [],
	);

	const [newDocument, setNewDocument] = useState();
	const selectedDocument = newDocument?.file;
	const [docEdit, setDocEdit] = useState();

	const allDocuments = initialDocuments
		.concat(updatedDocuments)
		.concat(uploadedDocuments)
		.map(item => {
			return {
				...item,
				fileName: item?.file?.name ?? item.fileName,
			};
		});

	useEffect(() => {
		const infoDocuments = uploadedDocuments.map(doc => {
			return {
				name: doc.file.name,
				language: doc.language,
				isLegalContract: doc.isLegalContract,
			};
		});

		handleAddDocuments({
			documents: uploadedDocuments,
			infoDocuments: infoDocuments ?? null,
			deletedDocuments: deletedDocuments ?? null,
			updatedDocuments: updatedDocuments ?? null,
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadedDocuments, deletedDocuments, updatedDocuments]);

	const showErrorToast = msg =>
		showToast(toast, 'Error al agregar el documento', msg);

	const handleDrop = event => {
		event.preventDefault();
		event.stopPropagation();
		const obj = { ...newDocument, file: event.dataTransfer?.files[0] };
		setNewDocument(obj);
		if (incorrectData.includes('infoDocuments')) {
			deleteFromIconrrectData('infoDocuments');
		}
	};

	const handleDragOver = event => {
		event.preventDefault();
		event.stopPropagation();

		if (incorrectData.includes('infoDocuments')) {
			deleteFromIconrrectData('infoDocuments');
		}
	};

	const handleClick = () => {
		document.getElementById('documentInput').click();

		if (incorrectData.includes('infoDocuments')) {
			deleteFromIconrrectData('infoDocuments');
		}
	};

	const onUpdateLanguage = (language, index, doc) => {
		if (doc?.documentId) {
			const checkIfUpdated = updatedDocuments.find(
				item => item?.documentId === doc.documentId,
			);

			if (checkIfUpdated) {
				const filteredUpdatedDocuments = updatedDocuments.filter(
					item => item.documentId !== doc.documentId,
				);
				setUpdatedDocuments(
					filteredUpdatedDocuments.concat({ ...doc, language }),
				);
			} else {
				setUpdatedDocuments(
					updatedDocuments.concat({ ...doc, language }),
				);
				const filteredInitialValues = initialValues?.documents.filter(
					item => item.documentId !== doc.documentId,
				);
				setPropertyInfo({
					...initialValues,
					documents: filteredInitialValues,
				});
			}
		} else {
			const updatedList = [...uploadedDocuments];
			updatedList[index].language = language;
			setUploadedDocuments(updatedList);
		}
	};

	const onSelectNewLanguage = language => {
		const obj = { ...newDocument, ...language };
		setNewDocument(obj);
	};

	const onUpdateIsLegalContract = (index, doc) => {
		if (doc?.documentId) {
			const checkIfUpdated = updatedDocuments.find(
				item => item?.documentId === doc.documentId,
			);

			if (checkIfUpdated) {
				const filteredUpdatedDocuments = updatedDocuments.filter(
					item => item.documentId !== doc.documentId,
				);
				setUpdatedDocuments(
					filteredUpdatedDocuments.concat({
						...doc,
						isLegalContract: !doc.isLegalContract,
					}),
				);
			} else {
				setUpdatedDocuments(
					updatedDocuments.concat({
						...doc,
						isLegalContract: !doc.isLegalContract,
					}),
				);
				const filteredInitialValues = initialValues?.documents.filter(
					item => item.documentId !== doc.documentId,
				);
				setPropertyInfo({
					...initialValues,
					documents: filteredInitialValues,
				});
			}
		} else {
			const updatedList = [...uploadedDocuments];
			updatedList[index].isLegalContract = !doc.isLegalContract;
			setUploadedDocuments(updatedList);
		}
	};

	const addNewDocument = event => {
		const files = Array.from(event.target.files);
		const obj = { ...newDocument, file: files[0] };
		setNewDocument(obj);

		if (incorrectData.includes('infoDocuments')) {
			deleteFromIconrrectData('infoDocuments');
		}
	};

	const checkDocAlreadyExists = doc => {
		if (!doc.language) return 'It is mandatory to select a language';
		if (allDocuments.some(item => item.fileName === doc.file.name))
			return 'The document already exists';
		if (
			allDocuments.some(
				item => item.isLegalContract && doc.isLegalContract,
			)
		)
			return 'A legal document already exists';

		return false;
	};

	const onAddDocument = () => {
		const documents = {
			...newDocument,
			isLegalContract: checkLegalDocument,
		};

		const checkValid = checkDocAlreadyExists(documents);
		if (checkValid) {
			showErrorToast(checkValid);
		} else {
			setUploadedDocuments(uploadedDocuments.concat(documents));
			setNewDocument(null);
			setCheckLegalDocument(false);
		}
	};

	const onDeleteDocument = _file => {
		if (_file?.documentId) {
			const filteredInitialValues = initialValues?.documents.filter(
				item => item.documentId !== _file.documentId,
			);
			setPropertyInfo({
				...initialValues,
				documents: filteredInitialValues,
			});
			const deletedList = deletedDocuments.concat(_file);
			setDeletedDocuments(deletedList);
		} else {
			const newDeletedDocs = uploadedDocuments.filter(
				item => item?.file.name !== _file?.file.name,
			);

			setUploadedDocuments(newDeletedDocs);
		}
	};

	const getDocumentUrl = doc => {
		if (doc?.documentId) {
			return `https://${PINATA_CDN}/ipfs/${doc?.cid}`;
		} else {
			return URL.createObjectURL(doc.file);
		}
	};

	return (
		<Grid templateColumns='repeat(12, 1fr)' gap='10px'>
			<Stack gridColumn={selectedDocument ? 'span 8' : 'span 12'}>
				<FormLabel variant='propertyLabel' m='0'>
					Attach documents
				</FormLabel>
				<Flex
					h='126px'
					bg='transparencyWhite.100'
					borderColor={
						colorMode === 'dark' ? 'brand.200' : 'brand.500'
					}
					borderRadius='12px'
					alignItems='center'
					px='30px'
					gap='10px'
					onDrop={handleDrop}
					onDragOver={handleDragOver}
					onClick={handleClick}
					cursor='pointer'
					outline={
						incorrectData.includes('infoDocuments')
							? '1px solid red'
							: 'none'
					}
				>
					<Input
						id='documentInput'
						type='file'
						multiple
						accept='.pdf,.doc,.docx,.xlsx,.txt'
						style={{ display: 'none' }}
						onChange={addNewDocument}
					/>
					<IconPDF />
					{selectedDocument ? (
						<Text fontSize='12px' fontWeight='400'>
							{selectedDocument.name}
						</Text>
					) : (
						<Text variant='gray' fontSize='12px' fontWeight='400'>
							Drop documents or click to upload
						</Text>
					)}
				</Flex>
				{incorrectData.includes('infoDocuments') && (
					<Text color={'red'} fontSize='12px' fontWeight='400'>
						* It is mandatory to upload the legal contract
					</Text>
				)}
			</Stack>
			{selectedDocument && (
				<Stack gridColumn='span 4' gap={3}>
					<FormLabel variant='propertyLabel' m='0'>
						Language
					</FormLabel>
					<MenuSelectComponent
						options={documentLanguageOptions}
						fieldName='language'
						selectedOption={newDocument.language}
						setTabInfo={onSelectNewLanguage}
					/>

					<Checkbox
						name={'isLegalContract'}
						value={false}
						onChange={e => setCheckLegalDocument(e.target.checked)}
					>
						<HStack spacing={1}>
							<Text fontSize='12px' fontWeight='400'>
								{t(
									`addProperty.adminPanel.financials.legalContract`,
								)}
							</Text>
							<Text
								color={'red'}
								fontSize='12px'
								fontWeight='400'
							>
								*
							</Text>
						</HStack>
					</Checkbox>

					<Button
						disabled={!newDocument?.language}
						variant='main'
						onClick={onAddDocument}
					>
						Add
					</Button>
				</Stack>
			)}
			{allDocuments.map((doc, index) => (
				<Flex
					gridColumn='span 12'
					key={index}
					h='42px'
					bg='transparencyWhite.100'
					border='1px solid'
					borderColor='transparencyWhite.100'
					borderRadius='12px'
					justifyContent='space-between'
					alignItems='center'
					px='20px'
				>
					<HStack>
						<Link href={getDocumentUrl(doc)} isExternal>
							<Flex gap='10px' align='center'>
								<IconPDF />
								<Text fontSize='12px' fontWeight='400'>
									{doc.fileName}
								</Text>
							</Flex>
						</Link>
						{doc?.isLegalContract && (
							<HStack
								py={1}
								px={2}
								rounded={5}
								bg={
									doc.fileName === docEdit?.fileName
										? 'transparencyWhite.200'
										: 'none'
								}
							>
								<Text
									fontSize='10px'
									color={
										doc.fileName === docEdit?.fileName
											? 'white'
											: 'transparencyWhite.300'
									}
								>
									{t(
										`addProperty.adminPanel.financials.legalContractTag`,
									)}
								</Text>
								{doc.fileName === docEdit?.fileName && (
									<IconClose
										boxSize={'10px'}
										cursor={'pointer'}
										_hover={{
											transform: 'scale(1.1)',
											transitionDuration: '0.3s',
										}}
										onClick={() =>
											onUpdateIsLegalContract(index, doc)
										}
									/>
								)}
							</HStack>
						)}
					</HStack>
					<Flex align='center' gap='10px'>
						{doc.fileName !== docEdit?.fileName ? (
							<Text fontSize='14px'>
								{t(
									`addProperty.adminPanel.financials.${doc.language}`,
								)}
							</Text>
						) : (
							<Menu>
								<MenuButton
									h='42px'
									fontWeight='300'
									borderRadius='5px'
									textAlign='start'
									bg='none'
									mr='-10px'
									_hover={{
										bg: 'none',
									}}
									_active={{
										bg: 'none',
									}}
									rightIcon={<IconArrowDown boxSize='12px' />}
									as={Button}
								>
									<Text fontSize='14px' fontWeight='400'>
										{t(
											`addProperty.adminPanel.financials.${doc.language}`,
										)}
									</Text>
								</MenuButton>
								<MenuList fontSize='12px' zIndex={10}>
									{documentLanguageOptions.map((elem, i) => (
										<MenuItem
											key={i}
											value={elem}
											onClick={() =>
												onUpdateLanguage(
													elem,
													index,
													doc,
												)
											}
										>
											{t(
												`addProperty.adminPanel.financials.${elem}`,
											)}
										</MenuItem>
									))}
								</MenuList>
							</Menu>
						)}
						{doc.fileName !== docEdit?.fileName ? (
							<IconEdit
								color={
									status === projectStatus.Published &&
									doc?.isLegalContract
										? 'transparencyWhite.300'
										: 'white'
								}
								cursor={
									status === projectStatus.Published &&
									doc?.isLegalContract
										? 'not-allowed'
										: 'pointer'
								}
								onClick={() => {
									status !== projectStatus.Published &&
										setDocEdit(doc);
								}}
							/>
						) : (
							<IconCheck
								cursor='pointer'
								color={
									colorMode === 'dark'
										? 'brand.200'
										: 'brand.500'
								}
								onClick={() => setDocEdit(undefined)}
							/>
						)}
						<IconTrash
							color={
								status === projectStatus.Published &&
								doc?.isLegalContract
									? 'transparencyWhite.300'
									: 'white'
							}
							cursor={
								status === projectStatus.Published &&
								doc?.isLegalContract
									? 'not-allowed'
									: 'pointer'
							}
							isDisabled={
								status === projectStatus.Published &&
								doc?.isLegalContract
							}
							onClick={() => {
								status !== projectStatus.Published &&
									onDeleteDocument(doc);
							}}
						/>
					</Flex>
				</Flex>
			))}
		</Grid>
	);
};
export default AddDocumentsComponent;
