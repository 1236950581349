import { AbsoluteCenter, Box } from '@chakra-ui/react';
import PadLock from '../assets/Icons/components/PadLock';

const BlurComponent = ({
	children,
	blurAmount = '4px',
	type = 'component',
}) => {
	return (
		<>
			{type === 'component' ? (
				<Box position='relative' w={'100%'} h={'100%'}>
					<AbsoluteCenter zIndex={2}>
						<PadLock boxSize='50px' />
					</AbsoluteCenter>
					<Box w={'100%'} h={'100%'} filter={`blur(${blurAmount})`}>
						{children}
					</Box>
				</Box>
			) : (
				<Box w={'100%'} h={'100%'} filter={`blur(${blurAmount})`}>
					{children}
				</Box>
			)}
		</>
	);
};

export default BlurComponent;
