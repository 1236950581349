import GridCenterContainer from '../../../components/GridCenterContainer';
import BoxComponent from '../../../components/BoxComponent';
import {
	Flex,
	Grid,
	Text,
	Stack,
	useMediaQuery,
	Button,
} from '@chakra-ui/react';
import InputComponent from '../../../components/InputComponent';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import AvatarChangeComponent from '../../../components/AvatarChangeComponent';
import { useState } from 'react';
import {
	validateImage,
	validateRegistrationInfo,
} from '../../../utils/validations/authValidations';
import { signUp } from '../../../services/userService';
import { PublicRoutes } from '../../../routes/Routes';
import { useTranslation } from 'react-i18next';

/**
*
    @name RegisterComponent
    @description The component for the register form. It has the following validations:
					- Inputs can't be empty
					- Password must have 8 characters at least
					- Email has to include an "@"
				It also has the option to add a avatarImage to the registration.
    @returns {JS.Element} RegisterComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const initialState = {
	fullname: '',
	username: '',
	email: '',
	password: '',
	passConfirm: '',
	avatarImage: '',
};

const RegisterComponent = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isMobile] = useMediaQuery('(max-width: 900px)');
	const [userInfo, setUserInfo] = useState(initialState);
	const [avatarImage, setAvatarImage] = useState();
	const [error, setError] = useState();

	// Function that change the user state for the new information given in the inputs
	const handleChange = event => {
		const { name, value } = event.target;
		setUserInfo({ ...userInfo, [name]: value });
	};

	// Function that validate the information in the inputs and register the user
	const handleOnSubmit = async () => {
		const obj = { ...userInfo, avatarImage };
		const formErrors = validateRegistrationInfo(obj);
		if (formErrors) setError(formErrors);
		if (!formErrors) {
			setError(undefined);
			const { success, data, message } = await signUp(obj);
			if (success) {
				navigate(`/${PublicRoutes.VerifyAccount}`, {
					state: {
						userInfo: data,
						type: 'verify',
					},
				});
			} else {
				setError({
					message,
				});
			}
		}
	};

	// 	Function that change the avatarImage of the user status
	const handleChangePhoto = event => {
		const image = event.target.files[0];
		const imageError = validateImage(image);

		if (imageError) {
			setUserInfo({ ...userInfo, avatarImage: '' });
			setError(imageError);
		} else {
			setAvatarImage(image);
			setUserInfo({ ...userInfo, avatarImage: image });
			error?.field === 'image' && setError(undefined);
		}
	};

	return (
		<GridCenterContainer w='100%'>
			<BoxComponent
				headerMsg={t('register.headerMsg')}
				explainMsg={t('register.explainMsg')}
				w={isMobile ? '100%' : '553px'}
				containerGap='25px'
			>
				<Grid
					gap='15px'
					gridTemplateColumns={
						isMobile ? '1fr' : 'repeat(auto-fit, 249px)'
					}
				>
					<Flex
						gridColumn={isMobile ? null : 'span 2'}
						justifyContent='center'
						alignItems='center'
						gap='10px'
					>
						<AvatarChangeComponent
							avatarImage={userInfo.avatarImage}
							onChange={handleChangePhoto}
							errorImage={
								error?.field === 'image' && error.message
							}
						/>

						<Text
							fontSize='11px'
							fontWeight='600'
							textDecor='underline'
						>
							{t('register.addImage')}
						</Text>
					</Flex>
					<InputComponent
						label={t('register.inputFullName.label')}
						placeholder={t('register.inputFullName.placeholder')}
						value={userInfo.fullname}
						onChange={handleChange}
						name={'fullname'}
						errorMsg={error?.field === 'fullname' && error.message}
						type={'text'}
					/>

					<InputComponent
						label={t('register.inputUsername.label')}
						placeholder={t('register.inputUsername.placeholder')}
						value={userInfo.username}
						onChange={handleChange}
						name={'username'}
						errorMsg={error?.field === 'username' && error.message}
						type={'text'}
					/>

					<InputComponent
						label={t('register.inputEmail.label')}
						placeholder={t('register.inputEmail.placeholder')}
						value={userInfo.email}
						onChange={handleChange}
						name={'email'}
						errorMsg={error?.field === 'email' && error.message}
						gridColumn={isMobile ? null : 'span 2'}
						type={'text'}
					/>

					<InputComponent
						label={t('register.inputPassword.label')}
						placeholder={t('register.inputPassword.placeholder')}
						value={userInfo.password}
						onChange={handleChange}
						name='password'
						errorMsg={error?.field === 'password' && error.message}
						type={'password'}
						enterAction={
							userInfo.passConfirm ? handleOnSubmit : undefined
						}
					/>

					<InputComponent
						label={t('register.inputConfirmPassword.label')}
						placeholder={t(
							'register.inputConfirmPassword.placeholder',
						)}
						value={userInfo.passConfirm}
						onChange={handleChange}
						name='passConfirm'
						errorMsg={error?.field === 'password' && error.message}
						type={'password'}
						enterAction={handleOnSubmit}
					/>

					<Stack
						w='100%'
						align={'center'}
						mt='15px'
						spacing={2}
						gridColumn={isMobile ? null : 'span 2'}
					>
						<Button
							w={isMobile ? '100%' : '285px'}
							variant='main'
							onClick={handleOnSubmit}
						>
							{t('register.signUp')}
						</Button>
						<Flex
							fontSize='12'
							fontWeight='700'
							justifyContent='center'
							gap='3px'
						>
							<Text
								textAlign='center'
								fontSize='12px'
								fontWeight='400'
							>
								{t('register.signIn')}
							</Text>
							<Link as={NavLink} to='/login'>
								{t('register.singInLink')}
							</Link>
						</Flex>
					</Stack>
				</Grid>
				{error && (
					<Text
						fontWeight='700'
						color='negative'
						textAlign='center'
						pt='25px'
					>
						{error.message}
					</Text>
				)}
			</BoxComponent>
		</GridCenterContainer>
	);
};

export default RegisterComponent;
