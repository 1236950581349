import {
	Divider,
	Flex,
	Grid,
	GridItem,
	Spinner,
	Stack,
	Text,
	useColorMode,
	useTheme,
} from '@chakra-ui/react';

import PublishedInfoComponent from './PublishedInfoComponent';
import { useEffect, useState } from 'react';
import {
	deleteProject,
	getAllProjects,
} from '../../../services/propertyService';
import { useTranslation } from 'react-i18next';
import PaginationComponent from '../../../components/PaginationComponent';
import { projectStatus } from '../../../data/optionsData';

const PublishedPropertiesComponent = ({ isMobile }) => {
	const theme = useTheme();
	const { colorMode } = useColorMode();
	const [projectsInfo, setProjectsInfo] = useState();
	const [page, setPage] = useState(1);
	const [loadingAction, setLoadingAction] = useState(false);
	const { t } = useTranslation();

	const colorBorderTablet =
		colorMode === 'dark'
			? theme.colors.transparencyWhite[100]
			: theme.colors.transparencyBlack[100];
	const colorBorderDesktop =
		colorMode === 'dark'
			? theme.colors.transparencyWhite[100]
			: theme.colors.transparencyBlack[200];

	useEffect(() => {
		const getProjectsInfo = async () => {
			const { data } = await getAllProjects({}, 5, page, 'admin');
			setProjectsInfo(data);
		};
		getProjectsInfo();
	}, [page]);

	const handleDeleteProject = async selectedProjectId => {
		setLoadingAction(true);
		const { data } = await deleteProject(selectedProjectId);
		setProjectsInfo(data);
		setLoadingAction(false);
	};

	const onPublishProject = data => {
		const index = projectsInfo.rows.findIndex(
			item => item.projectId === data.projectId,
		);
		const _projects = [...projectsInfo.rows];
		_projects[index] = { ..._projects[index], ...data };
		setProjectsInfo({ count: projectsInfo.count, rows: _projects });
	};

	const onWithdrawProject = metadataId => {
		const index = projectsInfo.rows.findIndex(
			item => item.metadataId === metadataId,
		);
		const _projects = [...projectsInfo.rows];
		_projects[index] = { ..._projects[index], isWithdrawn: true };
		setProjectsInfo({ count: projectsInfo.count, rows: _projects });
	};

	const onRefundProject = metadataId => {
		const index = projectsInfo.rows.findIndex(
			item => item.metadataId === metadataId,
		);
		const _projects = [...projectsInfo.rows];
		_projects[index] = {
			..._projects[index],
			status: projectStatus.Refund,
		};
		setProjectsInfo({ count: projectsInfo.count, rows: _projects });
	};

	return (
		<Stack w='100%' h='100%' p='15px' gap='11px'>
			<Text
				fontWeight='700'
				fontSize='16'
				lineHeight='100%'
				letterSpacing={0.02}
			>
				{t('adminPage.pubProperties.title')}
			</Text>

			<Flex
				flexDirection='column'
				justifyContent='center'
				h='100%'
				gap='5px'
			>
				<Grid
					templateColumns='repeat(4, 1fr)'
					placeItems='center'
					textAlign='center'
					fontSize={isMobile ? '12px' : '14px'}
					border={isMobile ? '2px solid' : 'none'}
					borderColor={colorBorderTablet}
					borderRadius={isMobile ? '12px' : '0'}
					rowGap={isMobile ? '0' : '0'}
				>
					<GridItem
						borderRight={
							isMobile ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isMobile ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isMobile ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							wordBreak='break-all'
							variant='colored'
							mb={isMobile ? '0px' : '6px'}
						>
							{t('adminPage.pubProperties.name')}
						</Text>
					</GridItem>
					<GridItem
						borderRight={
							isMobile ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isMobile ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isMobile ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isMobile ? '0px' : '6px'}
							wordBreak='break-all'
						>
							{t('adminPage.pubProperties.price')}
						</Text>
					</GridItem>
					<GridItem
						borderRight={
							isMobile ? `2px solid ${colorBorderTablet}` : 'none'
						}
						px='5px'
						py={isMobile ? '12px' : '5px'}
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isMobile ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isMobile ? '0px' : '6px'}
							wordBreak='break-all'
						>
							{t('adminPage.pubProperties.status')}
						</Text>
					</GridItem>

					<GridItem
						py={isMobile ? '12px' : '5px'}
						px='5px'
						w='100%'
						h='100%'
					>
						<Text
							fontSize={isMobile ? '12px' : '14px'}
							fontWeight='700'
							lineHeight='100%'
							variant='colored'
							mb={isMobile ? '0px' : '6px'}
							wordBreak='break-all'
						>
							{t('adminPage.pubProperties.action')}
						</Text>
					</GridItem>
					<Divider
						gridColumn={isMobile ? 'span 5' : 'span 6'}
						borderWidth='1px'
						borderColor={colorBorderDesktop}
					/>
					{!projectsInfo?.rows ? (
						<GridItem
							pt={5}
							gridColumn={isMobile ? 'span 4' : 'span 6'}
						>
							<Spinner
								thickness='4px'
								speed='0.65s'
								emptyColor='gray.200'
								color='brand.200'
								size='xl'
							/>
						</GridItem>
					) : (
						projectsInfo?.rows.map((property, index) => (
							<PublishedInfoComponent
								key={index}
								data={property}
								last={index === projectsInfo.rows.length - 1}
								isMobile={isMobile}
								colorBorder={colorBorderDesktop}
								handleDeleteProject={() =>
									handleDeleteProject(property.projectId)
								}
								loadingAction={loadingAction}
								onPublishProject={onPublishProject}
								onWithdrawProject={onWithdrawProject}
								onRefundProject={onRefundProject}
							/>
						))
					)}
				</Grid>
			</Flex>
			<PaginationComponent
				totalProjects={projectsInfo?.count}
				page={page}
				setPage={setPage}
				pageSize={5}
			/>
		</Stack>
	);
};

export default PublishedPropertiesComponent;
