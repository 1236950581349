import { Stack, Text } from '@chakra-ui/react';

/**
*
    @name InfoDashboardComponent
    @description Component that shows the styled numbers for the rest of components.
	@returns {JSX.Element} InfoDashboardComponent.js
    @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const InfoDashboardComponent = ({ header, info, blur = false }) => {
	return (
		<Stack gap='1px' textAlign='center' letterSpacing={-0.02}>
			<Text variant='gray' fontSize='12px'>
				{header}
			</Text>

			<Text
				fontSize='14px'
				fontWeight='500'
				filter={blur ? 'blur(4px)' : 'blur(0px)'}
			>
				{info}
			</Text>
		</Stack>
	);
};

export default InfoDashboardComponent;
