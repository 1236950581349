import {
	Button,
	Card,
	CardBody,
	Flex,
	Image,
	Input,
	Skeleton,
	Stack,
	Text,
	useColorMode,
	useToast,
} from '@chakra-ui/react';
import { IconSend } from '../assets/Icons';
import SuccessfulComponent from './SuccessfulComponent';
import FeeComponent from './FeeComponent';
import InfoPropertyCard from './ProjectCardComponent/InfoPropertyCard';
import { propertyPercentage } from '../utils/utils';
import { AWS_S3 } from '../constants/constants';
import CrossBoxComponent from './CrossBoxComponent';
import ModalComponent from './ModalComponent';
import { showToast } from '../utils/Toasts';
import { isAddress } from 'ethers';
import { useEffect, useState } from 'react';
import useProject from '../hooks/useProject';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useTokenTransfer from '../hooks/useTokenTransfer';
import { updateUser } from '../redux/states/user';

const SendProjectTokensComponent = ({
	isOpenSendModal,
	closeSendModal,
	isMobile,
	project,
}) => {
	const { t } = useTranslation();
	const toast = useToast();
	const { colorMode } = useColorMode();
	const walletAddress = useSelector(store => store.user.walletAddress);
	const user = useSelector(store => store.user);
	const dispatch = useDispatch();
	const { balanceOf } = useProject();
	const [projectBalance, setProjectBalance] = useState();

	const [amount, setAmount] = useState(null);
	const [addressToSend, setAddressToSend] = useState(null);
	const [sendTransactionSuccess, setSendTransactionSuccess] = useState(false);
	const [amountError, setAmountError] = useState(null);
	const [addressError, setAddressError] = useState(null);
	const [isPendingTransaction, setIsPendingTransaction] = useState(false);
	const [txHash, setTxHash] = useState(null);
	const { transferProjectTokens } = useTokenTransfer();

	useEffect(() => {
		if (walletAddress) {
			const getProjectTokens = async () => {
				const _balance = await balanceOf(
					walletAddress,
					project.metadataId,
				);

				setProjectBalance(_balance);
			};
			getProjectTokens();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [balanceOf, walletAddress]);

	const handleCloseSendModal = () => {
		closeSendModal();
		setSendTransactionSuccess(false);
	};

	const checkErrors = () => {
		let checked = {
			success: true,
			message: 'Transaction is valid to be sent.',
		};
		if (Number(amount) <= 0) {
			console.error('The amount should be greater than 0');
			checked = {
				success: false,
				message: 'Introduce a value',
				type: 'amount',
			};
		} else if (addressToSend === '' || addressToSend === null) {
			checked = {
				success: false,
				message: 'Address can not be empty',
				type: 'address',
			};
		} else if (!isAddress(addressToSend)) {
			checked = {
				success: false,
				message: 'Invalid address',
				type: 'address',
			};
		}
		return checked;
	};

	const onHandleAmount = event => {
		const value = event.target.value;

		const amount = parseFloat(value);

		if (amount > projectBalance) {
			setAmountError('Insufficient balance');
		} else {
			setAmountError(null);
		}

		setAmount(value);
	};

	const handleSetMaxAmount = async () => {
		setAmount(projectBalance);
	};

	const onChangeAddress = event => {
		if (addressError) {
			setAddressError(null);
		}
		setAddressToSend(event.target.value);
	};

	const updateProjects = async () => {
		const _projects = [...user.projects];
		const index = _projects.findIndex(
			proj => proj.project.metadataId === project.metadataId,
		);
		if (index !== -1) {
			const updatedProject = {
				..._projects[index],
				project: {
					..._projects[index].project,
					mintedSupply:
						_projects[index].project.mintedSupply - amount,
				},
			};

			_projects[index] = updatedProject;

			dispatch(updateUser({ projects: _projects }));
		}
	};

	const handleSendTokens = async () => {
		const error = checkErrors();
		if (!error.success) {
			if (error.type === 'amount') {
				setAmountError(error.message);
			} else {
				setAddressError(error.message);
			}
		}

		if (error.success) {
			setIsPendingTransaction(true);

			const transferTx = await transferProjectTokens({
				from: walletAddress,
				to: addressToSend,
				metadataId: project.metadataId,
				amount,
			});

			if (transferTx.success) {
				setTxHash(transferTx.receipt?.hash);
				updateProjects();
				setSendTransactionSuccess(true);
				showToast(
					toast,
					'Success',
					'Transaction completed successfully',
				);
			}

			setIsPendingTransaction(false);
		}
	};

	return (
		<ModalComponent
			isOpen={isOpenSendModal}
			onClose={closeSendModal}
			modalCross={false}
		>
			{!sendTransactionSuccess ? (
				<>
					<Flex w='100%' justify='end'>
						<CrossBoxComponent closeModal={closeSendModal} />
					</Flex>
					<Stack
						bg={
							colorMode === 'dark'
								? 'transparencyWhite.100'
								: 'transparencyWhite.800'
						}
						border='0.5px solid'
						borderColor='transparencyBlack.200'
						borderRadius='12px'
						p='20px'
						w={isMobile ? 'fit-content' : '352px'}
						gap='20px'
					>
						<Text
							fontSize='20px'
							fontWeight='700'
							letterSpacing={-0.02}
							textAlign='center'
							color={
								colorMode === 'dark' ? 'brand.200' : 'brand.500'
							}
						>
							{t('walletPage.sendTokens')}
						</Text>
						<Card borderRadius='12px'>
							<CardBody p='0' w='100%'>
								<Flex
									position='relative'
									overflow='hidden'
									borderTopRadius='12px'
									alignItems='center'
									justifyContent='center'
								>
									<Image
										src={`${AWS_S3}/projects/${project?.metadataId}/gallery/${project?.featureImage}`}
										h='250px'
										w='312px'
									/>
								</Flex>

								<InfoPropertyCard
									project={project}
									percentage={propertyPercentage(project)}
								/>
							</CardBody>
						</Card>
						<Flex w='100%' gap='12px' flexDir='column'>
							<Text
								fontSize='14px'
								fontWeight='700'
								letterSpacing={-0.02}
							>
								{t('dashboard.numberOfTokensToBeSent')}
							</Text>
							{projectBalance ? (
								<Flex
									w='100%'
									h='52px'
									bg='transparencyWhite.100'
									align='center'
									borderRadius='5px'
									p='10px'
									boxShadow={
										amountError
											? '0px 0px 0px 1px red'
											: 'none'
									}
									flexDir='row'
								>
									<Stack
										spacing={0}
										pe='5px'
										h='40px'
										flex='1'
										justifyContent='center'
									>
										<Input
											variant='noStyled'
											placeholder='Value'
											p={0}
											onChange={onHandleAmount}
											value={amount}
											type='number'
										/>
									</Stack>
									<Button
										maxH='32px'
										variant='main'
										isDisabled={!projectBalance}
									>
										<Text
											variant='inverse'
											fontSize='12px'
											fontWeight='700'
											lineHeight='20px'
											onClick={handleSetMaxAmount}
										>
											Max
										</Text>
									</Button>
								</Flex>
							) : (
								<Skeleton h='20px' />
							)}
							{amountError ? (
								<Text variant='error' fontSize='11px'>
									{amountError}
								</Text>
							) : null}
						</Flex>
						<Stack>
							<Text
								fontSize='14px'
								fontWeight='700'
								lineHeight='20px'
							>
								{t('walletPage.addressToSend')}
							</Text>
							{projectBalance ? (
								<Input
									variant={
										addressError ? 'error' : 'withBorder'
									}
									fontSize='12px'
									placeholder={t('walletPage.placeHolder')}
									p='10px'
									onChange={onChangeAddress}
								/>
							) : (
								<Skeleton h='20px' w='100%' />
							)}
							{addressError ? (
								<Text variant='error' fontSize='11px'>
									{addressError}
								</Text>
							) : null}
						</Stack>
						<Text
							fontSize='10px'
							lineHeight='15px'
							fontWeight='400'
							mt='-10px'
						>
							<Text
								as='span'
								variant='colored'
								fontSize='10px'
								lineHeight='15px'
							>
								{t('walletPage.disclaimerTitle')}
							</Text>
							{t('walletPage.disclaimerContent')}
						</Text>
						<FeeComponent />

						<Button
							variant='main'
							alignSelf='center'
							gap='5px'
							onClick={handleSendTokens}
							isLoading={isPendingTransaction}
							isDisabled={
								amountError || addressError || !projectBalance
							}
						>
							<IconSend boxSize='14px' />
							{t('dashboard.sendProjectTokens')}
						</Button>
					</Stack>
				</>
			) : (
				<SuccessfulComponent
					w='312px'
					p='20px'
					handleClick={handleCloseSendModal}
					buttonTitle={'Close'}
					text={t(
						'projectDetails.offerDetails.succesful.transactionSuccess',
					)}
					txHash={txHash}
				/>
			)}
		</ModalComponent>
	);
};

export default SendProjectTokensComponent;
