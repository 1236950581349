import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	Box,
	useColorModeValue,
	useTheme,
	Text,
	Flex,
} from '@chakra-ui/react';

import { useState } from 'react';
import { IconArrowDown } from '../../../../../assets/Icons';

const FaqsComponent = ({ faqs }) => {
	const theme = useTheme();
	const primaryColors = useColorModeValue(
		theme.colors.brand[500],
		theme.colors.brand[200],
	);

	const backgroundButton = useColorModeValue(
		theme.colors.transparencyWhite[800],
		theme.colors.transparencyWhite[100],
	);

	const [expandedIndex, setExpandedIndex] = useState(null);

	const handleToggle = index => {
		setExpandedIndex(expandedIndex === index ? null : index);
	};

	return (
		<Accordion
			allowToggle
			index={expandedIndex}
			onChange={index => handleToggle(index)}
		>
			{faqs.map((faq, index) => (
				<AccordionItem
					key={index}
					onClick={() => handleToggle(index)}
					border={
						expandedIndex === index
							? `2px solid ${primaryColors}`
							: `1px solid ${theme.colors.transparencyBlack[200]}`
					}
				>
					<>
						<>
							<AccordionButton
								pt='20px'
								pb={expandedIndex === index ? '0' : '20px'}
							>
								<Box
									as='span'
									flex='1'
									textAlign='left'
									fontWeight='medium'
								>
									{faq.question}
								</Box>

								<Flex
									bg={
										expandedIndex === index
											? primaryColors
											: backgroundButton
									}
									w='50px'
									h='50px'
									borderRadius='50%'
									align='center'
									justify='center'
								>
									<IconArrowDown
										transition='transform 0.5s'
										transform={
											expandedIndex === index
												? 'rotate(0deg)'
												: 'rotate(-90deg)'
										}
										boxSize='20px'
										color={
											expandedIndex === index && 'white'
										}
									/>
								</Flex>
							</AccordionButton>
						</>
						<AccordionPanel pb='15px'>
							<Text
								fontSize='16px'
								fontWeight='400'
								lineHeight='30px'
							>
								{faq.answer}
							</Text>
						</AccordionPanel>
					</>
				</AccordionItem>
			))}
		</Accordion>
	);
};

export default FaqsComponent;
