import { AspectRatio, Flex } from '@chakra-ui/react';
import { IconClose } from '../../../../assets/Icons';

const MediaComponent = ({ media, onRemove }) => {
	return (
		<Flex
			position='relative'
			boxSize='100px'
			borderRadius='5px'
			backgroundImage={`url(${media.url})`}
			backgroundSize='cover'
			backgroundPosition='center'
			backgroundRepeat='no-repeat'
			justify='end'
		>
			{media?.url?.endsWith('.mp4') && (
				<AspectRatio ratio={1} w='100px' h='100%'>
					<video
						title={`video`}
						style={{
							margin: '0px',
							padding: '0px',
							border: 'none',
							objectFit: 'cover',
							borderRadius: '3px',
						}}
						src={media.url}
						autoPlay
						loop
						muted
					/>
				</AspectRatio>
			)}
			<Flex
				position='absolute'
				top='5px'
				right='5px'
				backgroundColor='transparencyWhite.800'
				boxSize='15px'
				borderRadius='50%'
				align='center'
				justify='center'
				cursor='pointer'
				onClick={onRemove}
				pl='1px'
			>
				<IconClose boxSize='10px' color='transparencyBlack.800' />
			</Flex>
		</Flex>
	);
};

export default MediaComponent;
