/* eslint-disable react-hooks/exhaustive-deps */
import { Box, useColorMode } from '@chakra-ui/react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { GOOGLE_MAPS_API_KEY } from '../../constants/constants';
import { colors } from '../../theme/Theme';

const GoogleAutocomplete2 = ({
	onSelectAddress,
	incorrectData,
	addressValue,
	placeholder,
}) => {
	const defaultValue = addressValue?.label ?? addressValue;
	const { colorMode } = useColorMode();

	const bgColor =
		colorMode === 'dark'
			? colors.transparencyWhite[100]
			: 'rgba(0, 0, 0, 0.05)';

	const focusBorderColor =
		colorMode === 'dark' ? colors.brand[200] : colors.brand[500];

	const inputColor =
		colorMode === 'dark' ? 'white' : colors.transparencyBlack[900];

	const hoverOptionBgColor =
		colorMode === 'dark'
			? colors.transparencyBlack[200]
			: colors.transparencyWhite[200];

	return (
		<Box position='relative'>
			<GooglePlacesAutocomplete
				apiKey={GOOGLE_MAPS_API_KEY}
				debounce={1500}
				minLengthAutocomplete={5}
				onLoadFailed={error =>
					console.error('Could not inject Google script', error)
				}
				selectProps={{
					isClearable: true,
					defaultInputValue: defaultValue,
					value: addressValue,
					onChange: onSelectAddress,
					placeholder,
					styles: {
						control: (baseStyles, state) => ({
							...baseStyles,
							border: incorrectData || (state.isFocused ? 0 : 0),
							// This line disable the blue border
							boxShadow: state.isFocused ? 0 : 0,
							'&:hover': {
								border: state.isFocused ? 0 : 0,
							},
							background: bgColor,
							fontSize: '13px',
							letterSpacing: -0.02,
							_placeholder: {
								fontSize: '13px',
							},
						}),
						menu: provided => ({
							...provided,
							background: bgColor,
							border: focusBorderColor,
							backdropFilter: 'blur(50px)',
							fontSize: '13px',
							letterSpacing: -0.02,
						}),
						input: provided => ({
							...provided,
							color: inputColor,
						}),
						option: (provided, state) => ({
							...provided,
							backgroundColor: 'none',
							'&:hover': {
								background: hoverOptionBgColor,
							},
						}),
						singleValue: provided => ({
							...provided,
							color: inputColor,
						}),
						indicatorsContainer: (prevStyle, state) => ({
							...prevStyle,
							display: 'none',
						}),
					},
				}}
			/>
		</Box>
	);
};

export default GoogleAutocomplete2;
