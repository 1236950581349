import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { IconCheck2, IconClose } from '../assets/Icons';

/**
 *
 * @name showToast
 * @description This function displays a custom toast notification. It shows an error message with a progress bar that animates over 5 seconds. The notification contains an icon, title, message, and a progress bar to indicate the duration.
 * @returns {void} The function does not return a value, but triggers a toast notification on the UI.
 * @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
 * @version 1.0.0
 */

export const showToast = (toast, title, message) => {
	toast({
		position: 'top',
		duration: 5000,
		render: () => (
			<Stack
				bg='rgb(30, 30, 30)'
				borderRadius='8px'
				overflow='hidden'
				boxShadow='0 0 40px 1px black'
			>
				<Flex align='center' p='18px' pb='5px' gap='15px'>
					<Flex
						bg={title === 'Success' ? 'green' : 'negative'}
						h='25px'
						w='25px'
						align='center'
						justify='center'
						borderRadius='100%'
						pl='1px'
					>
						{title === 'Success' ? (
							<IconCheck2 boxSize='15px' />
						) : (
							<IconClose boxSize='15px' />
						)}
					</Flex>
					<Stack gap='2px'>
						<Text
							fontWeight='700'
							fontSize='16px'
							lineHeight='20px'
						>
							{title}
						</Text>
						<Text
							variant='gray'
							fontWeight='400'
							fontSize='12px'
							lineHeight='20px'
						>
							{message}
						</Text>
					</Stack>
				</Flex>
				<Box
					w='100%'
					h='4px'
					bg={title === 'Success' ? 'green' : 'negative'}
					borderRadius='2px'
					overflow='hidden'
					sx={{
						animation: 'progress-animation 5s linear forwards',
					}}
				/>
				<style>
					{`
						@keyframes progress-animation {
							from {
								width: 100%;
							}
							to {
								width: 0%;
							}
						}
					`}
				</style>
			</Stack>
		),
	});
};
