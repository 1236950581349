import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePickerComponent.css';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useState } from 'react';
import { IconArrowDown } from '../../assets/Icons';
import { formatMonthDate } from '../../utils/utils';
import { useTranslation } from 'react-i18next';

const DatePickerComponent = ({
	name,
	setInfo,
	incorrectData,
	minDate = new Date(),
	initialDate,
}) => {
	const { t } = useTranslation();

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	const handleDateChange = dates => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);

		const value = {
			from: start,
			to: end,
			day: null,
		};

		setInfo({
			target: { name, value, type: typeof value },
		});

		delete incorrectData[incorrectData.indexOf(name)];
	};

	const formatRange = (start, end) => {
		if (!start || !end) return '';
		return `${formatMonthDate(start)} - ${formatMonthDate(end)}`;
	};

	const formattedDateRange = initialDate?.from
		? formatRange(new Date(initialDate.from), new Date(initialDate.to))
		: formatRange(startDate, endDate);

	return (
		<DatePicker
			selected={startDate}
			startDate={startDate}
			endDate={endDate}
			onChange={handleDateChange}
			customInput={
				<InputGroup>
					<InputRightElement>
						<IconArrowDown />
					</InputRightElement>
					<Input
						name={name}
						placeholder={t('datePicker.selectMonthRange')}
						value={formattedDateRange}
						readOnly
						cursor='pointer'
						outline={
							incorrectData?.includes(name)
								? '1px solid red'
								: 'none'
						}
					/>
				</InputGroup>
			}
			minDate={minDate}
			wrapperClassName='datepicker-wrapper'
			calendarClassName='datepicker-calendar'
			inputClassName='datepicker-input'
			showPopperArrow={false}
			dateFormat='MMMM yyyy'
			selectsRange
			showMonthYearPicker
		/>
	);
};

export default DatePickerComponent;
