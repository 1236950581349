import UpcomingGeneralComponent from '../../components/UpcomingGeneralComponent.js';

const Notifications = () => {
	return <UpcomingGeneralComponent 
	title='notificationsPage.title' 
	subtitle='notificationsPage.subtitle'
	paragraph='notificationsPage.paragraph'
	button='notificationsPage.button' />
};

export default Notifications;
