import { useState } from 'react';
import {
	Button,
	Flex,
	Icon,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Skeleton,
	Stack,
	Text,
	useColorMode,
	useToast,
} from '@chakra-ui/react';
import ModalComponent from '../../../components/ModalComponent';
import CrossBoxComponent from '../../../components/CrossBoxComponent';
import { IconArrowDown, IconSend, IconEther } from '../../../assets/Icons';
import { tokenList } from '../../../data/optionsData';
import FeeComponent from '../../../components/FeeComponent';
import useInfoData from '../../../hooks/useInfoData';
import { useSelector } from 'react-redux';
import useTokenTransfer from '../../../hooks/useTokenTransfer';
import { useTranslation } from 'react-i18next';
import { isAddress } from 'ethers';
import { showToast } from '../../../utils/Toasts';
import SuccessfulComponent from '../../../components/SuccessfulComponent';

const SendTokensComponent = ({ isOpenSendModal, closeSendModal, isMobile }) => {
	const { colorMode } = useColorMode();
	const [selectedToken, setSelectedToken] = useState(tokenList[0]);
	const [ammountToSend, setAmmountToSend] = useState(null);
	const [addressToSend, setAddressToSend] = useState(null);
	const toast = useToast();
	const [sendTransactionSuccess, setSendTransactionSuccess] = useState(false);
	const [isLoadingAmount, setIsLoadingAmount] = useState(null);
	const [amountError, setAmountError] = useState(null);
	const [addressError, setAddressError] = useState(null);
	const [isPendingTransaction, setIsPendingTransaction] = useState(false);
	const [txHash, setTxHash] = useState(null);
	const user = useSelector(state => state.user);
	const { balances } = useInfoData({ walletAddress: user.walletAddress });
	const { sendTokens, sendETH, calculateMaxEthToSend } = useTokenTransfer();

	const { t } = useTranslation();

	const handleItemClick = item => {
		setSelectedToken(item);
	};

	const handleCloseSendModal = () => {
		closeSendModal();
		setSendTransactionSuccess(false);
	};

	const checkErrors = () => {
		let checked = {
			success: true,
			message: 'Transaction sent successfully',
		};
		if (Number(ammountToSend) <= 0) {
			console.error('The amount should be greater than 0');
			checked = {
				success: false,
				message: 'Introduce a value',
				type: 'amount',
			};
		} else if (addressToSend === '' || addressToSend === null) {
			checked = {
				success: false,
				message: 'Address can not be empty',
				type: 'address',
			};
		} else if (!isAddress(addressToSend)) {
			checked = {
				success: false,
				message: 'Invalid address',
				type: 'address',
			};
		}
		return checked;
	};

	const handleAmountToSendChange = event => {
		const value = event.target.value;
		setAmmountToSend(value);

		const tokenKey = selectedToken.abbr.toLowerCase();

		const tokenBalance = parseFloat(balances[tokenKey]) || 0;

		const amount = parseFloat(value);

		if (isNaN(amount) && value !== '') {
			setAmountError('Invalid amount');
		} else if (amount > tokenBalance) {
			setAmountError('Insufficient balance');
		} else {
			setAmountError(null);
		}
	};

	const handleSetMaxAmount = async () => {
		if (selectedToken.abbr === 'ETH') {
			setIsLoadingAmount(true);
			const maxEth = await calculateMaxEthToSend(
				user.walletAddress,
				addressToSend,
			);
			setAmmountToSend(maxEth.slice(0, 6));
			setIsLoadingAmount(false);
		} else {
			const tokenKey = selectedToken.abbr.toLowerCase();
			const tokenBalance = parseFloat(balances[tokenKey]) || 0;
			setAmmountToSend(tokenBalance);
		}
	};

	const handleAddressToSendChange = event => {
		if (addressError) {
			setAddressError(null);
		}
		setAddressToSend(event.target.value);
	};

	const handleSendTokens = async () => {
		const error = checkErrors();
		if (!error.success) {
			if (error.type === 'amount') {
				setAmountError(error.message);
			} else {
				setAddressError(error.message);
			}
			return;
		}

		if (!amountError && !addressError) {
			setIsPendingTransaction(true);
			if (selectedToken.abbr === 'ETH') {
				try {
					const tx = await sendETH(addressToSend, ammountToSend);
					tx.success && setTxHash(tx.receipt.hash);
				} catch (error) {
					console.error('Error sending ETH:', error);
					setIsPendingTransaction(false);
					showToast(toast, 'Error', 'Error sending ETH');
				}
			} else {
				try {
					const tx = await sendTokens(
						addressToSend,
						selectedToken.abbr,
						ammountToSend,
					);
					tx.success && setTxHash(tx.receipt.hash);
				} catch (error) {
					console.error('Error sending tokens:', error);
					setIsPendingTransaction(false);
					showToast(toast, 'Error', 'Error sending tokens');
				}
			}
			setIsPendingTransaction(false);
			setSendTransactionSuccess(true);
			setAddressToSend(undefined);
			setAmmountToSend(null);
			setSelectedToken(tokenList[0]);
			showToast(toast, 'Success', 'Transaction completed successfully');
		}
	};

	return (
		<ModalComponent
			isOpen={isOpenSendModal}
			onClose={closeSendModal}
			w={500}
			modalCross={false}
		>
			{!sendTransactionSuccess ? (
				<>
					{' '}
					<Flex w='100%' justify='end'>
						<CrossBoxComponent closeModal={closeSendModal} />
					</Flex>
					<Stack
						bg={
							colorMode === 'dark'
								? 'transparencyWhite.100'
								: 'transparencyWhite.800'
						}
						border='0.5px solid'
						borderColor='transparencyBlack.200'
						borderRadius='12px'
						p='20px'
						w={isMobile ? 'fit-content' : '312px'}
						gap='20px'
					>
						<Text
							fontSize='20px'
							fontWeight='700'
							letterSpacing={-0.02}
							textAlign='center'
						>
							{t('walletPage.sendTokens')}
						</Text>
						<Flex w='100%' gap='12px'>
							<Stack minW='128px'>
								<Text
									fontSize='14px'
									fontWeight='700'
									letterSpacing={-0.02}
								>
									{t('walletPage.asset')}
								</Text>
								<Menu>
									<MenuButton
										w='100%'
										h='52px'
										fontSize='12px'
										fontWeight='light'
										borderRadius='5px'
										pl='5px'
										pr='10px'
										bg={
											colorMode === 'dark'
												? 'transparencyWhite.100'
												: 'transparencyBlack.100'
										}
										_hover={{
											bg:
												colorMode === 'dark'
													? 'transparencyWhite.200'
													: 'transparencyBlack.200',
										}}
										_active={{
											bg:
												colorMode === 'dark'
													? 'transparencyWhite.200'
													: 'transparencyBlack.200',
										}}
										spacing={7}
										rightIcon={
											<IconArrowDown
												boxSize='16px'
												color='transparencyWhite.500'
											/>
										}
										as={Button}
									>
										<Flex gap={1} alignItems='center'>
											<Icon
												as={IconEther}
												boxSize='25px'
											/>
											<Stack textAlign='start'>
												<Text
													fontSize='13px'
													fontWeight='700'
													letterSpacing={-0.02}
													lineHeight='10px'
												>
													{selectedToken.abbr}
												</Text>
												<Text
													variant='gray'
													fontSize='13px'
													fontWeight='400'
													letterSpacing={-0.02}
													lineHeight='10px'
												>
													{selectedToken.token}
												</Text>
											</Stack>
										</Flex>
									</MenuButton>
									<MenuList fontSize='12px' zIndex={99}>
										{tokenList.map((elem, i) => (
											<MenuItem
												key={i}
												value={elem.token}
												onClick={() =>
													handleItemClick(elem)
												}
												gap='10px'
												fontSize='13px'
											>
												<Icon
													as={elem.icon}
													boxSize='17px'
												/>
												{elem.token}
											</MenuItem>
										))}
									</MenuList>
								</Menu>
							</Stack>
							<Stack>
								<Text
									fontSize='14px'
									fontWeight='700'
									letterSpacing={-0.02}
								>
									{t('walletPage.amount')}
								</Text>
								<Flex
									w='100%'
									h='52px'
									bg='transparencyWhite.100'
									align='center'
									borderRadius='5px'
									p='10px'
									boxShadow={
										amountError
											? '0px 0px 0px 1px red'
											: 'none'
									}
								>
									<Stack
										spacing={0}
										pe='5px'
										h='40px'
										w='62.22px'
										justifyContent='center'
									>
										{!isLoadingAmount ? (
											<Input
												variant='noStyled'
												placeholder='Value'
												p={0}
												onChange={
													handleAmountToSendChange
												}
												value={ammountToSend}
												type='number'
											/>
										) : (
											<Skeleton h='20px' />
										)}
									</Stack>
									<Button maxH='32px' variant='main'>
										<Text
											variant='inverse'
											fontSize='12px'
											fontWeight='700'
											lineHeight='20px'
											onClick={handleSetMaxAmount}
										>
											Max
										</Text>
									</Button>
								</Flex>
								<Text variant='error' fontSize='11px'>
									{amountError}
								</Text>
							</Stack>
						</Flex>
						<Stack>
							<Text
								fontSize='14px'
								fontWeight='700'
								lineHeight='20px'
							>
								{t('walletPage.addressToSend')}
							</Text>
							<Input
								variant={addressError ? 'error' : 'withBorder'}
								fontSize='12px'
								placeholder={t('walletPage.placeHolder')}
								p='10px'
								onChange={handleAddressToSendChange}
							/>
							<Text variant='error' fontSize='11px'>
								{addressError}
							</Text>
						</Stack>
						<Text
							fontSize='10px'
							lineHeight='15px'
							fontWeight='400'
							mt='-10px'
						>
							<Text
								as='span'
								variant='colored'
								fontSize='10px'
								lineHeight='15px'
							>
								{t('walletPage.disclaimerTitle')}
							</Text>{' '}
							{t('walletPage.disclaimerContent')}
						</Text>
						<FeeComponent />

						<Button
							variant='main'
							w='88.61px'
							alignSelf='center'
							gap='5px'
							onClick={handleSendTokens}
							isLoading={isPendingTransaction}
						>
							<IconSend boxSize='14px' />
							Send
						</Button>
					</Stack>{' '}
				</>
			) : (
				<SuccessfulComponent
					w='312px'
					p='20px'
					handleClick={handleCloseSendModal}
					buttonTitle={'Close'}
					text={t(
						'projectDetails.offerDetails.succesful.transactionSuccess',
					)}
					txHash={txHash}
				/>
			)}
		</ModalComponent>
	);
};

export default SendTokensComponent;
