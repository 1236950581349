import { Flex, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';

import { format } from 'date-fns';
import { currencyFormatter } from '../../../utils/utils';
import { IconPaidUser } from '../../../assets/Icons';

/**
*
    @name TransactionInfoComponent
    @description Component that shows the information about 1 transaction. It changes the icon and the color
				 of the money amount depending of the type of the transaction. 0 = sell, 1 = buy
	@returns {JSX.Element} TransactionInfoComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const TransactionInfoComponent = ({ data }) => {
	const theme = useTheme();
	const colorSell = useColorModeValue(
		theme.colors.light.primary,
		theme.colors.dark.primary,
	);

	const formatedDate = format(data.createdAt, "dd MMMM yyyy, 'at' hh:mm a");
	const formatedPrice = `-${currencyFormatter(data.usdCost)} `;

	return (
		<Flex justifyContent='space-between' alignItems='center' gap='3px'>
			<Flex alignItems='center' gap={{ base: '20px', sm: '10px' }}>
				<IconPaidUser color={colorSell} boxSize='24px' />
				<Stack gap='3px'>
					<Text fontSize={14} lineHeight='140%'>
						{data?.Project?.title}
					</Text>
					<Text variant='gray' fontSize='12px' fontWeight={'300'}>
						{formatedDate}
					</Text>
				</Stack>
			</Flex>
			<Text variant={'colored'} fontSize={14}>
				{formatedPrice}
			</Text>
		</Flex>
	);
};

export default TransactionInfoComponent;
