import { Flex, Text } from '@chakra-ui/react';

const FinancialStatisticalComponent = ({ label, icon, value }) => {
	const Icon = icon;
	return (
		<Flex justifyContent='space-between'>
			<Flex align='center' gap={1}>
				<Text fontSize='10'>{label}</Text>
				<Icon size='12px' color='gray' />
			</Flex>
			<Text fontSize='10px'>{value}</Text>
		</Flex>
	);
};
export default FinancialStatisticalComponent;
