import { Flex, Text, useColorMode } from '@chakra-ui/react';
import { IconClose } from '../../../../assets/Icons';
import { getFirstLetterUppercase } from '../../../../utils/utils';

const EquipmentBoxComponent = ({ equipment, onRemoveEquipment }) => {
	const { colorMode } = useColorMode();

	return (
		<Flex
			bg={
				colorMode === 'dark'
					? 'transparencyWhite.100'
					: 'transparencyBlack.100'
			}
			borderRadius='5px'
			fontSize='16px'
			fontWeight='600'
			letterSpacing={-0.02}
			lineHeight='20px'
			align='center'
			p='10px'
			gap='10px'
		>
			<equipment.icon boxSize='14px' />
			<Flex gap='5px'>
				{equipment.value !== false && <Text>{equipment.value}</Text>}
				<Text>{getFirstLetterUppercase(equipment.name)}</Text>
			</Flex>
			<IconClose
				name={equipment.name}
				boxSize='14px'
				onClick={() => onRemoveEquipment(equipment)}
				cursor='pointer'
			/>
		</Flex>
	);
};

export default EquipmentBoxComponent;
