import { Box } from '@chakra-ui/react';

const LoadingComponent = () => {
	return (
		<Box
			height='100%'
			display='flex'
			alignItems='center'
			justifyContent='center'
		>
			<span className='loading-component'></span>
		</Box>
	);
};
export default LoadingComponent;
