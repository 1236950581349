import {
	Box,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Grid,
	Input,
	Text,
	Textarea,
} from '@chakra-ui/react';
import { IconClose, IconPlus, IconSave } from '../../../../assets/Icons';
import FAQsTable from './FAQsTable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @name FAQsComponent
 * @description This component allows users to add, edit, and manage FAQs related to a given property. It enables input of new FAQs, editing existing ones, and displays the list of FAQs in a table. The component manages state for both new and existing FAQs.
 * @param {Object} propertyInfo - The property information object, which contains the FAQs data.
 * @param {Function} setPropertyInfo - A function to update the property information, including FAQs.
 * @param {number | null} selectedFAQIndex - The index of the FAQ currently being edited, or null if adding a new FAQ.
 * @param {Function} setSelectedFAQIndex - A function to set the index of the FAQ being edited.
 * @returns {JSX.Element} The FAQsComponent to handle the FAQs for the project.
 * @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
 * @version 1.0.0
 */

const newFAQInitialValues = { question: '', answer: '' };

const FAQsComponent = ({
	initialValues,
	handleOnChange,
	currentValues,
	setTabChangeDisabled,
	setPropertyInfo,
}) => {
	const [newFAQ, setNewFAQ] = useState();
	const newList = currentValues?.faqs ?? [];
	const updatedList = currentValues?.updatedFaqs ?? [];
	const allFAQs = initialValues.faqs.concat(updatedList).concat(newList);
	const [editIndex, setEditIndex] = useState();
	const { t } = useTranslation();

	const disableAddButton =
		!newFAQ?.question?.length || !newFAQ?.answer?.length;

	const onChangeInput = event => {
		const { name, value } = event.target;
		setNewFAQ({ ...newFAQ, [name]: value });
	};

	const onAddFAQ = () => {
		const newElement = {
			...newFAQ,
			id: Math.floor(Math.random() * 100),
		};
		const currentItems = newList.concat(newElement);
		setNewFAQ(newFAQInitialValues);
		handleOnChange({
			...currentValues,
			faqs: currentItems,
		});
	};

	const onEditFAQ = selectedIndex => {
		setTabChangeDisabled(true);
		setEditIndex(selectedIndex);
		const selectedElement = allFAQs[selectedIndex];
		setNewFAQ(selectedElement);
	};

	const onCancelEdit = () => {
		setNewFAQ(newFAQInitialValues);
		setEditIndex(undefined);
		setTabChangeDisabled(false);
	};

	const onSaveEditFAQ = () => {
		const element = newFAQ;
		let _newList = newList;
		let _updatedList = updatedList;

		if (element.faqsId) {
			const filteredList = updatedList.filter(
				item => item.faqsId !== newFAQ.faqsId,
			);
			const filteredInitialValues = initialValues?.faqs.filter(
				item => item.faqsId !== element.faqsId,
			);
			setPropertyInfo({
				...initialValues,
				faqs: filteredInitialValues,
			});

			_updatedList = filteredList.concat(element);
			handleOnChange({ ...currentValues, updatedFaqs: _updatedList });
		} else {
			const filteredList = newList.filter(item => item.id !== newFAQ.id);
			element.title = newFAQ.title;
			element.description = newFAQ.description;
			_newList = filteredList.concat(element);
			handleOnChange({ ...currentValues, faqs: _newList });
		}

		setNewFAQ(newFAQInitialValues);
		setEditIndex(undefined);
		setTabChangeDisabled(false);
	};

	const onDeleteFAQ = selectedIndex => {
		const element = allFAQs[selectedIndex];
		const deletedFaqs = currentValues?.deletedFaqs ?? [];

		if (element.faqsId) {
			const filteredInitialValues = initialValues?.faqs.filter(
				item => item.faqsId !== element.faqsId,
			);

			const filteredUpdatedList = updatedList.filter(
				item => item.faqsId !== element.faqsId,
			);

			setPropertyInfo({
				...initialValues,
				faqs: filteredInitialValues,
			});

			handleOnChange({
				...currentValues,
				deletedFaqs: deletedFaqs.concat(element),
				updatedFaqs:
					filteredUpdatedList.length > 0
						? filteredUpdatedList
						: undefined,
			});
		} else {
			const filteredList = newList.filter(item => item.id !== element.id);
			handleOnChange({
				...currentValues,
				faqs: filteredList,
			});
		}
	};

	return (
		<Grid templateColumns='repeat(12, 1fr)' gap='25px'>
			<FormControl gridColumn='span 12'>
				<FormLabel variant='propertyLabel'>
					{t('addProperty.adminPanel.faq.question')}
				</FormLabel>
				<Input
					placeholder={t('addProperty.adminPanel.faq.addQuestion')}
					name='question'
					value={newFAQ?.question}
					onChange={onChangeInput}
				/>
			</FormControl>

			<FormControl gridColumn='span 12'>
				<FormLabel variant='propertyLabel'>
					{t('addProperty.adminPanel.faq.response')}
				</FormLabel>
				<Textarea
					rows={5}
					placeholder={t('addProperty.adminPanel.faq.addAnswer')}
					name='answer'
					value={newFAQ?.answer}
					onChange={onChangeInput}
				/>
			</FormControl>
			<Flex gridColumn='span 12' justify='end'>
				{typeof editIndex === 'number' ? (
					<Flex gap='10px'>
						<Button
							variant='secondary'
							gap='5px'
							onClick={onCancelEdit}
						>
							<IconClose boxSize='13px' />
							<Text fontSize='13px'>Cancel</Text>
						</Button>
						<Button
							variant='main'
							gap='5px'
							onClick={onSaveEditFAQ}
						>
							<IconSave boxSize='13px' />
							<Text fontSize='13px'>Save edit</Text>
						</Button>
					</Flex>
				) : (
					<Button
						variant='secondary'
						gap='5px'
						onClick={onAddFAQ}
						isDisabled={disableAddButton}
					>
						<IconPlus boxSize='13px' mt='-2px' />
						<Text fontSize='13px'>
							{t('addProperty.adminPanel.faq.button')}
						</Text>
					</Button>
				)}
			</Flex>
			{allFAQs.length > 0 && (
				<Box gridColumn='span 12'>
					<FAQsTable
						currentFAQs={allFAQs}
						onEditFAQ={onEditFAQ}
						onDeleteFAQ={onDeleteFAQ}
					/>
				</Box>
			)}
		</Grid>
	);
};

export default FAQsComponent;
