import {
	Box,
	Flex,
	Image,
	Skeleton,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import { currencyFormatter, getCountryCode } from '../../utils/utils';
import { IconBathroom, IconRooms } from '../../assets/Icons';
import { useTranslation } from 'react-i18next';

/**
*
    @name InfoPropertyCard
    @description This is the important information of the property component. It is the body part of the property card.
    @returns {JS.Element} InfoPropertyCard.js
    @author SoniaRodS | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const InfoPropertyCard = ({ project }) => {
	const { t } = useTranslation();
	const { colorMode } = useColorMode();
	const { equipments, mintedSupply, value } = project;

	const ISO = getCountryCode(project.country);

	return (
		<>
			<Box p='10px'>
				<Flex justifyContent='space-between'>
					<Text fontSize='14px' fontWeight='600' lineHeight='20px'>
						{project.title}
					</Text>
					<Flex alignItems='center' gap='5px'>
						<Stack
							direction='row'
							alignItems='center'
							gap='3px'
							mr='2px'
						>
							<Image
								src={`/assets/countries/${ISO}.webp`}
								boxSize='12px'
								rounded={'50px'}
							/>
							<Text fontSize='10' fontWeight='300'>
								{project?.city}
							</Text>
						</Stack>
						{equipments.room > 0 ? (
							<Stack gap='2px' direction='row'>
								<Text
									variant='gray'
									fontSize='10px'
									fontWeight='400'
								>
									{equipments.room}
								</Text>
								<IconRooms
									boxSize='11px'
									alignSelf='center'
									color={
										colorMode === 'dark'
											? 'transparencyWhite.500'
											: 'transparencyBlack.600'
									}
								/>
							</Stack>
						) : null}
						{equipments.bathrooms > 0 ? (
							<Stack gap='2px' direction='row'>
								<Text
									variant='gray'
									fontSize='10px'
									fontWeight='400'
								>
									{equipments.bathrooms}
								</Text>
								<IconBathroom
									boxSize='11px'
									alignSelf='center'
									color={
										colorMode === 'dark'
											? 'transparencyWhite.500'
											: 'transparencyBlack.600'
									}
								/>
							</Stack>
						) : null}
					</Flex>
				</Flex>
			</Box>
			<Flex justifyContent='center' gap='30px' pb='10px'>
				<Stack alignItems='center' gap='1px'>
					<Text variant='gray' fontSize='12'>
						Total tokens
					</Text>
					{mintedSupply ? (
						<Text fontSize='14'>{mintedSupply}</Text>
					) : (
						<Skeleton h='20px' w='100%' />
					)}
				</Stack>
				<Stack alignItems='center' gap='1px'>
					<Text variant='gray' fontSize='12'>
						{t('projectCard.infoPropertyCard.value')}
					</Text>
					<Text fontSize='14'>{currencyFormatter(value)}</Text>
				</Stack>
			</Flex>
		</>
	);
};

export default InfoPropertyCard;
