import axios from 'axios';
import { decodeToken, encodeToken } from '../utils/TokenUtils';
import { formatProjectParameters } from '../utils/projectDataUtils';
import { propertyStatus } from '../data/optionsData';

export const addProperty = async propertyInfo => {
	const formattedPropertyInfo = formatProjectParameters(propertyInfo);
	try {
		const token = encodeToken(formattedPropertyInfo);

		const formData = new FormData();
		formData.append('token', token);
		if (propertyInfo?.gallery) {
			propertyInfo.gallery.forEach(media => {
				formData.append('mediaFiles', media.file);
			});
		}
		if (propertyInfo?.documents) {
			propertyInfo.documents.forEach(doc => {
				formData.append('documents', doc.file);
			});
		}

		const response = await axios.post('/project/create', formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		});

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (error.response.data.message) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const getProjectInfo = async projectId => {
	try {
		const response = await axios.get(`/project/one-project/${projectId}`);

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const getAllProjects = async (
	projectsFiltered,
	pageSize,
	page,
	type = 'default',
) => {
	try {
		const {
			purchasedOrPledge,
			propertyStatus,
			category,
			alphabetic,
			price,
			date,
			search,
		} = projectsFiltered;

		const params = new URLSearchParams({
			purchasedOrPledge,
			propertyStatus,
			category,
			alphabetic,
			price,
			date,
			search,
			pageSize,
			page,
		});

		const queryString = params.toString();

		const response = await axios.get(
			`/project/all-projects/${type}?${queryString}`,
		);

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const deleteProject = async projectId => {
	try {
		const response = await axios.delete(`/project/delete/${projectId}`);

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const updateProperty = async (propertyInfo, projectId) => {
	const formattedPropertyInfo = formatProjectParameters(propertyInfo);

	try {
		const token = encodeToken(formattedPropertyInfo);

		const formData = new FormData();
		formData.append('token', token);

		if (formattedPropertyInfo?.data?.isDraft && propertyInfo?.gallery) {
			propertyInfo.gallery.forEach(media => {
				formData.append('mediaFiles', media.file);
			});
		}

		if (propertyInfo?.documents) {
			propertyInfo.documents.forEach(doc => {
				formData.append('documents', doc.file);
			});
		}

		const response = await axios.put(
			`/project/update/${projectId}`,
			formData,
			{
				headers: { 'Content-Type': 'multipart/form-data' },
			},
		);

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (error.response.data.message) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const updatePropertyAndNonce = async (propertyInfo, projectId) => {
	try {
		const token = encodeToken(propertyInfo);

		const response = await axios.put(
			`/project/update-status-and-nonce/${projectId}`,
			{ token },
		);

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (error.response.data.message) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

/**
 * @name Publish project
 * @description Publish a project
 * @param {string} projectId
 * @returns
 */
export const publishProject = async projectId => {
	try {
		const response = await axios.post(`/project/publish/${projectId}`);

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

/**
 * Get project state based on project information
 * @returns projectStatus
 */
export const getProjectState = projectInfo => {
	const {
		propertyStatus: status,
		mintedSupply,
		quantityOfTokens,
	} = projectInfo;

	if (status === propertyStatus.EndedBurned) {
		return propertyStatus.Burned;
	} else if (projectInfo?.isWithdrawn) {
		return propertyStatus.OpenMarket;
	} else if (mintedSupply >= quantityOfTokens) {
		return propertyStatus.Funded;
	} else if (new Date() >= new Date(projectInfo.financingEnd)) {
		return propertyStatus.Cancelled;
	} else if (
		new Date() < new Date(projectInfo.financingEnd) &&
		new Date() >= new Date(projectInfo.financingStart)
	) {
		return propertyStatus.Open;
	} else {
		return propertyStatus.Upcoming;
	}
};

export const normalizeUserProjectData = projects => {
	if (!Array.isArray(projects) || projects.length < 2) return [];

	const [projectIds, mintedSupplies] = projects;

	return projectIds.map((id, index) => ({
		projectId: Number(id),
		mintedSupply: Number(mintedSupplies[index]),
	}));
};
