import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import PropertyGallery from './components/PropertyGallery';
import Filters from './components/DiscoveryFilters/index.js';
import ModalComponent from '../../components/ModalComponent.js';
import Onboarding from './components/Onboarding/index.js';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../redux/states/user.js';
import { getAllProjects } from '../../services/propertyService.js';
import LoadingComponent from '../../components/LoadingComponent.js';

const Discovery = () => {
	const dispatch = useDispatch();
	const [isScreenLarge, setIsScreenLarge] = useState(
		window.innerWidth > 1720,
	);

	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(isScreenLarge ? 10 : 12);
	const [data, setData] = useState();
	const [projectsFiltered, setProjectsFiltered] = useState({
		purchasedOrPledge: '',
		propertyStatus: '',
		category: '',
		alphabetic: '',
		price: '',
		date: '',
		search: '',
	});

	const user = useSelector(store => store.user);

	const [showOnboarding, setShowOnboarding] = useState(
		user.isFirstTime || false,
	);
	const [loading, setLoading] = useState(false);

	const closeOnboardingModal = () => {
		dispatch(updateUser({ ...user, isFirstTime: false }));
		setShowOnboarding(false);
	};

	useEffect(() => {
		const handleResize = () => {
			setIsScreenLarge(window.innerWidth > 1720);
			setPageSize(window.innerWidth > 1720 ? 10 : 12);
		};

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		setLoading(true);
		const getProjectsInfo = async () => {
			const { data } = await getAllProjects(
				projectsFiltered,
				pageSize,
				page,
			);

			setData(data);

			setLoading(false);
		};
		getProjectsInfo();
	}, [projectsFiltered, isScreenLarge, page, pageSize]);

	return (
		<Box mx='-15px' h='100%'>
			<Filters setProjectListFiltered={setProjectsFiltered} />
			{loading ? (
				<LoadingComponent />
			) : (
				<PropertyGallery
					projects={data?.projectsWithImages}
					totalProjects={data?.count}
					page={page}
					setPage={setPage}
					pageSize={pageSize}
					isScreenLarge={isScreenLarge}
				/>
			)}

			<ModalComponent
				onClose={closeOnboardingModal}
				isOpen={showOnboarding}
				modalCross={false}
				closeOnOverlayClick={false}
				moreBlur
			>
				<Onboarding closeOnboardingModal={closeOnboardingModal} />
			</ModalComponent>
		</Box>
	);
};
export default Discovery;
