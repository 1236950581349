import UpcomingGeneralComponent from '../../components/UpcomingGeneralComponent.js';

const HowTo = () => {
	return <UpcomingGeneralComponent 
	title='howToPage.title' 
	subtitle='howToPage.subtitle'
	paragraph='howToPage.paragraph'
	button='howToPage.button' />
};

export default HowTo;
