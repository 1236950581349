import { Image, useColorMode } from '@chakra-ui/react';

import { useNavigate } from 'react-router-dom';
import { PublicRoutes } from '../routes/Routes';

/**
*
    @name LogoComponent
    @description A component that returns the dark and light mode logo depending of the current color mode
    @returns {JS.Element} LogoComponent.js
    @author Edunknown | Unknown Gravity - All-in-one Blockchain Company
    @Version 1.0.0
*/

const LogoComponent = ({ maxWidth = '100px' }) => {
	const navigate = useNavigate();
	const { colorMode } = useColorMode();

	const logo = colorMode === 'dark' ? 'logo-dark.png' : 'logo-light.png';
	return (
		<>
			<Image
				maxWidth={maxWidth}
				src={`/assets/images/brand/${logo}`}
				onClick={() => navigate(`${PublicRoutes.Home}`)}
				cursor='pointer'
				transition='transform 0.5s, filter 0.7s'
				_hover={{
					filter: 'brightness(1.3)',
				}}
				_active={{
					transform: 'scale(0.97)',
				}}
				id='elevexLogo'
			/>
		</>
	);
};

export default LogoComponent;
