import { createIcon } from '@chakra-ui/react';

const PadLock = createIcon({
	displayName: 'PadLock',
	viewBox: '0 0 792 792',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<>
			<path
				d='M108,325.476v429.048C108,775.224,124.02,792,144.036,792h503.928C667.872,792,684,775.224,684,754.524V325.476c0-20.7-16.02-37.476-36.036-37.476H144.036C124.128,288,108,304.776,108,325.476z M396,433.729c39.744,0,72,33.768,72,75.42c0,27.936-14.472,52.308-36,65.34v66.672c0,0,0,37.728-35.964,37.728C360.108,678.852,360,641.16,360,641.16v-66.672c-21.528-13.032-36-37.404-36-65.34C324,467.496,356.22,433.729,396,433.729z'
				fill='white'
			/>
			<path
				d='M577.332,287.928v-99.36c0,0,0-188.568-181.332-188.568C215.244,0,215.244,187.776,215.244,187.776v100.152h36V187.776c0,0,0-151.776,144.756-151.776s145.332,152.568,145.332,152.568v99.36H577.332z'
				fill='white'
			/>
		</>
	),
});

export default PadLock;
