import { Magic } from 'magic-sdk';

const isMainnet = process.env.REACT_APP_MAGIC_IS_MAINNET === 'true';

const rpcUrl = isMainnet
	? process.env.REACT_APP_MAGIC_RPC_PROVIDER_MAINNET
	: process.env.REACT_APP_MAGIC_RPC_PROVIDER_SEPOLIA;

const chainId = isMainnet
	? parseInt(process.env.REACT_APP_MAGIC_CHAIN_ID_MAINNET)
	: parseInt(process.env.REACT_APP_MAGIC_CHAIN_ID_SEPOLIA);

const customNodeOptions = {
	rpcUrl,
	chainId,
};

const createMagic = () =>
	typeof window !== 'undefined' &&
	new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY, {
		network: customNodeOptions,
	});

export const magic = createMagic();
