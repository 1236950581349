import axios from 'axios';
import { decodeToken } from '../utils/TokenUtils';

export const getAdminData = async () => {
	try {
		const response = await axios.get('/admin/data');

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const createLogHistory = async data => {
	try {
		const response = await axios.post('/log/create', data);

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};

export const getGraphData = async days => {
	try {
		const params = new URLSearchParams({
			days,
		});
		const queryString = params.toString();
		const response = await axios.get(`/admin/graph?${queryString}`);

		return { success: true, data: decodeToken(response.data.data) };
	} catch (error) {
		if (
			error.response &&
			error.response.data &&
			error.response.data.message
		) {
			return { success: false, message: error.response.data.message };
		} else {
			return {
				success: false,
				message: 'Something went wrong.',
			};
		}
	}
};
