import { createIcon } from '@chakra-ui/react';

const IconArrowRight = createIcon({
	displayName: 'IconArrowRight',
	viewBox: '0 0 15 14',
	xmlns: 'http://www.w3.org/2000/svg',
	path: (
		<path
			d='M4.06421 1.84375L10.0642 7.84375L4.06421 13.8438'
			stroke='currentColor'
			fill='none'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	),
});

export default IconArrowRight;
