import { Transak } from '@transak/transak-sdk';
import { createLogHistory } from '../services/adminService';
import { logActionOptions, logTypeOptions } from '../data/optionsData';

const colorMode = 'LIGHT';
const disablePaymentMethods = [
	'gbp_bank_transfer',
	'sep_bank_transfer',
	'pm_open_banking',
	'inr_bank_transfer',
	'inr_upi',
	'sepa_bank_transfer',
	'pm_pse',
	'pm_us_wire_bank_transfer',
	'pm_astropay',
	'pm_pix',
	'pm_gcash',
	'pm_paymaya',
	'pm_bpi',
	'pm_ubp',
	'pm_grabpay',
	'pm_shopeepay',
	'google_pay',
	'apple_pay',
];

export const availableTransakProductOptions = {
	BUY: 'BUY',
	SELL: 'SELL',
	BUYANDSELL: 'BUY,SELL',
};

const getBuyingSettings = ({ purchaseQuantity, selectedCurrency }) => {
	return {
		disableWalletAddressForm: true,
		defaultFiatAmount: purchaseQuantity,
		fiatAmount: purchaseQuantity,
		defaultCryptoCurrency: selectedCurrency,
		disablePaymentMethods,
	};
};

const getSellingSettings = ({ purchaseQuantity, selectedCurrency }) => {
	return {
		disableWalletAddressForm: true,
		defaultCryptoAmount: purchaseQuantity,
		cryptoCurrencyCode: selectedCurrency,
	};
};

export function interactWithTransak(
	{
		purchaseQuantity,
		userInfo = null,
		isSettingsComplete = true,
		selectedCurrency = 'USDC',
		type = availableTransakProductOptions.BUY,
	},
	handler,
) {
	if (!userInfo) {
		isSettingsComplete = false;
	}
	let settings = {
		apiKey: process.env.REACT_APP_TRANSAK_API_KEY,
		environment: Transak.ENVIRONMENTS.STAGING,
		networks: 'arbitrum',
		defaultNetwork: 'arbitrum',
		cryptoCurrencyList: ['ETH', 'USDC'],
		themeColor: '000000',
		backgroundColors: '000000',
		hostURL: window.location.origin,
		widgetHeight: '600px',
		widgetWidth: '400px',
		hideExchangeScreen: true,
		hideMenu: true,
		colorMode,
		productsAvailed: type,
		defaultFiatCurrency: 'USD',
		defaultPaymentMethod: 'credit_debit_card',
	};

	if (type === availableTransakProductOptions.BUY) {
		settings = {
			...settings,
			...getBuyingSettings({ purchaseQuantity, selectedCurrency }),
			walletAddress: userInfo.walletAddress, // Wallet Address where you want to receive the crypto
		};
	} else {
		settings = {
			...settings,
			...getSellingSettings({ purchaseQuantity, selectedCurrency }),
		};
	}

	if (isSettingsComplete) {
		settings = {
			...settings,
			email: userInfo.email,
		};
	}

	const transak = new Transak(settings);

	transak.init();

	// To get all the events
	Transak.on('*', data => {
		console.log(data);
	});

	// This will trigger when the user closed the widget
	Transak.on(Transak.EVENTS.TRANSAK_WIDGET_CLOSE, () => {
		transak.close();
		console.log('Transak SDK closed!');
	});

	/*
	 * This will trigger when the user has confirmed the order
	 * This doesn't guarantee that payment has completed in all scenarios
	 * If you want to close/navigate away, use the TRANSAK_ORDER_SUCCESSFUL event
	 */
	Transak.on(Transak.EVENTS.TRANSAK_ORDER_CREATED, orderData => {
		console.log('TRANSAK_ORDER_CREATED');
	});

	/*
	 * This will trigger when the user marks payment is made
	 * You can close/navigate away at this event
	 */
	Transak.on(Transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, orderData => {
		console.log('TRANSAK_ORDER_SUCCESSFUL');
		const action = orderData?.status?.isBuyOrSell?.toLowerCase();
		const type =
			orderData.status.isBuyOrSell === availableTransakProductOptions.SELL
				? logTypeOptions.Fiat
				: logTypeOptions.Crypto;
		const amount = logActionOptions.Sell
			? orderData.status.cryptoAmount
			: orderData.status.fiatAmount;
		const currency =
			action === logActionOptions.Sell
				? orderData.status.fiatCurrency
				: orderData.status.cryptoCurrency;
		const to =
			action === logActionOptions.Sell
				? orderData.status.cryptoPaymentData.address
				: orderData.status.walletAddress;
		const userId = userInfo.userId;
		const tx = orderData.status.id;
		createLogHistory({ action, type, amount, currency, to, userId, tx });
		handler({ ...orderData });
	});
}
